// BASE TYPOGRAPHY STYLES AND CLASSES

// PARAGRAPHS
p {
  margin: 0 0 $spacing-xs;
  color: $dark-gray-2024;
  font-family: Roboto, Arial, Helvetica, sans-serif!important;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 32px;
  text-align: inherit;
}
small,
.small,
.small-text {
  font-size: 15px;
  font-weight: $font-weight-normal; // 300
}
b,
strong,
.bold-text {
  font-weight: bold;
}
i {
}
u {
}
em {
}
h2 > sub,
sup {
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif!important;
  top: 1.75rem;
  left: 0.5rem;
}

p > sup {
  font-size: 8px;
}

sup {
  // top: -0.65em;
  vertical-align: top;
}
sub {
}
strike {
}
del {
}
ins {
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
  //margin: 0 0 $spacing-sm;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: $dark-gray-2024;
  line-height: 1.33em;
  text-align: inherit;
}

p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  // adds padding to the top of a heading that follows a paragraph
  margin-top: $spacing-sm;
}

h1 {
  //color: $white;  // color is assigned in _hero.scss
  font-size: 40px;

  @media (max-width: $md - 1px) {
    font-size: 30px;
  }
}

h2 {
  font-size: 38px;
  font-style: normal;
  font-weight: 300;
  //line-height: 80px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 24px;
}

// QUOTES
blockquote {
  cite {
  }
}
q {
}
cite {
}

// OTHER TEXT ELEMENTS
a {
  color: $aqua-light;

  &:hover {
    color: $aqua-dark;
  }
}

code {
}

pre {
}

abbr {
}

acronym {
}

address {
}

dfn {
}

// ********** TYPOGRAPHY MODIFIERS **********

.white {
  color: $white;
}
.slate {
  color: $slate;
}
.red {
  color: $red;
}
.aqua {
  color: $aqua;
}

// For use on thank you pages.
.thanks-header {
  @extend .red;
  margin-bottom: $spacing-md;
  text-align: center;
}
