@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;

  src: url("#{$font-path}/slick.eot");
  src: url("#{$font-path}/slick.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}/slick.woff") format("woff"),
    url("#{$font-path}/slick.ttf") format("truetype"),
    url("#{$font-path}/slick.svg#slick") format("svg");
}
