// FORM STYLES
// see _buttons.scss for <button> styles

#appcform {
    .msg {
        color: $red;
        margin-top:5px;
        margin-bottom:5px;
    }
}

form {
  position: relative;
}

legend {
}

.form-group {
  position: relative;
  margin: 0;
}

fieldset {
}


form {
  font-family: Roboto;
  font-size: 18px;
  margin: 20px 0px;

  .form-group {
    margin: 10px 0;

  }

  .form-control {

    height: 40px;
    background-color: #f8f8f8;
    border-radius: 0px;
    font-size: 14px;
  }

  .form-textarea {
    height: 6em;
  }

  .form-item {

    .panel-title {
      font-size: 20px;
      font-weight: 700;
      padding: 5px 0px;
      border-bottom: 1px solid #a1a1a1;
      padding: 15px 0px 2px 0px;
    }

  }
  .form-inline label {
    padding-right: 10px;
  }

}



// ***************     LABELS      ***************
label {
  color: inherit; //$gray-darkest;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.75em;
}

// Floating Labels
.floating-label {
  display: none;
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  color: $gray-lighter;
}

input:focus + .floating-label,
textarea:focus + .floating-label {
  display: block;
}

input:placeholder-shown + .floating-label,
textarea:placeholder-shown + .floating-label {
  display: none;
}

// ***************     TEXT INPUT      ***************
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
select,
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 2.5px;
  border: 1px solid $gray-lighter;
  border-radius: 0px;
  padding: 5px 20px;
  background-color: $gray-bkg;
  color: $gray-darkest;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

.mktoForm input::placeholder,
.mktoForm textarea::placeholder,
input::placeholder,
textarea::placeholder {
  color: $gray-lighter;
}

.mktoForm textarea,
textarea {
  resize: vertical;
  height: $spacing-xl * 2;
  min-height: $spacing-xl * 1.5;
}

// Search Input

input[name="search"] {
  //Using important to overide default input[type=text]
  border: 1px solid $gray-darkest !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20fill-opacity%3D%22.4%22%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20451%20451%22%3E%3Cpath%20d%3D%22M447.05%20428l-109.6-109.6c29.4-33.8%2047.2-77.9%2047.2-126.1C384.65%2086.2%20298.35%200%20192.35%200%2086.25%200%20.05%2086.3.05%20192.3s86.3%20192.3%20192.3%20192.3c48.2%200%2092.3-17.8%20126.1-47.2L428.05%20447c2.6%202.6%206.1%204%209.5%204s6.9-1.3%209.5-4c5.2-5.2%205.2-13.8%200-19zM26.95%20192.3c0-91.2%2074.2-165.3%20165.3-165.3%2091.2%200%20165.3%2074.2%20165.3%20165.3s-74.1%20165.4-165.3%20165.4c-91.1%200-165.3-74.2-165.3-165.4z%22%20fill%3D%27black%27%2F%3E%3C%2Fsvg%3E");

  background-repeat: no-repeat;
  background-position: 95% center;
  border-radius: $spacing-sm + 5px;
  width: 100%;
  max-width: 364px;
  margin-bottom: $spacing-md + 4px;

  @media (max-width: $sm - 1px) {
    margin-bottom: 0px;
  }
}

input[name="search"]::placeholder {
  color: $gray-darkest;
  font-size: $spacing-sm - 1px;
  font-weight: $font-weight-normal;
}

// ***************     SELECT      ***************
// add the following line to all select elements
// onchange="$(this).addClass('choice-made');
select.form-control:not([size]):not([multiple]) {
  height: 40px;
}

.mktoForm select,
select {
  color: $gray-light;
  font-size: 16px;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }
  &.choice-made {
    font-size: 18px;
    color: $gray-darkest;
  }

  optgroup {
  }
  option {
    font-size: 18px;
    color: $gray-darkest;

    &:disabled {
      display: none;
    }
  }
}

// ***************     RADIO BUTTON      ***************
input[type="radio"] {
}

// ***************     CHECKBOXES      ***************
.checkbox,
.form-check {
  $box-size: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0;

  input[type="checkbox"] {
    display: none;
  }
  .box {
    height: $box-size;
    width: $box-size;
    margin-right: 10px;
    background-color: $white;
    border: 1px solid $gray-lighter;
    border-radius: 0px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  input[type="checkbox"]:checked + .box:after {
    position: absolute;
    top: -8px;
    left: 0;
    content: "\2714";
    font-size: 30px;
    font-weight: bold;
    color: $red;
  }
}

// ***************     FORM FOOTER      ***************
.form-footer {
  display: flex;
  flex-direction: row;
  max-width: 100%;

  @media (max-width: $md) {
    display: block;

    .form-footer-label {
      margin: 0;
    }
  }

  .form-footer-label {
    flex: 1;
    margin-left: $spacing-sm;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;

    @media (max-width: $md) {
      display: block;
      margin: $spacing-sm 0 0;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


///Design for Marketo Forms Added by Rupak

.mktoForm .mktoRequiredField label.mktoLabel {
  font-weight: inherit !important;;
}

.mktoForm {
  padding: 20px 0;
  width: 100% !important;

  label{
    font-family: "Helvetica Neue LT W01_41488878";
    color: #353535;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.9em;
    margin: 0 0 2px !important;
    text-align: inherit;
  }
  .mktoFormCol {
    min-height: 75px !important;
  }

  textarea,
  select,
  textarea.mktoField,
  select.mktoField {
    padding: 10px !important;
    width: 100% !important;
    line-height: 20px !important;
  }
  .mktoOffset {
    line-height: 0 !important;
    display: none;
  }

  .mktoRadioList > input,
  .mktoCheckboxList > input,
  .mktoLayoutAbove .mktoField {
    margin-top: 5px;
    margin-right: 10px;
  }
  .mktoCheckboxList > input{
    float:left;
  }

  .mktoRadioList > label,
  .mktoCheckboxList > label {
    padding-left: 20px;
  }

  .mktoRequiredField label.mktoLabel {
    font-weight: normal;
  }
  .mktoFormCol,
  .mktoFieldWrap,
  .mktoLabel,
  .mktoLayoutAbove .mktoLogicalField,
  .mktoLogicalField .mktoCheckboxList .mktoHasWidth,
  .mktoLayoutAbove .mktoField,
  .mktoLayoutAbove .mktoLogicalField,
  .mktoLayoutAbove .mktoRadioList,
  .mktoLayoutAbove .mktoCheckboxList,
  .mktoHtmlText {
    width: 100% !important;
  }
}
.mktoForm input[type="text"],
.mktoForm input[type="url"],
.mktoForm input[type="email"],
.mktoForm input[type="tel"] {
  padding: 10px !important;
  width: 100% !important;
  line-height: 20px !important;
  height: 42px !important;
}
.mktoForm.mktoLayoutAbove .mktoLogicalField {
  width: 100% !important;
}
#instant-contact-us .mktoForm .mktoRequiredField label.mktoLabel {
  display: none;
}

.contact-form .mktoForm, #become-a-partner .mktoForm {
  .mktoFormRow {
    clear: none;
    float: left;
    padding-left: 2%;
    width: 47%;

    @media (max-width: $md) {
      width: 97%;
    }
  }

  .mktoButtonRow {
    clear: both;
    width: 100%;
    padding-left: 1%;
  }
}
.contact-form .mktoForm {
  .mktoFormRow:nth-child(13) {
    width: 97%;
  }
}

.event-form {
  .mktoForm {
    .mktoRadioList > label,
    .mktoCheckboxList > label,
    .mktoLabel {
      color: $white;
    }
  }
}
.font13 {
  font-size: 13px;
}
_:-ms-lang(x), .font13 {
  //margin-top: 470px;
}
_:-ms-lang(x), .iesolution {
  margin-top: 35px;
}


.form-griffin-bg {

  p {
    color: $white;
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}

.partner-marketplace-form {
  .mktoForm input[type=text],
  .mktoForm input[type=url],
  .mktoForm input[type=email],
  .mktoForm input[type=tel],
  .mktoForm input[type=number],
  .mktoForm input[type=date],
  .mktoForm textarea.mktoField,
  .mktoForm select.mktoField {
    background-color: #fff !important;
  }
}

/// End of Design for Marketo Forms


//Drupal Form
.webform-submission-insight-opportunity-registration-form,
.webform-submission-cdw-opportunity-registration-form,
.webform-submission-axway-opportunity-registration-form {
  max-width: 800px;
  margin: auto;
  padding-bottom: 20px;
}
.form-required:after {
  content: " *";
  color: #E82C2A;
}

.btn-success {
  background-color: #506570;
}

.role-image_uploader .media-image-add-form .form-type-vertical-tabs,
.role-image_uploader #edit-field-media-image-1-0-upload--description {
  display: none;
}
