// HERO STYLES
#video-banner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;
  padding: 50px 0 $section-padding;
  background-image: $aqua-grad;
  background-position: center center;
  background-size: cover;
  color: white;

  .container {
      width: 93.75%;
      max-width: $xl;
  }

  @media (max-width: $lg) {
    padding: $section-padding-sm 0;
  }

  .banner-content {
    z-index: 1;

    h2 {
      border-bottom: none;
      margin-bottom: 20px;
      color: $white;
    }
    p {
      font-size: 18px;
      line-height: 32px;
      color: $white;
      font-weight: 300;

      &.subtitle-large {
        font-size: 23px;
      }
    }
  }
}

.play-container {
  margin-top: 30px;
  padding: 0;

  @media (max-width: $lg) {
    display: flex;
    flex-direction: row;
    // align-items: center;
    align-items: flex-start;
    padding: 0;
    justify-content: flex-start;
    margin-top: $spacing-md;
  }
  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.play-btn.mobile {
  display: none;
  background-size: 25px;

  &:after {
    content: "Watch Video";
    bottom: 0;
    color: #fff;
    display: block;
    font-size: 16px;
    margin-bottom: auto;
    margin-left: auto;
    margin-top: auto;
    position: absolute;
    top: 15px;
    bottom: 0;
    margin: auto;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: $lg) {
    display: flex;
    position: relative;
    flex-direction: row;
    //margin-top: $spacing-md;
    width: 130px;
    margin-left: $spacing-md;
    background-position: left;
    margin-left: 0;
  }
}

.banner-video-col {
  
  display:flex;
  flex-direction: column;
  min-height: 290px;

  @include center-child(); // centers child on x and y

  @media (max-width: $lg) {
    margin-top: $spacing-lg;
  }

  @media (max-width: $lg) {
    display: none;
  }
}
