// ============================================================================================================
// Gartner 2023
// ============================================================================================================

.sitewide-alert.alert.alert-Gartner2023 {
  display: flex;
  align-items: center;
  margin-top: 102px;
  min-height: 112px;
  background-color: black;
  background-image: url(https://www.axway.com/sites/default/files/2023-10/banner-square_bg_2x.png);
  background-repeat: no-repeat; left: calc(-1 * (100vw - 100%) / 2); position: relative; width: 100vw;
  background-size: 100px 114px;
  margin-bottom: 0!important;

  .container {
    margin: auto;
    width: 93.75% !important;

    .alert {
      align-items: baseline;
      margin-bottom: 0!important;

      span.axwleader {
        color: #FFFFFF;
        font-family: Roboto Slab, Roboto, Arial !important;
        font-size: 31px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
        padding-right: 50px;
      }

      span.axwbline {
        color: #FFFFFF;
        font-family: "Roboto Condensed", Roboto, Arial !important;
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0px;
      }

      .rightcta {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .btn-red {
        margin-right: unset !important;
        margin-left: 30px;
        height: unset !important;
        width: unset !important;
      }
    }
  }
}

@media (max-width: 1440px) {
  .sitewide-alert.alert.alert-Gartner2023 {
    //background-position-x: -25px;
    background-position-x: -40px;
    .row.alert .col-md-9 {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
    }

    .row.alert .col-xl-9 {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
    }
  }
}
@media (max-width: 1200px) {
  .sitewide-alert.alert.alert-Gartner2023 {
    background-image: none;

    .container {
      //width: 100% !important;
      width: 93.75% !important;

      .alert {
        span.axwbline {
          padding-left: 30px;
        }
        span.axwleader {
          padding-right: unset !important;
        }
        .col-xl-9 {
          padding-left: unset !important;
          padding-right: unset !important;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .sitewide-alert.alert.alert-Gartner2023 {
    .container {
      padding: 20px 0px;
      .alert {
        .col-xl-9 {
          justify-content: center;
        }
        .col-lg-12 {
          padding: 10px 0;
        }
        .rightcta {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .sitewide-alert.alert.alert-Gartner2023 {
    .container {
      padding: unset !important;

      .alert {
        span.axwleader {
          padding-right: unset !important;
          text-align: center;
          padding-bottom: 15px;
        }

        .btn-red {
          margin-left: unset !important;
          margin-top: 20px;
        }

        .col-lg-12 {
          flex-direction: column !important;
          align-items: center !important;
        }
      }
    }
  }
}
