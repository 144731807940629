//
// Lato Regular
// http://www.latofonts.com/
// ------------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}/lato-regular.eot');
  src: local('Lato Regular'),
       local('Lato-Regular'),
       url('#{$font-path}/lato-regular.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/lato-regular.woff2') format('woff2'),
       url('#{$font-path}/lato-regular.woff') format('woff'),
       url('#{$font-path}/lato-regular.ttf') format('truetype'),
       url('#{$font-path}/lato-regular.svg#lato') format('svg');
  font-weight: 400;
  font-style: normal;
}
