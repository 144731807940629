/* Begin Footer Styles */

#footer {
  background: $gray-dark-2020;
  padding: 90px 0 60px;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  color: white;

  .container {
    max-width: 1260px;
  }

  #block-footerfirstmenus,
  #block-footersecondmenu {
    margin: 0 1em;
    padding: 0 5px;
  }

  ul.footer-first-menus,
  ul.footer-second-menu {
    display: block;

    li {
      display: block;
      a {
        color: white;
      }
    }
  }

  .footermap-item {
    a {
      color: white;
    }
  }

  .col-footer {
    margin-bottom: $spacing-md;
    //padding: 0 15px;
    border-left: 1px solid $gray;

    &:first-child {
      border: none;
    }
    @media (max-width: $lg) {
      //padding: 0 15px;

      &:nth-child(2) {
        border: none;
      }
    }
    @media (max-width: $md) {
      border: none;
      text-align: center;
    }
    @media (max-width: $sm) {
      border: none;
    }

    .footer-logo {
      height: 50px;
      margin: -23px 0 20px;
    }

    p {
      color: white;
      font-size: 15px;
      font-weight: 300;

      @media (max-width: $lg) {
        font-size: inherit;
      }

      @media (max-width: $md) {
        font-size: 15px;
      }
    }

    ul {
      padding-left: 15px;
      margin: 0;

      @media (max-width: $lg) {
        padding-left: 0;
      }

      @media (max-width: $md) {
        padding: 0;
      }
    }
  }

  .col-footer-nav {
    @media (max-width: $md - 1px) {
      display: none;
    }
  }

  .col-social {
    padding-left: 45px;

    @media (max-width: $md) {
      padding-left: 0;
    }

    .social-title {
      margin-bottom: $spacing-sm;
      color: $white;

      @media (max-width: $md) {
        display: none;
      }
    }
    .social-link {
      margin-right: 15px;

      @media (max-width: 1024px) {
        /* margin: 0 10px; */
        /*margin: 0 7px;*/
        margin-right: 7px;
      }

      img {
        height: 25px;

        @media (max-width: 1024px) {
          height: 23px;
          //max-width: 30px;
          max-height: 23px;
        }

        @media (max-width: $md) {
          height: 30px;
          //max-width: 30px;
          max-height: 30px;
        }
      }
    }
  }
}

/* End Footer Styles */

/* Teaser Styles */

#teaser {
  padding: 15px 0;
  background-color: $teaser-footer;
  font-size: 12px;
  color: #bcbcbc;
  line-height: 14px;
  font-weight: normal;
  font-family: Roboto, "Helvetica Neue LT W01_75 Bold", Arial, Helvetica, sans-serif;

  @media (max-width: 1024px) {
    .teaser-container {
      flex-direction: column;

      .col-md-3 {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .copyright-menu,
  .footermap-header--copyright-menu {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;

    @media (max-width: 1024px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .copyright-menu li,
  .footermap-item {
    // display: inline-block;
    display: block;
    list-style: none outside none;
    padding: 0 10px;
    border-right: 1px solid #bcbcbc;


    &:last-child {
      border-right: none;
    }

    a {
      color: #bcbcbc;
      font-family: Roboto,"Helvetica Neue LT W01_51488890",Arial,Helvetica,sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
    }

    @include media-breakpoint-up(md) {
      // padding: 0 30px;
      padding: 0 24px;
    }

    @media (max-width: 1078px) {
      padding: 10px 24px 0;
      border: 0;
    }
  }
}

#block-copyrightmenu {
  margin: 0 auto;
  padding: 0;
}

.teaser-container {
  display: flex;
  flex-direction: row;
  padding: 0;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1024px) {
      flex-direction: column;
      margin: auto;
    }
  }
}

.teaser-card {
  display: inline-block;
  padding: 0 30px;
}

.teaser-card a {
  color: #bcbcbc;
}

.teaser-bar {
  width: 1px;
  max-width: 1px;
  background: #bcbcbc;
}

/* End Teaser Styles */
