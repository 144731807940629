//
// Lato Light Italic
// http://www.latofonts.com/
// ------------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}/lato-lightitalic.eot');
  src: local('Lato Light Italic'),
       local('Lato-LightItalic'),
       url('#{$font-path}/lato-lightitalic.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/lato-lightitalic.woff2') format('woff2'),
       url('#{$font-path}/lato-lightitalic.woff') format('woff'),
       url('#{$font-path}/lato-lightitalic.ttf') format('truetype'),
       url('#{$font-path}/lato-lightitalic.svg#lato') format('svg');
  font-weight: 300;
  font-style: italic;
}
