//
// Lato Bold Italic
// http://www.latofonts.com/
// ------------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}/lato-bolditalic.eot');
  src: local('Lato Bold Italic'),
       local('Lato-BoldItalic'),
       url('#{$font-path}/lato-bolditalic.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/lato-bolditalic.woff2') format('woff2'),
       url('#{$font-path}/lato-bolditalic.woff') format('woff'),
       url('#{$font-path}/lato-bolditalic.ttf') format('truetype'),
       url('#{$font-path}/lato-bolditalic.svg#lato') format('svg');
  font-weight: 700;
  font-style: italic;
}
