// Brand Colors
// ==================================================
$red-light:     #e03d39;
$red:           #E82C2A;
$red-dark:      #DB110F;

$slate-light:   #414c55;
$slate:         #2C3A44;
$slate-dark:    #1B2A35;
$slate-darker:  #181D21;

$aqua-light:    #3694a3;
$aqua:          #008999;
$aqua-dark:     #047583;

// Brand 2020 Colors
// ==================================================
$gray-dark-2020:     #4A4F54;
$teaser-footer:      #3B3E42;

// Brand 2024 Colors
$dark-gray-2024: #22272B; 

// Gradients
$teal-grad:           linear-gradient(270deg, #82b2bf 0%, #006580 100%);

// end - Brand 2020 Colors
// ==================================================

// Gray Scale
// ==================================================
$white:         #FFF;
$gray-bkg:      #f8f8f8;
$gray-lightest: #C9D3D9;
$gray-lighter:  #B5C0C7;
$gray-light:    #7A929F;
$gray:          #5F7581;
$gray-dark:     #566872;
$gray-darker:   #444C52;
$gray-darkest:  #353535;
$black:         #181D21;


// Gradients
// ==================================================
$aqua-grad:     linear-gradient(163deg, #008999 0%, #2C3A44 100%);
$red-grad:      linear-gradient(163deg, #E82C2A 0%, #CC1A18 100%);
$slate-grad:    linear-gradient(163deg, #506570 0%, #181D21 100%);
$overlay-grad:  linear-gradient(270deg, rgba(44, 58, 68, .3) 0%, rgba(44, 58, 68, .8) 100%);
$overlay-grad-is:  linear-gradient(270deg, rgba(44,58,68,0.7) 0%, rgba(44,58,68,0.7) 100%);
$grey-light-grad:  linear-gradient(163deg, #C6CFD1 0%,#585B5B 100%);

// Box Shadows
// ==================================================
$box-shadow: 0 0 15px 0 rgba(0,0,0,0.06);

// Fonts
// ==================================================
$font-serif: Georgia, "Times New Roman", Times, serif;
$font-sans:  'Lato', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
$font-mono:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-weight-normal: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-path: '../fonts';


// Break Points (From Bootstrap)
// ==================================================
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1600px;


// Padding and Margins
// ==================================================
$spacing-base: 15px;

$spacing-xl: $spacing-base * 6;   // 90px
$spacing-lg: $spacing-base * 3;   // 45px
$spacing-md: $spacing-base * 2;   // 30px
$spacing-sm: $spacing-base;       // 15px
$spacing-xs: 10px;

$section-padding: $spacing-xl;    // 90px
$section-padding-sm: $spacing-lg; // 45px
