// ============================================================================================================
// Homepage
// ============================================================================================================

#hero-2021.level-10 {
  background-color: #000000;
  /* height: 446px;*/
  height: 473px;
  min-height: unset;
  margin: 102px 0 0;
  padding: 0!important;

  .row {
    align-items: center !important;

    .hero-content {
      h1 {
        color: #FFFFFF !important;
        font-family: Roboto Slab, Roboto, Arial;
        font-size: 42px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
        padding-right: 50px !important;
        margin-top: unset !important;
        margin-right: unset !important;
        margin-bottom: 20px !important;
      }

      p {
        color: #FFFFFF !important;
        font-family: Roboto !important;
        font-size: 20px !important;
        font-weight: 400 !important;
        line-height: 23px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
        margin-right: unset !important;
        max-width: 700px;
        padding-right: 80px !important;
      }
    }

    .right-content.col-sm-12.col-lg-6 {
      display: flex;
      align-items: center;
      justify-content: center;
      img.centerimg{
        width: 813px;
        height: 472px;
      }
    }
  }
}

@media (max-width: 1200px) {
  #hero-2021.level-10 .row {
    .hero-content h1, .hero-content p {
      padding-right: unset !important;
    }

    .hero-video .bg-video-wrap video {
      left: 160px !important;
    }
  }
}
@media (max-width: 992px) {
  #hero-2021.level-10 .hero-video .bg-video-wrap video {
    left: 180px!important;
  }
}

@media (max-width: 991px) {
  #hero-2021.level-10 {
    height: 671px;

    .hero-content {
      display: flex;
      align-items: center;
      padding-top: 20px;
      flex-direction: column;
    }
  }
}
@media (max-width: 576px) {
  #hero-2021.level-10 .row .hero-content {
    width: 100% !important;
      h1, p {
        text-align: center !important;
      }
  }
}
@media (max-width: 486px) {
  #hero-2021.level-10 {
    height: 700px;
  }
}