// Begin Stats Styles

.griffin-box,
.griffin-box-slate,
.griffin-box-aqua,
.griffin-box-red {
  position: relative;
  padding: 60px 0;
  background: $teal-grad;
  color: white;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;

  h2,
  h3,
  h4,
  p {
    color: $white;
  }

  .container {
    max-width: $xl + 60px;
    width: 93.75%;

    @media (max-width: $xxl) {
    }

    .row {
      .col-md-8 {
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          align-self: flex-start;
        }
      }

      .col-md-4.center-child {
        //display: block;
        img {
          // margin-top: $spacing-lg;
          //  width: 100%; Removing to allow images to maintain natural size.

          @media (max-width: $md - 1px) {
            margin-top: 30px;
            width: 90%;
          }
        }
      }
    }
  }

  .griffin {
    display: block;
    position: absolute;
    top: -90px;
    left: 0;
    height: 560px;
    width: 560px;
    background: transparent left top no-repeat;
    background-size: contain;

    @media (max-width: $md) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center center;
    }
  }

  .stat-box {
    // add to .row for stat box

    @media (max-width: $md) {
      text-align: center;
      .field--item.col-md-4:last-child p {
        margin-bottom:0px;
      }
    }

    h2,
    h3 {
      margin-bottom: $spacing-sm;
      font-size: 60px;
      line-height: 1.1em;
      color: $white;

      @media (max-width: $xl) {
        font-size: 48px;
      }
      @media (max-width: $lg) {
        font-size: 45px;
      }
      @media (max-width: $md) {
        font-size: 70px;
      }
      @media (max-width: $sm) {
        font-size: 60px;
      }
    }
    p {
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.8);

      @media (max-width: $md) {
        margin-bottom: $spacing-lg;
      }
    }
  }

  .cta-box {
    // add to .row for cta box
  }

  .btn {
    margin-right: 23px;

    &:last-child {
      margin-right: 0;
    }
  }

  .img-container {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    &:hover {
      .play-btn {
        opacity: 0.9;
      }
    }

    .play-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }
}

.griffin-box-slate {
  background: $slate-grad;

  .griffin {
    background-image: url("../img/griffin-slate.svg");
  }
}

.griffin-box-aqua {
  background: $teal-grad;

  .griffin {
    background-image: url("../img/griffin-aqua.svg");
  }
}

.griffin-box-red {
  //background: $red-grad;
  background: $grey-light-grad;

  .griffin {
    //background-image: url("../img/griffin-red.svg");
    background-image: url("../img/griffin-slate.svg");
  }
}

.griffin-box-narrow {
  padding: $spacing-lg 0;
  overflow: hidden;

  .griffin {
    height: 360px;
    width: 360px;
    top: -45px;
  }
}

.griffin-box-xl {
  padding: 60px 0;
  overflow: hidden;

  .griffin {
    height: 100%;
    width: 100%;
    top: 0;
    background-size: 60% auto;
    background-position: 0 30%;
    background-image: url("../img/griffin-slate.svg");
  }
}

.griffin-box__title {
  margin-bottom: 0;
}

.griffin-box__content > * {
  margin-bottom: $spacing-sm;
}

.griffin-box__form--inline {
  display: flex;
  flex-direction: row;
  max-width: 700px;
  margin: auto;


  #register-email {
    @media (max-width: $sm) {
      margin-bottom: $spacing-md;
    }
  }

  .form-group {
    margin-left: $spacing-sm;

    &:first-child {
      margin-left: $spacing-sm;
    }
  }

  input {
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
  }

  @media (max-width: $sm) {
    flex-direction: column;
  }
}

#appcform a {
  color: #fff;
  font-size: 16px;
}
#appcform a:hover {
  text-decoration: underline;
}
