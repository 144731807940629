// ***************     RESOURCE GRID      ***************
.resource-grid {
  .img-tile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    //flex: 1 0 auto;
    position: relative;
    background: $slate-grad;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: $spacing-md;
    min-height: 285px; // exactly half of large img tile
    color: $white;

    h3 {
      position: relative;
      z-index: 1;
      color: $white;
    }

    p {
      position: relative;
      z-index: 1;
      color: $white;
    }

    a {
      color: $white;
    }

    &-lg {
      min-height: 570px;
      flex:1;
    }

    &.video-tile {
      .play-btn {
        display: block;
      }

      .category {
        color: $white;
      }
    }

    .grad-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $overlay-grad;
      z-index: 1;
    }

    .category {
      color: $white;
      margin: 0;
      font-size: 15px;
      text-transform: uppercase;
      z-index: 2;
      color: $white;

      a {
        color: $white;
      }
    }

    hr {
      width: 60px;
      border-bottom: 2px $aqua solid;
      margin: $spacing-sm 0;
      z-index: 2;
    }

    h3 {
      margin: 0;
      z-index: 2;
    }

    .play-btn {
      display: none;
      position: absolute;
      top: 50%;
      margin-top: -30px;
      left: 50%;
      margin-left: -30px;
      opacity: 0.9;
      z-index: 3;
    }
  }
}

#resource-grid-v2 {
  .flex-card-container {
    padding-top: 70px;
    .container {
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    .flex-card {
    width: 50% !important;
    max-width: unset !important;
    margin-top: unset !important;
      @media (max-width: 768px) {
        width: 100% !important;
      }
      .card-body {
        padding: 20px 20px;
      }
  }
  .flex-card.blog-card .card-body {
    background-image: unset !important;
    background-color: rgba(255, 255, 255, 0.82);
    margin-top: 33%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 125px;
    .resource-type {
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #D22630;
      text-transform: uppercase;
    }
    .blog-title {
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      color: #4A4F54;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
    .blog-card-body {
      padding: 20px 48px;
    }
  }
}