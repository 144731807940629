// GLOBAL MODAL STYLES
.modal {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: 0;
  padding: $spacing-xl;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;

  @include media-breakpoint-down(sm) {
    padding: $spacing-sm;
  }
  @include media-breakpoint-down(xs) {
    padding: 0 !important; // important is needed here to override the inline style added by bootstrap
  }

  &.show {
    display: flex !important; // important is needed here to override the inline style added by bootstrap
  }

  .modal-body {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    background: white;

    @include media-breakpoint-down(sm) {
      flex: 1 1 0;
      max-width: 100%;
    }

    .modal-image {
      display: block;
      width: 375px;
      min-width: 375px;
      background: $slate-light center center no-repeat;
      background-size: cover;

      @include media-breakpoint-down(md) {
        display: none;
        width: 0;
        min-width: 0;
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      width: 580px;
      max-height: 100%;
      overflow-y: auto;
      border-radius: 0;
      box-shadow: none;
      padding: 40px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      .modal-title {
        margin-bottom: $spacing-sm;
      }

      .modal-subtitle {
        margin-bottom: $spacing-md;
      }
    }
  }

  // Close Button
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    background: url("../img/icn-close.svg") no-repeat center center;
    background-size: 20px 20px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

#videoModal {
  .modal-content {
    padding: 45px 0 0 0;
  }
  .close-btn {
    height: 30px;
    width: 30px;
  }
}

// Contact Button Style
#contact-btn {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
  position: fixed;
  right: -60px;
  background: $red-dark;
  padding: 10px 30px;
  box-sizing: border-box;
  z-index: 9;
  top: 35vh;
  border-radius: initial;
  height: 55px;
  color: $white;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 19px;
  font-weight: bold;
  line-height: 10px!important;
  text-transform: initial;

  @media (max-width: 575px) {
    background: #018999;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    border-radius: 100%;
    bottom: 30px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    color: transparent;
    right: 30px;
    top: auto;
    transform: rotate(0deg);
    transform-origin: center;
    width: 60px;
    height: 60px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20height%3D%2228%22%20width%3D%2240%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M38.93%2026.028L26.313%2012.875l11.64-10.62L20.12%2013.391%202.283%202.254l11.64%2010.621L1.31%2026.028%2016.68%2015.392l3.438%203.136%203.437-3.136zM0%2027.746h40V0H0z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
  }
}

// Special cases for landing pages
body {
  &.path-node-195,
  &.path-node-196,
  &.path-node-197 {
    #event {
      .col-event-about {
        @media (max-width: $lg) {
          padding-left: 0;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        &.col-event-register {
          @media (max-width: $lg) {
            padding-left: 45px;
          }
        }
      }
    }
    #contact-btn {
      @media (max-width: 575px) {
        display: none;
      }
    }
  }
}

// Contact Modal
#contact-modal {
  #contact-form {
    input[type="text"],
    textarea {
      margin-bottom: 20px;
    }
    .contact-form-footer {
      display: flex;
      flex-direction: row;
      max-width: 100%;

      @media (max-width: $md) {
        display: block;

        .form-footer-label {
          margin: 0;
        }
      }
    }
  }

  .modal-body {
    .modal-content {
      h3 {
        font-size: 30px;
      }

      .modal-subtitle {
        margin-bottom: 10px;
      }
    }
  }
}

// Leadership Modal
#leadership-modal {
  .leader-title {
    color: $gray-light;
    margin-bottom: 0;
  }
  hr {
    margin: $spacing-md 0;
    border-bottom: 1px solid $gray-lighter;
    width: 125px;
    max-width: 100%;
  }
  .leader-modal-social-links {
    padding: $spacing-sm 0;

    .social-link {
      margin-right: $spacing-md;

      img {
        height: 30px;
      }
    }
  }
}

// Video Modals
#popupVid,
#popupHeroVid {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  z-index: 9999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
}

// NEW VIDEO MODAL
#video-modal {
  @media (max-width: $md) {
    padding: 80px 0 30px;
  }

  .modal-body {
    background: transparent;
  }

  .close-btn {
    // background-color: $white;
    opacity: 1;
    right: 0;
    z-index: 1;
    top: 0;
    position: absolute;
    filter: brightness(0) invert(1);
  }
}
// 1280 x 720
// xl: 1200: 1200 x 675
// lg: 992: 992 x 558
// md: 768: 768 x 432
// sm: 576: 576 x 324
// xs: 0:
.embed-container {
  width: 1080px;
  max-width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
