// centers a single child on x and y in a div
// @include center-child();
@mixin center-child() {
  display: flex;
  align-items: center;
  justify-content: center;
}

// centers content vertically with flexbox
// @include center-vertically();
@mixin center-vertically() {
  display: flex;
  align-items: center;
}

// Ellipsis a string of text
// @include ellipsis();
@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Center a background image in a div
// @include center-bkg();
@mixin center-bkg() {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}