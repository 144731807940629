a,
[onclick] {
  cursor: pointer;
}

a:focus,
a:hover {
  text-decoration: none;
}

.mktoForm .mktoButtonWrap.mktoDownloadButton button.mktoButton,
.mktoForm .mktoButton,
#instant-contact-us .mktoForm .mktoButton,
.btn {
  display: inline-block;
  height: 40px;
  padding: 0 35px;
  border: 1px solid $white;
  border-radius: initial;
  background-color: $red-dark;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  color: $white;
  font-size: 17px;
  line-height: 40px;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

.btn-small {
  height: 34px;
  padding: 0 25px;
  font-size: 14px;
  line-height: 34px;
}

.btn-red {
  background-color: $red-dark;
  border: 1px solid $red-dark;
  box-shadow: $box-shadow;
  margin-right: 30px;

  @media (max-width: $sm) {
    margin-right: 0;
  }
}

.btn-red:hover {
  background-color: $red-dark;
  border: 1px solid $red-dark;
}

.btn-red-outline {
  border: 1px solid $red;
  color: $red;
}

.btn-red-outline:hover {
  border: 1px solid $red-dark;
  color: $red-dark;
}

.mktoForm .mktoButtonWrap.mktoDownloadButton button.mktoButton,
.mktoForm .mktoButton,
#instant-contact-us .mktoForm .mktoButton,
.btn-info,
.btn-aqua {
  background-color: $aqua;
  border: 1px solid $aqua;
  box-shadow: $box-shadow;
}

.mktoForm .mktoButtonWrap.mktoDownloadButton button.mktoButton:hover,
.mktoForm .mktoButton:hover,
#instant-contact-us .mktoForm .mktoButton:hover,
.btn-info:hover,
.btn-aqua:hover {
  background-color: $aqua-dark;
  border: 1px solid $aqua-dark;
}

.btn-aqua-outline {
  color: $aqua;
  border: 1px solid $aqua;
}

.btn-aqua-outline:hover {
  color: $aqua-dark;
  border: 1px solid $aqua-dark;
}

.btn-slate,
.btn-primary {
  background-color: $slate;
  border: 1px solid $slate;
  box-shadow: $box-shadow;
}

.btn-slate:hover,
.btn-primary:hover {
  background-color: $slate-dark;
  border: 1px solid $slate-dark;
}

.btn-slate-outline {
  color: $slate;
  border: 1px solid $slate;
}

.btn-slate-outline:hover {
  color: $slate-dark;
  border: 1px solid $slate-dark;
}

.btn-white {
  background-color: $white;
  border: 1px solid $white;
  color: #353535;
  box-shadow: $box-shadow;
}

.btn-white:hover {
  border: 1px solid #f3f6f7;
  background-color: #f3f6f7;
  color: #353535;
}

.btn-white-outline {
  border: 1px solid $red;
  color: white;
}

.btn-white-outline:hover {
  border: 1px solid #f3f6f7;
  color: #f3f6f7;
}

.btn:disabled,
.btn-disabled {
  border: 1px solid #b5c0c7;
  background-color: #b5c0c7;
  color: #353535;
}

.btn:disabled:hover,
.btn-disabled:hover {
  border: 1px solid #b5c0c7;
  background-color: #b5c0c7;
  color: #353535;
}

.btn-red-outline:disabled,
.btn-dark-outline:disabled,
.btn-aqua-outline:disabled,
.btn-white-outline:disabled,
.btn-disabled-outline {
  border: 1px solid #b5c0c7;
  color: #b5c0c7;
}

.btn-red-outline:disabled:hover,
.btn-dark-outline:disabled:hover,
.btn-aqua-outline:disabled:hover,
.btn-white-outline:disabled:hover,
.btn-disabled-outline:hover {
  border: 1px solid #b5c0c7;
  color: #b5c0c7;
}

#edit-submit-events {
  display: none;
}

/// Specific to Marketo Forms
.mktoForm .mktoButtonWrap.mktoDownloadButton button.mktoButton,
.mktoForm .mktoButton {
  background-color: $aqua !important;
  border: 1px solid $aqua !important;
  box-shadow: $box-shadow !important;
}

/// End of Design for Marketo Forms
