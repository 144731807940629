// ***************     EXTERNAL LIBS      ***************
@import "../node_modules/bootstrap/scss/bootstrap";

// ***************     SETUP      ***************
@import "variables";
@import "mixins";

// ***************     BASE COMPONENTS      ***************
@import "base/fonts";
@import "base/typography";
@import "base/buttons";
@import "base/forms";
@import "base/lists";

// ***************     PRIMARY PARTIALS      ***************
@import "sections/modal";
@import "sections/footer";
@import "sections/heroes";

// ***************     PARTIALS      ***************
@import "sections/griffin-box";
@import "sections/resource-grid";
@import "sections/video-banner";

// ***************     DESIGN 2021      ***************
@import "design2021";
@import "base/paragraphs";
@import "v3";

// ***************     MEGAMENU      ***************
@import "menus/nav";
@import "menus/menu-login";
@import "menus/menu-languaje";
@import "menus/megamenu";

// ==================================== ==================================== ==================================== *
//                                            $_GLOBAL_STYLES
// ==================================== ==================================== ==================================== *
%video-link-base {
  display: block;
  position: relative;
  width: 100%;
  // add background image inline so that it can be updated by Drupal
  background: $slate-grad center center no-repeat;
  background-size: contain;
  min-height: 337px;
  cursor: pointer;
  flex: 1;
}

* {
  outline: none !important;

  &:focus {
    outline: none !important;
  }
}

.brand-bar-imgs .col {
  flex-basis:0;
  flex-grow:0;
  max-width:100%;
}

.brand-bar-imgs img {
  //margin-right: 10.847222%;
  //height: 80px;
  /* max-width: 300px; */
  //width: auto;
  margin-right: 8%!important;
  max-height: 80px!important;
  max-width: 20%!important;
  width: auto!important;

  @media (max-width: 1052px) {
    margin-left: auto!important;
    margin-right: auto!important;
    width: auto!important;
    max-width: 300px!important;
  }
}

#downloadForm {
  display:none;
  @media (max-width: 768px) {
    display:block;
  }
}

.brand-bar-imgs {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 1052px) {
    flex-direction: column;

    img {
      margin-bottom: 16px;
    }
  }
}

.brand-bar-row {
  padding: 50px 0px;
}

.brand.container {
  max-width: 1260px;
  width: 93.75%;
}

.form-sidebar {
  max-width: 630px;
  width: 100%;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.optimized-landing-header {
  background: url("../img/griffin-slate.svg");
  background-position: center top;
  background-repeat: no-repeat;
  height: 900px;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100vw;
  background-size: 80%;
  opacity: 0.19;

  @media only screen and (max-width: $sm) {
    opacity: 0;
  }
}

.optimized-logo-header {
  margin-top: 40px;
  margin-bottom: 40px;
}

.download-whitepaper-headline {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  color: #2c3a44;

  @media only screen and (max-width: $sm) {
    font-size: 32px;
  }
}

.download-now {
  color: #008999;
  font-family:  Roboto, Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 35px;
}

.whitepaper-list {
  color: #353535;
  font-family: Roboto, Arial, Helvetica, sans-serif!important;
  font-size: 20px;
  line-height: 34px;
}

.whitepaper-teaser.container {
  background: #e9ebee;
  padding: 40px;
  margin-top: 38px;

  img {
    max-width: 164px;
    float: right;
  }

  @media only screen and (max-width: $sm) {
    img {
      margin-top: 16px;
      max-width: 300px;
      float: none;
    }
  }
}

.whitepaper-teaser-title {
  color: #353535;
  font-family: Roboto, Arial, Helvetica, sans-serif!important;
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  max-width: 366px;
}

.whitepaper-teaser-text {
  color: #353535;
  font-family: Roboto, Arial, Helvetica, sans-serif!important;
  font-size: 18px;
  line-height: 34px;
  max-width: 366px;
}

.whitepaper-long-desc {
  padding: 50px 0px;

  @media (max-width: 768px) {
    max-width: 600px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

#roundedImage {
  @media (max-width: 768px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.whitepaper-long-headline {
  color: #008999;
  font-family:  Roboto, Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}

.whitepaper-long-body {
  color: #353535;
  font-family:  Roboto, Arial, Helvetica, sans-serif!important;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  margin-top: 24px;
  margin-bottom: 42px;
}

.whitepaper-long-callout {
  color: #353535;
  font-family: Roboto, Arial, Helvetica, sans-serif!important;
  font-size: 28px;
  font-style: italic;
  font-weight: 500;
  line-height: 32px;
}

.full-width-quote.optimized {
  padding-top: 37px !important;
  padding-bottom: 37px !important;
}

.whitepaper-quote {
  color: #ffffff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 37px;
  max-width: 976px;

  @media (max-width: 768px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.whitepaper-quote-source {
  color: #ffffff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 29px;

  @media (max-width: 768px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.brand-bar-headline {
  color: #008999;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 42px;

  @media (max-width: 768px) {
    max-width: 566px;
    margin-left: auto;
    margin-right: auto;
  }
}

html {
  max-width: 100%;
  overflow-x: hidden;
  // height: 100%;
  height: auto !important;
  min-height: 100%;
  font-size: 10px; // ROOT PIXEL

  &.calendly-page-scroll-locked {
    height: 100% !important; /* set height when popup is active */
  }
}

body {
  display: flex;
  flex-direction: column;

  max-width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  padding-right: 0 !important; // prevents bootstrap modal from adding padding.
  font-size: 1.8rem; // 18px
  color: $gray-darkest;
  font-weight: $font-weight-normal; // 300
  text-align: left;

  &.page-node-type-press-release {
    .col-md-12 {
      margin-bottom: 44px;
      margin-top: 44px;

      @media (min-width: $md) {
        max-width: calc(100vw - 7%);
      }

      @media (min-width: $xxl) {
        max-width: 1260px;
      }
    }
  }
}

// .field--name-body, .content.container, .flex-contact {
//     max-width: 1240px;
//     width: 93.75%;
//     margin-left: auto!important;
//     margin-right: auto!important;
// }

.dialog-off-canvas-main-canvas {
  // makes a Sticky footer for drupal added markup
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  & + .main {
    flex: 1 0 auto;
  }
}

.content {
  padding-left: 0;
  padding-right: 0;
}

.modal-body {
  height: 100%;
}

.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 20px;
  padding-left: 0;
  display: none;

  li {
    a {
      color: $white;

      &:hover {
        text-decoration: underline;
      }
    }

    &:before {
      content: "/";
      margin: 0 10px;
    }

    &:first-child:before {
      content: none;
    }
  }
}

.card-footer.blog-card-footer > span {
  color: $red;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:before {
    content: "";
    border: 1px solid $red;
    display: inline-block;
    width: 30px;
    margin-right: 5px;
    background: $red;
  }
}

.current-page-subnav {
  font-weight: bold;

  &:after {
    content: "";
    width: 100%;
    border: 1px solid $aqua;
    display: block;
  }
}

.video-image {
  display: flex;
  flex-direction: column;
}

.video-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  img {
    @media only screen and (max-width: $sm) {
      width: 90%;
      height: auto;
    }
  }
}

.flexor {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  @media only screen and (max-width: $lg - 1px) {
    a:first-child {
      margin-bottom: 30px;
    }
  }
}

.hero-video-col {
  .play-btn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .play-btn {
    position: relative;
    display: block;
    //border: 5px solid white;
    /* padding: 20px; */
    height: 90px;
    width: 90px;
    border-radius: 100%;
  }

  /*.play-btn::after {
    bottom: -25px;
    color: #fff;
    content: "Watch Video";
    display: block;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    width: 100px;
    text-align: center;
    left: -50%;
    right: -50%;
}*/
}

.col-md-12 {
  width: 93.75%;

  margin: auto;
}

.partner-marketplace-form-wrapper {
  padding-top: 90px;
  left: calc(-1 * (100vw - 100%) / 2);
  position: relative;
  width: 100vw;
  background: $gray-bkg;

  @media (max-width: $md - 1px) {
    margin-top: 30px;
  }

  .text > * {
    width: 77%;
    height: auto;
    margin: auto;
    display: block;
  }

  .text {
    h3 {
      margin-bottom: 30px;
    }

    img {
      margin-top: 60px;

      @media (max-width: $md - 1px) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }

  .partner-marketplace-form {
    @media (max-width: $md - 1px) {
      width: 77%;
    }
  }
}

#noResults {
  display: none;
}

.field--name-body {
  max-width: 1230px;
  width: 93.75%;
  margin-left: auto !important;
  margin-right: auto !important;
}

#navigation
#navbar-nav-wrapper
.dropdown-menu
.dropdown-nav
.dropdown-nav-column
li
span {
  @media (max-width: $lg - 1px) {
    color: $white;
    padding-left: 0;
  }
}

#navigation.clear
.container
#navbar-nav-wrapper
.navbar-nav
> li
.dropdown-menu.show
.dropdown-nav
.dropdown-nav-column
a {
  @media (max-width: $lg - 1px) {
    padding-left: 70px;
  }
}

// ==================================== ==================================== ==================================== *
//                   Slant Cards
// ==================================== ==================================== ==================================== *
// Slant Card

.watch-customer-video {
  color: $red;
  //font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  display: block;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin-top: auto;
}

.deck-card {
  .slant-card-footer {
    .name {
      color: initial;
    }
  }
}

.col-slant-card {
  display: flex;
  flex-direction: column;
}

.slant-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 30px;

  .slant-card-bkg {
    position: absolute;
    top: 0;
    right: 12px;
    left: 12px;
    height: 200px;
    // background-image added inline for dynamic updating by CMS
    background-color: $aqua-dark;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% 90px, 0 200px);
    //transform: skewY(-13deg);
    //transform-origin: top left;
  }

  .slant-card-content {
    flex-grow: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 25px 20px;
    //box-shadow: $box-shadow;  // box-shadow and clip path do not work together
    padding: 25px 20px 20px;
    background-color: $white;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;

    &:hover {
      transition: all 0.25s ease;
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
    }

    p {
      color: #747474;
      font-size: 16px;
      line-height: 30px;
    }

    &.top-hat {
      display: flex;
      margin: 60px 20px 20px;
      padding-top: 110px;
      clip-path: polygon(0 90px, 100% 0, 100% 100%, 0 100%);
    }

    .slant-card-title {
      margin-bottom: 15px;
      border-left: 2px solid $aqua;
      padding-left: 15px;
      font-size: 22px;
      line-height: 29px;

      @media (max-width: $lg) {
        font-size: 19px;
      }
      @media (max-width: $md) {
        font-size: 22px;
      }
    }

    .slant-card-footer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 0;
      margin-top: auto;

      @media (max-width: $sm) {
        .btn {
          display: block;
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  } // close .slant-card-content

  _:-ms-lang(x),
  .slant-card-content.top-hat {
    // Targets Edge and IE
    margin-top: 90px;
    padding-top: 20px;
  }
}

p {
  color: #353535;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.9em;
  //margin: 0 0 10px;
  //text-align: inherit;
}

.photo-card-content.hover {
  .photo-card-message,
  hr,
  .hover-text {
    transition: all 1.5s ease;
  }
}

.photo-card-content.hover {
  &:hover > .hover-text {
    transform: translateY(0);
    position: absolute;
    width: 80%;
  }
  &:hover {
    .photo-card-message,
    hr {
      transform: translateY(-275px);
      visibility: 0;
      opacity: 1;
    }
  }
}

.hover-text {
  transform: translateY(220px);
  position: absolute;
  font-size: 15px;
  width: 80%;
}

.photo-message-cards {
  @media (max-width: 1024px) {
    .photo-message-card {
      height: 220px;
    }

    .hover-text {
      font-size: 12px;
    }
  }
  @media (max-width: $md) {
    .photo-message-card {
      height: 220px;
    }

    .hover-text {
      font-size: 14px;
    }
  }
  @media (max-width: $md - 1px) {
    .photo-message-card {
      height: 230px;
    }

    .hover-text {
      font-size: 14px;
    }
  }
}

.path-node-164 {
  #rowCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 93.75%;
    margin: auto;
    justify-content: space-around;
  }
}

#board {
  .container {
    width: 93.75%;
    max-width: 1260px;
    margin: auto;
  }

  .block-title {
    margin-bottom: 50px;
  }
}

.flexor {
  position: relative;
  z-index: 9;
}

.alternating-text-and-image-rever,
.alternating-text-and-image,
.alternating-text-and-image-reduc {
  max-width: 1440px;
  margin: auto;

  .img-card {
    @media (max-width: $md) {
      display: none;
    }
  }

  .text-card {
    @media (max-width: $md) {
      min-height: initial;
      padding: 0 6% 0;
    }
  }

  .row {
    &:last-child {
      .text-card {
        margin-bottom: 45px;
      }
    }
  }
}

.ar-background {
  background: #f7f7f7;
}

.path-node-207 {
  .hero-content {
    .play-container {
      flex: 1 0 auto;
    }
  }
}

.play-btn.Red {
  background: url(/themes/custom/axway/css/../img/btn-video-play-red.svg) center
  center no-repeat !important;
  background-size: cover !important;
}
.play-btn.Red:after {
  animation: playbtnpulsered 1.5s ease-out infinite;
  border-radius: 50%;
  content: "";
  display: inline-block;
  left: 30px;
  position: absolute;
  top: 30px;
  transform: translate(-50%, -50%);
  transform-origin: center;
}
.play-btn.Grey {
  background: url(/themes/custom/axway/css/../img/btn-video-play-grey.svg)
  center center no-repeat !important;
  background-size: cover !important;
}
.play-btn.Grey:after {
  animation: playbtnpulsegrey 1.5s ease-out infinite;
  border-radius: 50%;
  content: "";
  display: inline-block;
  left: 30px;
  position: absolute;
  top: 30px;
  transform: translate(-50%, -50%);
  transform-origin: center;
}
.play-btn.Aqua {
  background: url(/themes/custom/axway/css/../img/btn-video-play-aqua.svg)
  center center no-repeat !important;
  background-size: cover !important;
}
.play-btn.Aqua:after {
  animation: playbtnpulseaqua 1.5s ease-out infinite;
  border-radius: 50%;
  content: "";
  display: inline-block;
  left: 30px;
  position: absolute;
  top: 30px;
  transform: translate(-50%, -50%);
  transform-origin: center;
}

// ==================================== ==================================== ==================================== *
//                    Analyst Recognition & Find a Partner
// ==================================== ==================================== ==================================== *

.page-node-type-analyst-recognition {
  h1 {
    font-size: 30px;
  }

  .ar-content {
    padding: 40px 10px;
  }

  .ar-background .container {
    max-width: 1260px;
  }

  .ar-logo img {
    height: auto;
    max-width: 120px;
    width: 100%;
    padding-bottom: 20px;
  }

  .ar-link {
    margin-top: 20px;
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 20px;
    }

    .col-lg-9.hero-content {
      max-width: 100%;
    }
  }
}

.path-node-164 {
  .view-analyst-recognition {
    width: 100%;

    .ar-background {
      background: #f7f7f7;
    }

    .view-analyst-recognition {
      width: 100%;

      .main-view {
        .row {
          justify-content: center;
          width: 100%;
        }
      }
    }
  }

  .main {
    .container {
      max-width: 1260px;
      width: 90.75%;
    }
  }
}

.block-views-blockanalyst-recognition-analyst-block {
  margin-bottom: 90px;
}

.view-analyst-recognition {
  .pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    .pager__item {
      a {
        color: #353535;
      }
    }
  }

  .main-view {
    background: $white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);

    @media (max-width: $md) {
      box-shadow: none;
    }
  }

  // .control-label,
  .form-submit {
    display: none;
  }

  .control-label {
    width: 100%;
    text-align: left !important;
    display: block !important;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-sm !important;
  }

  .view-filters.form-group {
    width: 90.75%;
    max-width: 1140px;
    margin: auto;

    @media (max-width: $md) {
      max-width: 340px;
    }
  }

  .form-date.form-control,
  .form-select.form-control {
    border: 1px solid #2c3a44;
    border-radius: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    color: #2c3a44;
    cursor: pointer;
    height: 40px !important;
    line-height: 33px;
    max-width: 300px;
    font-size: 16px;
    margin-right: 30px;
    // margin-top: 30px;
    margin-bottom: 30px;
  }

  .form-select.form-control {
    min-width: 180px;
    background-image: url(../img/icn-chevron-down-slate.svg);
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 15px auto;
  }

  #edit-field-products-target-id {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #2c3a44;
    border-radius: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    color: #2c3a44;
    cursor: pointer;
    background: url(../img/icn-chevron-down-slate.svg) no-repeat;
    background-position: right 15px center;
    background-size: 15px auto;
    height: 40px !important;
    line-height: 40px;
    max-width: 300px;
    font-size: 16px;
    margin-right: 30px;
  }

  .view-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 90.75%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .view-header {
    padding: ($spacing-base * 3) 0;
    text-align: center;
    width: 100%;

    a {
      color: $aqua-light;
      font-weight: $font-weight-medium;
    }
  }

  .views-infinite-scroll-content-wrapper {
    @media (max-width: $lg) {
      justify-content: space-around;
    }
    @media (max-width: $md - 1px) {
      justify-content: center;
      flex-direction: column;
    }
  }

  .views-row {
    max-width: 340px;
    width: 100%;
    margin-bottom: $spacing-lg;
    // display: flex!important;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 30px;
    border: 1px solid transparent;
    width: 27.441485068603713%;
    display: flex;
    flex-direction: column;
    //margin-right: 4.4%;

    @media (max-width: $lg) {
      width: 50%;
      margin-left: 0;
      margin-right: 0;
    }

    @media (max-width: $md) {
      width: 100%;
    }

    &:nth-child(3n + 1) {
      margin-left: 4.4%;

      @media (max-width: 1024px) {
        margin-left: 0;
        margin-right: 0;
      }

      @media (max-width: $md) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:nth-child(3n + 2) {
      margin-right: 4.4%;
      margin-left: 4.4%;
      //background: blue;

      @media (max-width: 1024px) {
        margin-left: 0;
        margin-right: 0;
      }

      @media (max-width: $md) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:hover {
      border: 1px solid $aqua;
    }

    /*&:nth-child(n+10) {
            visibility: hidden;
            opacity: 0;
            display: none;
        }*/

    .ar-card {
      flex-direction: column;
      display: flex;
      flex: 1;
      height: 100%;
    }

    .ar-url {
      width: 90%;
      margin-top: auto;
      padding-top: 10px;
      margin-left: auto;
      margin-right: auto;

      span {
        font-size: 14px;
        font-weight: 700;
        left: 4px;
        position: relative;
        top: 3px;
        color: #e82c2a;

        &:before {
          background-image: url(/themes/custom/axway/css/../img/uber-dash.svg);
          background-size: 31px 3px;
          content: " ";
          display: inline-block;
          height: 3px;
          margin-right: 4px;
          vertical-align: middle;
          width: 31px;
        }
      }
    }

    .ar-grey-img {
      //background: $gray-bkg;
      justify-content: center;
      display: flex;
      flex-direction: row;
      padding: 30px;
      box-sizing: border-box;
      height: 150px;
      align-items: center;

      img {
        max-width: 110px;
        width: 100%;
        height: auto;
      }

      .ar-url {
        margin-top: auto;
        width: 90%;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .ar-year {
      color: $gray-darkest;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }

    .ar-name {
      font-weight: bold;
      margin-top: $spacing-base + 5px;
      width: 90%;
      margin-right: auto;
      margin-left: auto;
    }

    .ar-desc {
      font-size: 18px;
      margin-top: $spacing-base + 5px;
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      color: #353535;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.9em;
      text-align: inherit;
    }
  }
}

.col-md-12 {
  .field--name-body {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
    width: 93.75%;
  }

  .flex-contact,
  .contact-address-location {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    width: 93.75%;
  }
}

article.product {
  .content {
    width: 93.75%;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
    margin-bottom: 45px;
  }
}

// ==================================== ==================================== ==================================== *
//                    Find a Partner
// ==================================== ==================================== ==================================== *

.path-node-221 {
  .main {
    .container {
      max-width: 1260px;
      width: 90.75%;

      @media (max-width: $md - 1px) {
        width: 100%;
        padding: 0;
      }
    }

    .col-md-12 {
      @media (max-width: $md - 1px) {
        padding: 0;
      }
    }
  }
}

.main.fp-background {
  background-color: $gray-bkg;
  .full_width.container {
    max-width: 1260px;
    width: 90.75%;

    @media (max-width: $md - 1px) {
      width: 100%;
      padding: 0;
    }
    .col-md-12 {
      @media (max-width: $md - 1px) {
        padding: 0;
      }
    }
  }
}


.fp-background {
  background-color: $gray-bkg;
}

.block-views-blockfind-a-partner-partner-block,
.block-views-blockcustomers-customer-block  {
  margin: 90px 0;

  @media (max-width: $md - 1px) {
    margin-top: 45px;
  }
}

.view-find-a-partner,
.view-customers
{
  .main-view {
    background: $white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);

    @media (max-width: $md - 1px) {
      box-shadow: none;
    }
  }

  .control-label,
  .form-submit {
    display: none;
  }

  .view-filters.form-group {
    width: 90.75%;
    max-width: 1055px;
    margin: auto;

    .form-inline {
      justify-content: flex-start;

      @media (max-width: $md - 1px) {
        justify-content: center;
      }

      @media (max-width: $md - 1px) {
        .form-item {
          width: 90%;
          max-width: 300px;
        }
      }
    }
  }

  .form-date.form-control {
    border: 1px solid #2c3a44;
    border-radius: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    color: #2c3a44;
    cursor: pointer;
    height: 40px !important;
    line-height: 40px;
    max-width: 300px;
    font-size: 16px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  select[data-drupal-selector="edit-field-products-target-id"],
  select[data-drupal-selector="edit-field-products-target-id-selective"],
  select[data-drupal-selector="edit-field-partner-type-target-id-selective"],
  select[data-drupal-selector="edit-field-country-target-id-selective"],
  select[data-drupal-selector="edit-field-challenge-target-id-selective"],
  select[data-drupal-selector="edit-field-products-2018-target-id-selective"],
  select[data-drupal-selector="edit-field-industries-target-id-selective"]{
    width: 250px;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid $slate;
    border-radius: 20px;
    // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: none;
    color: $slate;
    cursor: pointer;
    background: url(../img/icn-chevron-down-slate.svg) no-repeat;
    background-position: right 15px center;
    background-size: 15px auto;
    height: 40px !important;
    line-height: 30px;
    min-width: 195px;
    max-width: 300px;
    font-size: 14px;
    padding-left: 20px;
    margin-right: 30px;

    @media (max-width: $md - 1px) {
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      max-width: none;
    }
  }

  input[data-drupal-selector="edit-combine"] {
    background-color: $white;
    border: 1px solid $slate;
    color: $gray-darkest;
    font-size: 14px;
  }

  .view-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 90.75%;
    max-width: 1055px;
    margin-left: auto;
    margin-right: auto;
  }

  .view-header {
    padding: ($spacing-base * 3) 0;
    text-align: center;
    width: 100%;

    a {
      color: $aqua-light;
      font-weight: $font-weight-medium;
    }
  }

  .views-row {
    max-width: 320px;
    width: 22%;
    margin-bottom: $spacing-lg;
    display: flex;
    flex-direction: flex-start;
    flex-grow: 1;
    margin-right: 4.4%;
    //margin-right: 3.585671307633107%;

    @media (max-width: $md) {
      width: 33%;
    }
    @media (max-width: $md - 1px) {
      margin-left: 4.4%;
    }

    .partner-card {
      width: 100%;

      div {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:nth-child(3n + 2) {
      //margin-left: 4.4%;
      margin-right: 4.4%;
    }
    &:nth-child(3n + 1) {
      //margin-left: 4.4%;
      margin-right: 4.4%;
    }

    .views-field-field-greyscale-image {
      background: $gray-bkg;
      justify-content: center;
      display: flex;
      flex-direction: row;
      padding: 30px;
      box-sizing: border-box;
      height: 150px;
      align-items: center;

      .field-content img {
        max-width: 190px;
        width: 100%;
        height: auto;
      }

      .views-field-field-url-path {
        margin-top: auto;
      }
    }

    .views-field-field-company-name {
      font-weight: bold;
      margin-top: $spacing-base + 5px;
    }

    .views-field-field-analyst-short-description {
      font-size: 18px;
      margin-top: $spacing-base + 5px;
    }
  }

  .partner-title {
    color: #818181;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin-bottom: 3px;
  }

  .partner-type,
  .partner-products {
    color: #818181;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: $font-weight-normal;
    margin-bottom: 3px;
  }

  .partner-card {
    img {
      width: 100%;
    }
  }

  .partner-country {
    color: #a2a2a2;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: $font-weight-normal;
    letter-spacing: 1.5px;
    margin-bottom: 9px;
    text-transform: uppercase;
  }

  .partner-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 3px;
      height: 22px;
      width: 22px;

      &.partner-web {
        background-image: url("../img/find_a_partner_globe_icon.svg");
      }

      &.partner-mail {
        background-image: url("../img/find_a_partner_envelope_icon.svg");
        height: 15px;
        width: 25px;
      }

      &.partner-extra {
        background-image: url("../img/find_a_partner_info_icon.svg");
        // height: 24px;
        // width: 24px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/*.path-node-208 {

    .play-btn {

        &:after {
        animation: playbtnpulse 1.5s ease-out infinite;
        border-radius: 50%;
        content: "";
        display: inline-block;
        left: 0;
        position: absolute;
        top: 0;
     transform: translate(-50%, -50%);
        transform-origin: center;
        margin: auto;
        bottom: 0;
        right: 0;
    }

}*/

// New Section

.social-griffin {
  a {
    width: 155px;
  }

  @media (max-width: $lg) {
    flex-direction: column;

    a {
      margin-right: 0 !important;
      margin-bottom: 30px;
    }
  }
}

.about-griffin {
  padding: $section-padding 0;
  background: $gray-bkg;

  h2 {
    margin-bottom: $spacing-md;
  }

  p {
    margin-bottom: $spacing-sm;
  }

  ul {
    li {
      margin-bottom: $spacing-sm;
    }
  }

  .griffin-card {
    float: right;
    width: 50%;
    height: 500px;
    margin: 0 0 $spacing-sm $spacing-lg;
    box-shadow: $box-shadow;
    //padding: $spacing-lg;
    background: $red;

    @media (max-width: $lg) {
      width: 100%;
      min-width: 100%;
      margin: 0 0 $spacing-lg;
    }

    .griffin {
      height: 100%;
      width: 100%;
      background-image: url("../img/Company_-_Griffin.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.about-logo {
  padding: $section-padding 0;
  background-color: $gray-bkg;

  h2 {
    margin-bottom: $spacing-md;
  }

  p {
    margin-bottom: $spacing-sm;
  }

  .logo-card {
    float: left;
    width: 50%;
    height: 500px;
    margin: 0 $spacing-lg $spacing-sm 0;
    box-shadow: $box-shadow;
    padding: $spacing-xl;
    background: $white;

    @media (max-width: $lg) {
      width: 100%;
      min-width: 100%;
      margin: 0 0 $spacing-lg;
    }

    .logos {
      height: 100%;
      width: 100%;
      background-image: url("../img/logo-evolution.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.path-node-207 {
  @media (max-width: $sm) {
    #hero {
      .hero-content {
        .btn {
          margin: 0;
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
  @media (max-width: 375px) {
    #hero {
      .hero-content {
        .btn {
          margin-left: 30px;
        }
      }
    }
  }

  #title-and-body {
    .container {
      @media (max-width: 375px) {
        .btn {
          margin: 0;
          padding-left: 30px;
          padding-right: 30px;
          margin-left: -20px;
        }
      }
    }
  }
}

html[lang="fr"],
html[lang="de"] {
  .path-frontpage {
    .stat-box {
      h2 {
        font-size: 60px;
      }
    }
  }
}

.path-frontpage h2 {
  font-size: 30px;
}

#customers,
#slanted-card-deck {
  flex: 1 0 auto;

  .container {
    flex: 1 0 auto;
    //min-height: 700px;
    display: flex;
    flex-direction: column;

    .slant-card-row {
      //min-height: 479px;
      flex: 1 0 auto;
    }

    .slant-card-content {
      div.small {
        //flex: 1 0 auto;
      }
    }

    div.slant-card-content.top-hat {
      flex: 1 0 auto;
    }
  }
}

.slant-card {
  flex: 1;
}

.col-slant-card {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.slant-card.customer-card {
  flex: 1;
}

.slant-card.deck-card {
  //flex: 1 0 auto;
  // Remove from: slanted-card-deck container, slant-card-row
}

.view-id-events {
  width: 100%;
}

.slant-card-link-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.griffin-box-Array.griffin-box-Array {
  //max-width: 1260px;
  margin: auto;
  //width: 93.75%;

  .row {
    max-width: 1260px;
    margin: auto;
  }

  .container {
    padding: 0;
  }
}

.iefix {
  display: flex;
  flex-direction: row;
  flex-basis: 33.33333%;

  @media (min-width: 1025px) {
    flex-basis: 33.33333%;
  }

  @media (max-width: 1024px) {
    flex-basis: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $md - 1px) {
    flex-basis: auto;
  }
}

.col-slant-card {
  max-width: 100%;
  width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  // IE 10 & 11 Slant Card Styles

  .view-analyst-recognition .views-row .ar-card {
    flex: 1 0 auto;
  }

  .col-slant-card,
  .slant-card,
  .slant-card.customer-card {
    //min-height: 100vh;
    flex: auto;
  }

  .slant-card.deck-card {
    //min-height:100vh;
  }
}

.stat-box {
  .field--item.col-md-4 {
    @media (max-width: $md) {
      width: 100%;
      max-width: none;
      flex: 0 0 100%;
    }
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  a.st-ui-close-button {
    right: 37px !important;
  }
}

// ==================================== ==================================== ==================================== *
//                    Title and Body
// ==================================== ==================================== ==================================== *

#title-and-body {
  padding: 50px 0 0px;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;

  @media (max-width: $lg) {
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }

  h2 {
    //width: 93.75%;
    //max-width: 1260px;
    margin: auto;
    margin-bottom: 3rem;
  }

  p {
    //width: 93.75%;
    //max-width: 1260px;
    margin: auto;
  }

  ul {
    width: 100%;
    max-width: $xl;
    margin: auto;
  }
}

.path-node-130 {
  .flex-card {
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;

    &:hover {
      border: 1px solid $aqua;
    }
  }
}

.path-node-234 {
  #title-and-body {
    padding-top: 0;
  }

  .flex-card {
    .card-title {
      margin-bottom: 2rem;
    }
  }
}

.path-node-167 {
  .card-img-top {
    height: 178px;
  }
}
.path-node-167,
.path-node-237 {
  .flex-card.blog-card {
    display: flex;
    flex-direction: column;

    .card-footer.blog-card-footer {
      margin-top: auto;
    }
  }
}
.path-node-201 {
  .resource-grid {
    left: calc(-1 * (100vw - 100%) / 2);
    position: relative;
    width: 100vw;
    background: #f8f8f8;

    .container-fluid {
      max-width: 1440px;
    }
  }

  .flex-card.blog-card {
    display: flex;
    flex-direction: column;

    &:first-child {
      .card-body {
        background-image: url("https://www.axway.com/sites/default/files/default_images/new-ribbon.png");
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-position-x: right;
      }
    }

    .card-footer.blog-card-footer {
      margin-top: auto;
    }
  }

  #slanted-card-deck {
    left: calc(-1 * (100vw - 100%) / 2);
    position: relative;
    width: 100vw;
    padding-top: 20px;

    .btn {
      background-color: transparent;
      color: #e82c2a;
      box-shadow: none;
    }

    .container {
      max-width: 1260px;
      width: 93.75%;
      padding-left: 0;
      padding-right: 0;

      .slant-card-bkg {
        background-color: transparent;
        background-size: cover;
      }

      .slant-card-content.top-hat {
        //margin: 60px 30px 20px;
      }
    }
  }
}

.flex-card-container.thirds {
  padding-top: 50px;
  padding-bottom: 50px;

  .container {
    flex-wrap: wrap;
    justify-content: space-around;

    .flex-card.blog-card {
      //width: 93.75%;
      width: 360px;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      flex: 1 1 360px;
      max-width: 360px;
    }
  }
}

.path-node-213,
.path-node-161 {
  .hero-content {
    max-width: 560px;
    margin-right: auto;
  }
}

.path-node-214 {
  .download-collateral {
    padding-top: 30px;

    .container {
      max-width: 1260px;
      width: 93.75%;
      margin: auto;

      .collateral-body {
        display: flex;
        justify-content: center;
        flex-direction: column;

        a {
          margin-top: 30px;
        }
      }
    }
  }

  #title-and-body {
    display: flex;
    flex-direction: column;

    h2 {
      margin-bottom: 2rem;
    }
  }

  .flex-card-container.thirds {
    padding-top: 10px;

    .flex-card.blog-card {
      .card-text.blog-card-text {
        h1 {
          color: rgba(0, 137, 153, 0.65);
          font-size: 80px;
        }
      }

      &:last-child {
        background: linear-gradient(163deg, #008999 0%, #2c3a44 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          margin-top: 30px;
        }

        .card-body.blog-card-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .card-text.blog-card-text {
          color: $white;
        }

        .card-footer.blog-card-footer {
          background: transparent;
        }
      }
    }
  }

  .title-and-body {
    display: flex;
    flex-direction: column;
  }
}

.download-collateral {
  .container {
    max-width: 1260px;
    margin: auto;
    width: 93.75%;
  }
}

.path-node-206 {
  .download-collateral {
    padding-bottom: 47px;
    .row {
      position: relative;

      .collateral-thumb-wrapper {
        position: absolute;
        right: 0;
        top: -115px;

        @media (max-width: 1024px) {
          position: relative;
          top: auto;
        }

        img.collateral-thumb {
          width: 100%;
          max-width: 350px;

          @media (max-width: $lg - 1px) {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .collateral-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.full_width_second.container-fluid {
  .container {
    width: 93.75%;
    max-width: 1260px;
    margin: auto;
  }
}

.path-node-161 {
  #tp-grid {
    padding-top: 0;
  }
}

.path-node-237 {
  .griffin-box-aqua.griffin-box-xl {
    .griffin {
      display: none;
    }
  }
}

.path-node-152 {
  .field--name-body {
    margin-bottom: 0;
  }

  #title-and-body {
    margin-top: 0px;
    padding-top: 40px;
  }
}

.path-node-177 {
  .card-text.blog-card-text {
    font-size: 16px;
  }
}

.path-node-177 {
  #title-and-body {
    display: flex;
    flex-direction: column;
  }
}

.path-node-207 {
  .watch-video {
    display: none;
  }

  .small {
    margin-bottom: 30px;
  }
}

.path-node-162 {
  .hero-md {
    .play-btn {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2265%22%20height%3D%2265%22%3E%3Cpath%20d%3D%22M32.5%2065C14.55%2065%200%2050.45%200%2032.5S14.55%200%2032.5%200%2065%2014.55%2065%2032.5%2050.45%2065%2032.5%2065zm12-31l-20-12.5v25l20-12.5z%22%20fill%3D%22%23e0e1e6%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
    }
  }
}
.path-node-144 {
  #title-and-body {
    display: flex;
    flex-direction: column;
  }

  .hero-md {
    .play-btn {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2265%22%20height%3D%2265%22%3E%3Cpath%20d%3D%22M32.5%2065C14.55%2065%200%2050.45%200%2032.5S14.55%200%2032.5%200%2065%2014.55%2065%2032.5%2050.45%2065%2032.5%2065zm12-31l-20-12.5v25l20-12.5z%22%20fill%3D%22%23e0e1e6%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
    }
  }
}

.path-node-112 {
  #leadership {
    .container {
      max-width: 1240px;
      margin: auto;
    }
  }
  @media (min-width: 1025px) {
    #leadership .leadership-col {
      flex: 1 1 350px!important;
      max-width: 350px;
    }
  }
  #leadership .row {
    justify-content: center;
  }
  #leadership .leader-card .leader-headshot {
    height:300px!important;
  }
}

.path-node-238,
.path-node-183 {
  #title-and-body {
    padding-top: 0;
  }
}

.path-node-136 {
  .download-collateral {
    .container {
      max-width: 1260px;
      margin: auto;
      width: 93.75%;
    }
  }
}
.path-node-207 {
  #slanted-card-deck {
    .container {
      max-width: 1260px;
      margin: auto;
      width: 93.75%;
      padding-top: 40px;
    }
  }
}

.already-signed {
  margin-top: 15px;
  @media (max-width: $sm) {
    margin-top: 0;
  }
}

h3,
h2 {
  @media (max-width: $sm) {
    font-size: 26px !important;
    margin-bottom: 20px !important;
  }
}

#video-cta .video-text .play-btn.mobile {
  display: none;
}

.form-inline .form-group {
  flex-flow: row;
}

.event-card-featured h2 {
  @media (max-width: $sm) {
    top: 100px !important;
  }
}

.logo-card {
  width: 47% !important;

  @media (max-width: $sm) {
    padding: 30px 0 !important;
    height: 250px !important;
  }
}

.about-logo {
  @media (max-width: $sm) {
    padding: 0 !important;
  }
}

.griffin-card {
  width: 47% !important;

  @media (max-width: $md) {
    height: 200px !important;
  }
}

.path-node-208 {
  .field--name-body {
    margin-top: 30px !important;
  }
}

.partner-marketplace-form {
  @media (max-width: $md) {
    margin-right: auto;
    margin-left: auto;
  }
}

.eye {
  background: url(../img/BuildingITFuture_400.jpg);
}

#title-and-body {
  @media (max-width: $sm) {
    h2,
    p {
      text-align: left !important;
    }
  }
}

.stat-box {
  .field--item.col-md-4 {
    @media (min-width: $md + 1px) {
      margin-bottom: 30px;
    }
  }
}

.pager-nav {
  .pager__item {
    a {
      color: #2c3a44;
      margin-left: 3px;
      margin-right: 3px;
      font-weight: 600;
    }
  }
}

.col-md-12 {
  #event {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    width: 93.75%;
  }
}

#block-views-block-events-webinars-block {
  .view-filters.form-group {
    @media (max-width: $sm) {
      margin-left: 15px;
    }
  }
}

.board-leader-nomination {
  display: none;
}

.path-node-207 {
  #hero {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      padding-top: 155px;
    }
  }
}

#teaser {
  .text-left {
    flex: 1 0 auto;
  }
}

// ==================================== ==================================== ==================================== *
//                                            $_Bootstrap_Overrides
// ==================================== ==================================== ==================================== *
//increase the container size at the breakpoints
.container {
  @media (min-width: $xxl) {
    //$xxl: 1440px;
    max-width: $xxl;
  }
  @media (max-width: $xxl -1px) {
    //$xxl: 1440px;
    max-width: $xxl;
    //width: 93.75%;
  }

  @media (min-width: $xl) {
    //$xl: 1200px;
    //max-width: $xl;
    //width: 93.75%;
  }

  @media (min-width: $lg) {
    //$lg: 992px;
    //max-width: $lg;
  }

  @media (min-width: $md) {
    //$md: 768px;
    //max-width: $md;
    max-width: 1440px;
  }
}

// ==================================== ==================================== ==================================== *
//                                            $_Cross_Browser_Fixes
// ==================================== ==================================== ==================================== *
// fix for columns breaking onto next line in Safari (Current & Current-1 (11.1 & 10.1))
.row:before {
  display: inline-block;
}

// ==================================== ==================================== ==================================== *
//                                            $_HELPER_CLASSES
// ==================================== ==================================== ==================================== *

// ********** layout **********
.flex-spacer {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

.center-child {
  // centers a single child element with flexbox
  @include center-child;
}

.v-align-flex {
  // vertical aligns a single element
  display: flex;
  flex-direction: row;
  align-items: center;
}

// ********** padding and spacing **********
.no-padding {
  padding: 0;
}

.section-padded {
  padding: $spacing-xl 0;

  &-lg {
    padding: $spacing-lg 0;
  }

  &-md {
    padding: $spacing-md 0;
  }
}
.section-padded-top {
  padding-top: $spacing-xl;

  &-lg {
    padding-top: $spacing-lg;
  }

  &-md {
    padding-top: $spacing-md;
  }
}

.no-margin {
  margin: 0;
}
.margin-xl {
  margin-bottom: $spacing-xl;
}
.margin-lg {
  margin-bottom: $spacing-lg;
}
.margin-md {
  margin-bottom: $spacing-md;
}
.margin-sm {
  margin-bottom: $spacing-sm;
}
.margin-xs {
  margin-bottom: $spacing-xs;
}

// ********** backgrounds **********
.bkg-gray {
  background: $gray-bkg;
}
.bkg-slate-grad {
  background: $slate-grad;
}
.bkg-aqua-grad {
  background: $aqua-grad;
}

// ********** text modifiers **********

.center {
  text-align: center;
}

// ********** other **********
.center img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.field--label-hidden {
  //display: none;
}

.field--name-body {
  margin: 84px 0;
}

// ==================================== ==================================== ==================================== *
//                                            $_intro block
// ==================================== ==================================== ==================================== *

.row.intro {
  //padding-top: 40px;
}

.intro,
.intro-tight {
  text-align: center;
  //padding-bottom: $spacing-lg;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: $lg) {
    padding-right: $spacing-lg;
    padding-left: $spacing-lg;
  }

  h2 {
    margin-bottom: $spacing-md;
  }

  hr {
    margin-bottom: $spacing-md;
    width: 65px;
    border-top: 1px solid $gray-lightest;
  }

  p {
    margin-bottom: $spacing-sm;
    padding: 0 15px;
  }

  &.two-column {
    p {
      text-align: left;
      column-count: 2;
    }
  }
}

.intro-tight {
  padding-bottom: $spacing-xs;
}

// ==================================== ==================================== ==================================== *
//                                            $_video_thumbs
// ==================================== ==================================== ==================================== *

@-webkit-keyframes playbtnpulse {
  0% {
    background-color: $white;
    width: 60px;
    height: 60px;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
    width: 90px;
    height: 90px;
  }
}
@keyframes playbtnpulse {
  0% {
    background-color: $white;
    width: 60px;
    height: 60px;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
    width: 90px;
    height: 90px;
  }
}

@-webkit-keyframes playbtnpulsered {
  0% {
    background-color: #e82c2a;
    width: 60px;
    height: 60px;
  }
  100% {
    background-color: rgba(232, 44, 42, 0.1);
    width: 90px;
    height: 90px;
  }
}
@keyframes playbtnpulsered {
  0% {
    background-color: #e82c2a;
    width: 60px;
    height: 60px;
  }
  100% {
    background-color: rgba(232, 44, 42, 0.1);
    width: 90px;
    height: 90px;
  }
}

@-webkit-keyframes playbtnpulsegrey {
  0% {
    background-color: $gray;
    width: 60px;
    height: 60px;
  }
  100% {
    background-color: rgba(232, 44, 42, 0.1);
    width: 90px;
    height: 90px;
  }
}
@keyframes playbtnpulsegrey {
  0% {
    background-color: $gray;
    width: 60px;
    height: 60px;
  }
  100% {
    background-color: rgba(95, 117, 129, 0.1);
    width: 90px;
    height: 90px;
  }
}

@-webkit-keyframes playbtnpulseaqua {
  0% {
    background-color: rgb(20, 137, 152);
    width: 60px;
    height: 60px;
  }
  100% {
    background-color: rgba(20, 137, 152, 0.1);
    width: 90px;
    height: 90px;
  }
}
@keyframes playbtnpulseaqua {
  0% {
    background-color: rgb(20, 137, 152);
    width: 60px;
    height: 60px;
  }
  100% {
    background-color: rgba(20, 137, 152, 0.1);
    width: 90px;
    height: 90px;
  }
}

.play-btn {
  display: block;
  height: $spacing-md * 2;
  width: $spacing-md * 2;
  background: url("../img/btn-play.svg") center center no-repeat;
  //background-size: cover;
  cursor: pointer;
  background-size: $spacing-md * 2;

  &:after {
    animation: playbtnpulse 1.5s ease-out infinite;
    border-radius: 50%;
    content: "";
    display: inline-block;
    left: 30px;
    position: absolute;
    top: 30px;
    transform-origin: center;
    transform: translate(-50%, -50%);
  }
}

.link-thumb {
  @extend %video-link-base;

  a {
    display: block;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.path-node-791 {
  .link-thumb {
    a {
      height: 400px;
      width: 400px;
    }
  }
}

.video-thumb {
  @extend %video-link-base;
  padding-top: 56.25%; // 16:9 Aspect Ratio

  &:hover {
    .play-btn {
      opacity: 0.9;
    }
  }

  .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.partner {
  .container {
    max-width: 1260px;
    margin: auto;
    width: 93.75%;
  }
}

// ==================================== ==================================== ==================================== *
//                    $_generic_flexbox_container_and_card
// ==================================== ==================================== ==================================== *

.flex-card-container {
  //margin-top: $spacing-xl + 4px;
  //padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  //margin-bottom: $spacing-xl + 4px;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    position: relative;
    z-index: 1;
    max-width: 1200px;
    width: 93.75%;
    padding-left: 0;
    padding-right: 0;

    @media only screen and (min-width: $xl) {
      justify-content: space-around;
    }

    @media only screen and (max-width: $md) {
      flex-direction: column;
      align-items: center;
    }
  }

  &.griffin-bg {
    &:after {
      background-image: url(../img/griffin-slate.svg);
      content: "";
      top: -20%;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 0;
      background-size: cover;
      background-position: top;
      width: 100%;
      max-width: 1136px;
      margin: auto;
      opacity: 0.2;
    }
  }
}

.flex-card {
  background: $white;
  max-width: 570px;
  width: 47.5%;
  //display: flex;
  display: inline-block;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: $spacing-lg;
  box-shadow: $box-shadow;
  color: $slate;

  @media only screen and (max-width: $md) {
    width: 93.75%;
  }

  &:nth-child(odd) {
    margin-right: auto;

    @media only screen and (max-width: $md) {
      margin-right: 0;
    }
  }

  &:hover {
    color: $slate;
    //transition: all 0.25s ease;
    //-webkit-transform: translateY(-3px);
    //transform: translateY(-3px);
  }

  .card-img-top {
    border-radius: 0;
  }

  .card-title {
    margin-bottom: $spacing-sm;
  }

  .card-body {
    padding: $spacing-md $spacing-md;
  }

  .card-footer {
    background: inherit;
    border-radius: 0;
    border-top: 0;
    padding: 0 $spacing-md $spacing-md $spacing-md;
    margin-top: auto;

    span {
      &:before {
        background-image: url("../img/uber-dash.svg");
        background-size: 31px 3px;
        content: " ";
        display: inline-block;
        height: 3px;
        margin-right: 4px;
        vertical-align: middle;
        width: 31px;
      }

      color: $red;
      font-size: 14px;
      font-weight: $font-weight-bold;
      position: relative;
      top: 3px;
      left: 4px;
    }
  }
}

#navbar-nav-wrapper {
  position: relative;

  #search-glass.nav-search-active {
    position: absolute;
    right: 340px;
    transition: 0.5s ease all;
  }

  #search-glass {
    position: absolute;
    right: 0;
    transition: 0.75s ease all;
    cursor: pointer;
  }

  #nav-search {
    position: absolute;
    width: 300px;
    right: 30px;
    opacity: 1;
    visibility: visible;
    transition: 0.5s ease all;
  }

  #nav-search.hide {
    width: 0;
    overflow: hidden;
    transition: 0.5s ease all;
    display: inline-block;
    vertical-align: middle;
    line-height: initial;
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }

  #nav-search-close.nav-search-active {
    opacity: 1;
    z-index: 20;
    display: inline-block;
  }

  #nav-search-close {
    display: none;
    color: $gray-dark-2020;
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 30px;
    font-weight: 300;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 0;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-nav.top-right-menu.hide,
  .language-switcher-language-url.navbar-nav.hide {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .navbar-nav.top-right-menu,
  .language-switcher-language-url.navbar-nav {
    opacity: 1;
    transition: all 1.5s ease;
  }

  .language-switcher-language-url {
    margin-right: 20px !important;
  }
}

.st-default-autocomplete {
  display: none !important;
}

.st-default-autocomplete.default-st-style {
  display: block !important;
}

.st-ui-embed {
  width: 100% !important;
  display: flex !important;
  position: absolute !important;
  z-index: 999999999999999999999 !important;
  max-width: 1260px !important;
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  top: 0 !important;
  display: none;
}

.st-ui-embed.default-st-style {
  width: 100% !important;
  display: flex !important;
  position: relative !important;
  z-index: 999999999999999999999 !important;
  max-width: none !important;
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  align-items: initial;
  justify-content: initial !important;
  top: 0 !important;
}

.st-default-search-input {
  border: 0 !important;
}

.st-ui-overlay {
  display: none !important;
}

.st-ui-overlay.default-st-style {
  display: block !important;
}

.st-ui-close-button {
  display: none !important;
}
.st-ui-close-button.default-st-style {
  display: block !important;
}

.st-ui-container {
  position: absolute !important;
  top: 0 !important;
  width: 0px !important;
  left: auto !important;
  height: 50px !important;
  animation: none !important;
  transition: 0s ease all !important;
  margin: 40px 0px !important;
  right: 30px !important;
  border-radius: 50px !important;
}

.st-ui-container.slide-open {
  position: absolute;
  top: 0;
  width: 310px !important;
  left: auto;
  height: 48px !important;
  animation: none;
  transition: 0.5s ease all !important;
  // margin: 40px 0px !important;
  //margin: 30px 0px !important;
  right: 36px !important;
}

.st-ui-container.slide-opened {
  position: absolute;
  top: 0;
  width: 310px !important;
  left: auto;
  height: 48px !important;
  animation: none;
  transition: none !important;
  margin: 40px 0px;
  right: 36px !important;
}

.st-ui-container.slide-close {
  position: absolute !important;
  top: 0 !important;
  width: 0px !important;
  left: auto !important;
  height: 50px !important;
  animation: none !important;
  transition: 0.5s ease all !important;
  margin: 40px 0px !important;
  right: 30px !important;
  border-radius: 50px !important;
}

.st-ui-container.default-st-style {
  position: fixed !important;
  top: 0 !important;
  left: 50% !important;
  margin: 64px 0 0 -320px !important;
  width: 640px !important;
  height: 80% !important;
  box-sizing: border-box !important;
  background: #fff !important;
  z-index: 99999992 !important;
  animation: st-ui-fade-in ease-in-out 0.5s;
  overflow: hidden !important;
  text-size-adjust: none !important;
  border-radius: 5px !important;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.st-ui-search-icon {
  display: none !important;
}
.st-ui-search-icon.default-st-style {
  display: block !important;
}

.user-logged-in.toolbar-tray-open {
  .st-ui-container,
  .st-ui-container.slide-open,
  .st-ui-container.slide-opened,
  .st-ui-container.slide-close {
    // margin-top: 120px !important;
    // top: -172px !important;
    margin-top: -20px !important;
    right: 0px !important;
    display:block;
  }
}

.user-logged-in {
  .st-ui-container,
  .st-ui-container.slide-open,
  .st-ui-container.slide-opened,
  .st-ui-container.slide-close {
    // margin-top: 80px !important;
    margin-top: -20px !important;
  }
}

@media only screen and (max-width: $xl) {
  .st-ui-container.slide-open,
  .st-ui-container.slide-opened {
    right: 75px !important;
  }
}

@media only screen and (max-width: $xl - 1px) {
  .st-ui-container.slide-open,
  .st-ui-container.slide-opened {
    // right: 75px !important;
    // width: 270px !important;
    right: 0 !important;
    left: 0 !important;
    margin: 16px auto 0 !important;
    width: 270px !important;
  }

  #navbar-nav-wrapper #search-glass.nav-search-active {
    right: 310px !important;
  }
}

@media only screen and (max-width: $lg) {
  #search-glass,
  #search-glass.nav-search-active {
    position: relative !important;
    left: 30px !important;
    transition: none !important;
    right: auto !important;
  }

  #navbar-nav-wrapper .navbar-nav.top-right-menu.hide,
  #navbar-nav-wrapper .language-switcher-language-url.navbar-nav.hide {
    opacity: 1;
    transition: none;
  }

  #nav-search-close.nav-search-active {
    //display: none!important;
    right: 5px !important;
  }
}

@media only screen and (max-width: $sm) {
  .st-ui-container.slide-open,
  .st-ui-container.slide-opened {
    right: 30px !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .st-ui-content.st-search-results {
    display: none !important;
  }
  .st-ui-content.st-search-results.default-st-style {
    display: block !important;
  }

  #nav-search-close {
    margin-top: -25px;
    margin-right: -10px;
  }

  #search-glass {
    margin-top: -10px;
  }
}

#fooSearch {
  position: absolute;
  width: 330px;
  display: none;
  color: #3b454f;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
}

#fooSearch.active {
  position: absolute;
  width: 310px;
  right: 21px;
  height: 50px;
  top: -5px;
  z-index: 999999999;
  border-radius: 25px;
  display: block;
}

.mobile-active {
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.fixed {
  position: fixed;
  overflow: hidden;
}

// ==================================== ==================================== ==================================== *
//                    $_axway_subnav
// ==================================== ==================================== ==================================== *

.axway-subnav {
  background: $white;
  display: flex;
  background-color: #fff;
  position: relative;
  z-index: 9;
  left: calc(-1 * (100vw - 100%) / 2);
  position: relative;
  width: 100vw;

  @media only screen and (max-width: $sm) {
    display: flex;
    flex-direction: column;
  }

  .nav-link {
    color: $gray-darkest;
    font-size: 18px;
    padding: 0;

    a {
      @media only screen and (max-width: $sm) {
        display: inline-block;
      }
    }

    &:hover {
      color: $black;
    }
  }

  .active .nav-link {
    font-weight: 700;
    border-bottom: solid 2px $aqua-light;
  }

  ul.axway-subnav-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 80px;
    width: 100%;

    @media only screen and (max-width: $sm) {
      flex-direction: column;
    }

    & > li {
      list-style: none;
      padding-left: $spacing-sm;
      padding-right: $spacing-sm;
    }

    @media only screen and (max-width: $lg) {
      padding-top: $spacing-sm;

      & > li {
        padding-bottom: $spacing-sm;
      }
    }
    @media only screen and (max-width: $sm) {
      & > li {
        padding-top: $spacing-sm;

        a {
          display: inline-block;
        }

        &:nth-child(odd) {
          background: #e8e8e8;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

.card-title.blog-title {
  font-size: 26px;
}

#appcform {
  @media only screen and (max-width: $sm) {
    .form-group {
      #button-appc-submit {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
}

#media-contacts {
  @media only screen and (max-width: $sm) {
    .col-md-6 {
      img {
        display: none;
      }
    }
  }
}

.event-card-featured {
  @media only screen and (max-width: $sm) {
    .col-md-6 {
      left: 0px;
      position: relative;
      top: 23px;
      width: 280px;
      z-index: 9;
      font-size: 30px;
    }
  }
}

#title-and-body {
  .container {
    max-width: 1260px;
    width: 93.75%;
    margin: auto;

    @media only screen and (max-width: $sm) {
      padding: 0;
      width: 100%;
    }
  }
}

.comparison-table-section {
  @media only screen and (max-width: $sm) {
    padding: 0;
    width: 100%;

    th[scope="col"] {
      width: 100%;
    }
  }
}

// ==================================== ==================================== ==================================== *
//                    $_numbered_cards (for use in marketplace steps or elsewhere)
// ==================================== ==================================== ==================================== *

.c-numbered-card__container {
  margin-top: $spacing-xl - $spacing-md;
  margin-bottom: $spacing-xl;
}

.c-numbered-card {
  text-align: center;
  margin-top: $spacing-md;
}

.c-numbered-card__number {
  color: $aqua;
  font-size: 80px;
  line-height: 80px;
  margin-bottom: $spacing-md;
}

.c-numbered-card__header {
  margin-bottom: $spacing-sm;
}

.c-card {
  background: $white;
  box-shadow: $box-shadow;
  padding: $spacing-lg;
}

// ==================================== ==================================== ==================================== *
//                    $_divider
// ==================================== ==================================== ==================================== *

.o-divider {
  box-sizing: border-box;
  width: 54px;
  border: solid 1px $gray-darkest;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;

  &.o-divider--full-width {
    width: 100%;
  }

  &.o-divider--gray-lighter {
    border-color: $gray-lighter;
  }
}

// ==================================== ==================================== ==================================== *
//                    $_header_with_multiple_buttons
// ==================================== ==================================== ==================================== *

#header-multiple-buttons {
  .button-row {
    justify-content: center;
  }
}

.paragraph--type--only-one-image {
  .img-fluid {
    width: 100%;
  }
}

#block-axway-openidconnectlogin {
  display: none;
}

// ==================================== ==================================== ==================================== *
//                    $_views_infinite_scroll_button
// ==================================== ==================================== ==================================== *

.view {
  .js-pager__items {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    li {
      list-style: none outside none;
    }
  }
}

// ==================================== ==================================== ==================================== *
//                    Thank You Enhancements
// ==================================== ==================================== ==================================== *

a.thanks-btn {
  margin: $spacing-md 0;
}

#related-items {
  .row {
    h3,
    & > div {
      margin-bottom: $spacing-sm;
    }

    a {
      color: $gray-darkest;
      max-width: 200px;
    }

    p {
      &.small {
        font-weight: $font-weight-bold;
        line-height: 1.4em;
        margin-top: $spacing-sm;
        max-width: 200px;
      }
    }
  }
}

.landing.page.optimized-form {
  max-width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    max-width: 600px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}

.space-between {
  justify-content: space-between;
}

.optimized-form {
  h3 {
    color: #008999;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
  }

  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #ffffff;
  box-shadow: 0px 0px 60px rgba(128, 128, 128, 0.23137254901960785);

  form.optimizedForm {
    margin-top: 0 !important;
    max-width: 432px;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-around;

    label {
      font-size: 16px;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select,
    select#Title,
    select#Industry {
      max-width: 200px !important;
      border-radius: 0px !important;
      width: 200px !important;

      @media (max-width: 768px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    .mktoFormRow {
      //float: left;
      //clear: none;
      //flex: 1 0 50%;
      display: inline-block;

      @media (max-width: 768px) {
        width: 100%;
        display: block;
      }

      &:nth-child(even) {
        margin-right: 32px;
      }

      .mktoForm .mktoFormCol {
        margin-bottom: 0 !important;
      }
    }

    .mktoButtonRow {
      margin-left: -24px;

      @media (max-width: 768px) {
        margin-left: 0;
        width: 100%;
      }
    }

    button[type="submit"].mktoButton {
      background-color: #e82c2a !important;
      background: #e82c2a !important;
      border: 1px solid #e82c2a !important;
      width: 153px;

      &:before {
        //background-color:#E82C2A!important;
        //background:#E82C2A!important;
        border: 1px solid #e82c2a !important;
        border-radius: 20px;
      }
      &:after {
        //background-color:#E82C2A!important;
        //background:#E82C2A!important;
        border: 1px solid #e82c2a !important;
        border-radius: 20px;
      }
    }
  }

  .formprivacy {
    font-size: 14px;
    max-width: 460px;
    margin: auto;
  }
}

// ==================================== ==================================== ==================================== *
//                    Speakers
// ==================================== ==================================== ==================================== *

// Special formatting for IS Virtual 2020 page.
.path-node-1354 {
  h4 {
    @media (max-width: $sm) {
      font-size: 22px;
    }
  }
  .card-title.blog-title {
    @media (max-width: $sm) {
      font-size: 18px;
    }
  }
  .speakers {
    .container {
      margin: auto;
      max-width: 1240px;
    }
  }
  .speaker-card,
  .speaker-card .speaker-card-item {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .speaker-card .speaker-card-item .speaker-title {
    flex: 1 1 auto;
  }
}

.speakers {
  background: $gray-bkg;
  left: calc(-1 * (100vw - 100%) / 2);
  padding: $spacing-md 0;
  position: relative;
  width: 100vw;

  .intro {
    margin: $spacing-lg 0;

    @media (max-width: $md - 1px) {
      width: 100%;
      margin-left: $spacing-md;
      margin-right: $spacing-md;
    }
  }
}

.speaker-col {
  margin-bottom: 30px;
  margin-right: 30px;
  background: #fff;
  padding: 0;
  max-width: 270px;
  max-width: 270px;
  flex: 1 1 270px;
  box-shadow: $box-shadow;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  @media (max-width: $lg - 1px) {
    max-width: 45%;
  }

  @media (max-width: 1024px) {
    margin-left: 30px;
  }

  @media (max-width: $md - 1px) {
    max-width: 100%;
  }
}

.speaker-card {
  background: $white;

  .speaker-headshot {
    height: 200px;
    background: $slate no-repeat center center;
    background-size: cover;
    width: 100%;

    @media (max-width: $md) {
      height: 300px;
    }
  }

  .speaker-card-item {
    overflow: hidden;
    padding: 30px 20px;
    background: $white;
    text-align: center;

    .speaker-name {
      display: block;
      font-size: 22px;
      margin-bottom: $spacing-sm;
    }

    .speaker-title {
      display: block;
      font-size: 16px;
      line-height: 26px;
      font-weight: 300;
    }

    .speaker-company {
      font-size: 14px;
      letter-spacing: 1.5px;
      color: $gray-lighter;
      text-transform: uppercase;
    }

    .speaker-link {
      margin-top: $spacing-md;
      .btn-red {
        margin-right: 0;
      }
    }
  }
}
// ==================================== ==================================== ==================================== *
//                    CookieBot
// ==================================== ==================================== ==================================== *

#CybotCookiebotDialogBody {
  max-width: 1260px !important;
}

@media (max-width: 768px) {

  h2#CybotCookiebotDialogBodyContentTitle {
    font-size: 16px !important;
  }

  th.CookieDeclarationTableHeader {
    font-size: 12px;
    padding:unset;
  }

  td.CookieDeclarationTableCell {
    font-size: 12px;
  }
}

// ==================================== ==================================== ==================================== *
//                    Legal Pages
// ==================================== ==================================== ==================================== *

.path-node-987 {
  #title-and-body {
    padding: 0 30px
  }
  .flex-card-container.thirds {
    padding-bottom: 0;
  }
  .flex-card-container.thirds .container .flex-card.blog-card {
    display: flex;
  }
}

.path-node-986,
.path-node-985,
.path-node-984 {
  th.row_0.col_0 {
    width: 20%;
  }
  td.col_1 a {
    color: #3694a3;
  }
  .comparison-table-section .comparison-table th:last-child,
  .comparison-table-section .comparison-table td {
    text-align: left;
  }
}

.path-node-986 {
  .comparison-table-section .comparison-table th:first-child {
    width: 250px;
  }
}


// ==================================== ==================================== ==================================== *
//                    Responsive - Global updates
// ==================================== ==================================== ==================================== *

@media only screen and (max-width: $sm)
{
  .flex-card-container .container {
    flex-direction: unset!important;
  }
  .griffin-box .btn, .griffin-box-slate .btn, .griffin-box-aqua .btn, .griffin-box-red .btn {
    margin-right:0!important;
    margin-bottom:10px
  }
}
// ==================================== ==================================== ==================================== *
//                    Progress bar
// ==================================== ==================================== ==================================== *

.container-pb {
  /* margin: 100px auto;*/
  width: 350px;
  text-align: center;
  vertical-align: center;
}

.progress {
  padding: 6px;
  /*background: rgba(0, 0, 0, 0.25);*/
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  height: 3rem;
}

.progress-bar {
  height: 18px;
  background-color: #ee303c;
  border-radius: 0px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-striped .progress-bar {
  background-color: #FCBC51;
  width: 100%;
  background-image: linear-gradient(163deg,#008999 0%,#2C3A44 100%);
  animation: progressAnimationStrike 5s;
  animation-iteration-count: 20;
}

@keyframes progressAnimationStrike {
  from { width: 0 }
  to   { width: 100% }
}

// ==================================== ==================================== ==================================== *
//                    Customers
// ==================================== ==================================== ==================================== *


.col-md-4.center-child.customerlogo {
  align-items: normal;
}
img.customerlogo {
  background-color: #FFFFFF;
  width: 90%;
  vertical-align: top;
}

.play-btn.pdf {
  background: url("../img/btn-down.png") center center no-repeat;
  background-size: $spacing-md * 2;
}

.play-btn.Red.pdf {
  background: url("../img/btn-down-red.png") center center no-repeat !important;
  background-size: $spacing-md * 2 !important;
}

.play-btn.Aqua.pdf {
  background: url("../img/btn-down-aqua.png") center center no-repeat !important;
  background-size: $spacing-md * 2 !important;
}

.play-btn.Grey.pdf {
  background: url("../img/btn-down-grey.png") center center no-repeat !important;
  background-size: $spacing-md * 2 !important;
}
.customer-power-statement {
  color: #818181;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: $font-weight-normal;
  margin-bottom: 3px;
  min-height: 70px;
}
.view-customers .views-row {
  @media (max-width: $md) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.page-node-type-customer {

  .griffin-box-aqua .stat-box h2 {
    font-size: 40px !important;
  }

  .griffin-box-slate .griffin {
    display: none;
  }

  .flex-card.blog-card {
    -ms-flex-direction: column;
    -ms-flex-pack: center;
    background: linear-gradient(163deg, #008999 0%, #2c3a44 100%);
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .blog-card-text h2 {
    font-size: 30px !important;
    color: #FFFFFF !important;
    text-align: center !important
  }

  .blog-card-text p {
    color: #FFFFFF !important;
    text-align: center !important
  }

  .flex-card-container {
    padding-top: 0 !important;
  }
  .center-child img {
    width: 240px!important;
  }
  @media (max-width: $md) {
    #hero .container .row {
      flex-direction: column-reverse!important;
    }
    .video-thumb {
      min-height: 200px!important;
    }
  }
  @media (max-width: $md - 1px) {
    .griffin-box-aqua .container .row {
      flex-direction: column-reverse;

      .col-md-4.center-child img {
        margin-top:0;
        margin-bottom: 30px;

      }
    }
  }

}

// ==================================== ==================================== ==================================== *
//                    Customers - Random choice for the homepage
// ==================================== ==================================== ==================================== *

#block-views-block-customers-random-customers {

  background: white;
  padding: 40px 80px 70px 80px;

  @media (min-width: 768px) {
    .container {
      max-width: 1440px;
    }
  }
  h2.customers-title {
    font-family: Roboto, Arial, Helvetica, sans-serif!important;
    font-style: normal;
    font-weight: 300;
    font-size: 42px;
    line-height: 80px;
    text-align: center;
    color: $gray-dark-2020;
  }
  a.btn.btn-red {
    margin-top: 50px;
    margin-right: 0px;
  }
  .intro p
  {
    font-family: Roboto, Arial, Helvetica, sans-serif!important;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    //color: #4A4F54;
    color: $gray-dark-2020;
    padding-bottom: 45px;
  }
  .col-slant-card {
    max-width: 100%;
    width: 100%;
  }

  .slant-card {
    box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    border-bottom: none!important;
    .slant-card-content {
      .slant-card-title {
        border-left: none;
        margin-bottom: 0!important;
        font-family:  Roboto, Arial, Helvetica, sans-serif!important;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 14px;
        color: $gray-dark-2020;
        padding-left: 0;
        padding-bottom: 20px;
      }
      .small p {
        font-family: Roboto, Arial, Helvetica, sans-serif!important;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        text-align: left;
        color: #4A4F54;
        opacity: 0.6;
      }
    }
  }

  .customer-card {
    .watch-customer-video {

      font-family: Roboto, Arial, Helvetica, sans-serif!important;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #D22630;
      &:after {
        content: "\203A";
        display: inline-block;
        margin-left: 4px;
        vertical-align: top;
        font-size: 18px;
        font-family: Roboto, Arial, Helvetica, sans-serif!important;
      }

      img {
        display: none!important;
      }
    }
  }

  .slant-card .slant-card-content.top-hat {
    clip-path: none;
    margin: 0;
    padding-top: 20px;
  }

  .video-thumb {
    min-height: 230px;
    padding-top: 0;
  }

  .views-row {
    -ms-flex-direction: row;
    -ms-flex-preferred-size: 33.33333%;
    display: -ms-flexbox;
    display: flex;
    flex-basis: 33.33333%;
    flex-direction: row;
  }

  @media (max-width: 768px) {
    padding: 40px 0 40px !important;
    .views-row {
      flex-basis: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 320px) {
    .video-thumb {
      min-height: 145px;
    }
  }
  @media (min-width: 1025px) {
    .views-row {
      -ms-flex-preferred-size: 33.33333%;
      flex-basis: 33.33333%;
    }
  }
}

// ==================================== ==================================== ==================================== *
//                    Find a partner - Add level partner logo
// ==================================== ==================================== ==================================== *


.view-find-a-partner {
  .views-row .partner-card div {
    width: unset;
  }
  .container_partner {
    display: flex;
    width: 90%!important;
  }
  .partner-left {
    width: 100%!important;
  }

  .partner-right img {
    width: 100px;
  }
  .partner-title {
    padding-top: 2px;
  }

  @media (max-width: 576px) {
    .container_partner {
      flex-direction: column;
    }
  }
}

// ==================================== ==================================== ==================================== *
//                    Customer Stories  - Featured customers
// ==================================== ==================================== ==================================== *

.path-node-1571 {
  .main .container {
    max-width: 1260px;
    width: 93.75%;
  }

  @media (min-width: 1024px) {
    #resource-grid-v2 .flex-card-container .flex-card {
      width: 33.33% !important;
      background: center;
    }
    #resource-grid-v2 .flex-card-container .flex-card.blog-card .card-body .blog-title {
      font-size: 17px !important;
    }
    .flex-card.blog-card .card-body {
      min-height: 225px !important;
    }
  }

  #resource-grid-v2 .flex-card-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  #resource-grid-v2 .promotion-link {
    padding-top: 10px;
    align-items: center;
    color: #DB110F;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    font-family: Roboto, Arial, Helvetica, sans-serif!important;
  }

  #resource-grid-v2 .promotion-link:before {
    display: none;
  }

  #resource-grid-v2 .promotion-link:after {
    content: "\203A";
    display: inline-block;
    margin-left: 4px;
    vertical-align: middle;
    font-size: 18px;
    font-family: Roboto, Arial, Helvetica, sans-serif!important;
  }

  #resource-grid-v2 .small.promotion-subtitle {
    color: black;
  }

  #resource-grid-v2 .flex-card-container .flex-card.blog-card .card-body {
    background-color: rgba(255, 255, 255, 0.92);
  }
}

  #block-views-block-customers-featured-customers {
    background: #FFFFFF;
    padding: 60px 0 0px 0!important;
    min-height: 470px;

    h2.block-title {
      font-size: 32px;
      padding-left: 15px;
      padding-bottom: 30px;
    }

    .col-md-12.center {
      padding: 40px;
    }
    .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      max-width: 1440px;
      width: 93.75%;

      // .row {
      //   align-items: start;
      //   display: flex;
      //   flex-direction: row;
      // }
    }

    .promotion {
      display: flex;
      flex-direction: column;
      // flex: 1 1 25%;
      flex: 1 1 auto;
      padding-left: 0px;
      padding-right: 40px;
      @media (max-width: $sm) {
        padding-right: 0px!important;
      }
      // Special case where we only have 1 promotion.

      .promotion-card {
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
      }
      &.col-md-12 {
        .promotion-title {
          font-size: 24px;
        }

        .promotion-subtitle {
          font-size: 18px;
        }

        .promotion-link {
          font-size: 18px;
        }
      }

      // Special case for 2 promotions.
      &.col-md-6 {
        .promotion-image {
          max-width: 500px;
          height: 280px;
        }
      }

      @media (max-width: $md) {
        margin-bottom: $spacing-sm + 7px;
      }

      &:last-child {
        @media (max-width: $md) {
          margin-bottom: 0;
        }
      }
    }

    .promotion-body {
      flex: 1 1 auto;
      padding: 0px;
    }
    .promotion-content {
      background: #FFFFFF;
      display: -ms-flexbox;
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      padding: 25px 20px 20px;
      position: relative;
    }
    .promotion-image {
      margin-bottom: 0px!important;
    }

    .promotion-title {
      font-family: Roboto, Arial, Helvetica, sans-serif!important;
      margin-bottom: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;

      a {
        color: $slate;
      }
    }


    p.promotion-info  {
      font-size: 14px!important;

    }
    .promotion-subtitle {
      color: #919191;
      margin-bottom: 15px;
      p {

        margin-bottom: 15px;
        font-family: Roboto, Arial, Helvetica, sans-serif!important;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        color: #4A4F54;
        opacity: 0.6;
      }
    }

    .promotion-link {
      align-items: center;
      color: $red-dark;
      display: flex;
      flex-direction: row;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      font-family: Roboto, Arial, Helvetica, sans-serif!important;

      &:before {
        display: none;
      }
      &:after {
        content: "\203A";
        display: inline-block;
        margin-left: 4px;
        vertical-align: middle;
        font-size: 18px;
        font-family: Roboto, Arial, Helvetica, sans-serif!important;
      }

    }
    .promo-footer {
      padding:0;
    }
  }

