// PARAGRAPH 2021
.para {
  h2 {
    color: $dark-gray-2024;
    font-family: $font-global;
    font-size: 30px !important;
    font-style: normal;
    font-weight: $font-weight-large;
    line-height: 1.35em;
  }
}
////////////////////////////////////////
// PARAGRAPH 2023
////////////////////////////////////////

.bkgnd_tan {
  background-color: #F4EDE4;
}
.bkgnd_gray {
  background-color: #F3F3F3;
}
.bkgnd_lightgray {
  background-color: #F8F8F8;
}
.bkgnd_white {
  background-color: #FFFFFF;
}
.pg_bottom {
  padding-bottom: 50px;
}

.customer-promotion-2023 {
  left: calc(-1 * (100vw - 100%) / 2);
  position: relative;
  width: 100vw;
  .container {
      padding-top: 50px;
      max-width: 1440px;
      width: 100%;
    .col-lg-6.text-card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-height: 440px;
      padding-top: 30px;
    }
    .customer-logo {
      height: 80px;
      margin-bottom: 30px!important;
    }
    h2 {
        color: $dark-gray-2024;
        font-family: "Roboto", Arial, Helvetica, sans-serif;
        font-size: 30px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 1.35em;
        width: 67%;
        margin-bottom: 1.5rem;
      }
    .customer-quote p, .customer-author {
      font-family: Roboto;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      text-align: left;
      color: $dark-gray-2024;
      padding-bottom: 5px;
      max-width: 585px;
    }
    .promo-footer {
      padding-top: 40px;
    }
    .customer-video {
      display: flex;
      justify-items: flex-end;
      justify-content: end;
      z-index: 1;
      overflow: hidden;
      padding: 0;
      .bg-video-wrap-customer {
        height: 370px!important;
        background: rgba(0, 0, 0, 0.5);
        margin-top: 30px;
        margin-right: 40px;
        box-shadow: 0px 0px 31px 3px #bab9b8;
        /*&:after {
          content: '';
          position: absolute;
          background: rgba(0, 0, 0, 0.5);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }*/
          video {
            height: 370px!important;
            width: 580px!important;
            left: unset!important;
            position: relative;
            z-index: -1;
            object-fit: cover;
          }
        .play-btn.Red {
          left: 55% !important;
          top: 50% !important;
          background: url(https://www.axway.com/themes/custom/axway2020/img/btn-video-play-2021.svg) center center no-repeat !important;
          background-size: cover !important;
          margin-right: -50%;
          position: absolute;
          transform: translate(-50%, -50%);
          &:after {
            display: none!important;
          }
        }
        }
      }
    }
  }


