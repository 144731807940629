// ***************     SETUP      ***************
@import "variables";
@import "mixins";
@import "promotions/gartner2023.scss";
@import "sections/sitewide-alerts";
// Brand 2021 Variables
// ============================================================================================================

$teal-2021: #016580;
$dark-red-2021: #b1001a;
$light-red-2021: #ff0000;
$dark-grey-2021: #4a4f54;
$medium-grey-2021: #b4b6b8;
$soft-grey-2021: #ebecec;
$light-grey-2021: #f3f3f3;
$violet-2021: #7e71ff;
$light-violet-2021: #c9c4ff;
$light-beige: #f3ece3;

// Trello - Revert default text from pure black to dark grey
$dark-gray-2024: #22272B;

// used for all the container of the new paragraphs
$padding-top-2021: 100px;
$padding-bottom-2021: 0px;

$xl2: 1260px;

// FONTS
$font-global: "Roboto", Arial, Helvetica,
  sans-serif;
$font-weight-regular: 300;
$font-weight-medium: 400;
$font-weight-large: 500;
$font-weight-bold: 700;
$font-size-12: 12px;

// ============================================================================================================ *
//                                            CONTAINERS
// ============================================================================================================ *
.redcolor {
  color: $dark-red-2021;
  &:hover {
    color: $light-red-2021;
  }
}

#navigation .container,
#hero .container,
#footer .container,
#teaser .container {
  max-width: $xxl;
}

.main .hero-wrapper {
  display: none;
}

// ============================================================================================================ *
//                                            MENUS
// ============================================================================================================ *
#navigation {
  padding: 13px 0 0;
  box-shadow: unset;
  border-bottom: 1px solid #f3f3f3;
  &.scroll {
    .dropdown-menu {
      margin-top: 0px !important;
    }
  }
  &.clear {
    .dropdown-menu {
      margin-top: 0px !important;
    }
  }
  .navbar-brand img {
    height: 40px;
    margin: -5px 0 0 0;
  }
}

#navigation #navbar-nav-wrapper {
  //mouseover
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  @media (max-width: $xl) {
    .language-switcher-language-url > li > .dropdown-toggle {
      padding: 5px 10px;
    }
  }

  //Hide the dropdown for the some menus but keep them on the Footer
  /*.our-customers,
  .nos-clients,
  .clientes,
  .kunden {
    .dropdown-menu {
      display: none !important;
    }
  }*/
  li.dropdown.legal-contracts,
  li.dropdown.documents-légaux,
  li.dropdown.sitemap,
  li.dropdown.plan-du-site {
    display: none !important;
  }

  .navbar-nav > li > .dropdown-toggle {
    font-size: 16px;
    font-weight: $font-weight-large;
  }
  .top-right-menu > li > .dropdown-toggle,
  .language-switcher-language-url > li > .dropdown-toggle {
    font-size: 14px;
    font-weight: $font-weight-large;
  }
  .language-switcher-language-url > li .caret-down:after,
  .top-right-menu > li .caret-down:after {
    font-size: 10px;
  }

  ul.navbar-nav.main {
    a.dropdown-toggle {
      //width: 151px;
      //text-align: center;
      padding: 0 15px;
      /* &:hover {
         span.caret {
           display: block !important;
         }
       }*/
      @media (max-width: $xl) {
        padding: 0 10px;
      }
    }
    li.active a.dropdown-toggle {
      font-weight: $font-weight-large !important;
      /* Active square for top menus
      &:before {
        content: " ";
        position: absolute;
        top: 56px;
        left: 50px;
        height: 10px;
        width: 10px;
        background: red;
      }*/
    }
    li ul {
      li {
        a:hover {
          position: relative;
          background: unset !important;

          &:before {
            content: " ";
            position: absolute;
            top: 12px;
            left: -8px;
            height: 10px;
            width: 10px;
            background: red;
          }
        }
      }
    }

    li.active ul {
      li {
        a:hover {
          position: relative;
          background: unset !important;

          &:before {
            content: " ";
            position: absolute;
            top: 12px;
            left: -8px;
            height: 10px;
            width: 10px;
            background: red;
          }
        }
      }

      li.active {
        a {
          position: relative;
        }

        a.is-active {
          font-weight: $font-weight-regular;
          color: black !important;
          background: unset !important;

          &:before {
            content: " ";
            position: absolute;
            top: 12px;
            left: -8px;
            height: 10px;
            width: 10px;
            background: red;
          }
        }
      }
    }
    .dropdown-menu {
      border: none;

      .dropdown-nav {
        width: 516px;
        box-shadow: 0px 10000px 0 10000px rgba(0, 0, 0, 0.15);
        .dropdown-nav-column li {
          font-weight: $font-weight-medium;
        }
      }
    }
  }
  // TOP RIGHT MENU
  ul.navbar-nav.top-right-menu {
    a.dropdown-toggle {
      //width: 75px;
      padding: 0 15px;
      text-align: center;
      /*&:hover {
        span.caret {
          display: block !important;
        }
      }*/
      @media (max-width: $xl) {
        padding: 0 10px;
      }
    }
    /*span.caret {
      background-color: $light-grey-2021;
      position: absolute;
      height: 20px;
      width: 95px;
      display: none;
    }*/
    li ul {
      li {
        a:hover {
          position: relative;
          background: unset !important;

          &:before {
            content: " ";
            position: absolute;
            top: 12px;
            left: -8px;
            height: 10px;
            width: 10px;
            background: red;
          }
        }
      }
    }

    li.active ul {
      li {
        a:hover {
          position: relative;
          background: unset !important;

          &:before {
            content: " ";
            position: absolute;
            top: 12px;
            left: -8px;
            height: 10px;
            width: 10px;
            background: red;
          }
        }
      }

      li.active {
        a {
          position: relative;
        }

        a.is-active {
          font-weight: $font-weight-regular;
          color: black !important;
          background: unset !important;

          &:before {
            content: " ";
            position: absolute;
            top: 12px;
            left: -8px;
            height: 10px;
            width: 10px;
            background: red;
          }
        }
      }
    }
    .dropdown-menu {
      border: none;
      &:before {
        //background-image: url(/themes/custom/axway2020/img/arrrow-2021.png);
        content: "";
        display: inline-block;
        height: 21px;
        /*margin-left: 20px;*/
        vertical-align: middle;
        width: 85px;
        background-color: $light-grey-2021;
      }

      .dropdown-nav {
        width: 420px;
        box-shadow: 0px 10000px 0 10000px rgba(0, 0, 0, 0.15);
      }
    }
  
    li.about-us .dropdown-menu .dropdown-nav,
    li.sobre-nós .dropdown-menu .dropdown-nav,
    li.über-uns .dropdown-menu .dropdown-nav,
    li.a-propos .dropdown-menu .dropdown-nav,
    li.support .dropdown-menu .dropdown-nav,
    li.suporte .dropdown-menu .dropdown-nav,
    li.partners .dropdown-menu .dropdown-nav,
    li.parceiros .dropdown-menu .dropdown-nav,
    li.partenaires .dropdown-menu .dropdown-nav,
    li.partner .dropdown-menu .dropdown-nav,
    li.login .dropdown-menu .dropdown-nav,
    li.à-propos-de-nous .dropdown-menu .dropdown-nav,
    li.a-propos-de-nous .dropdown-menu .dropdown-nav {
      //column-count: 2;

      .dropdown-nav-column {
        flex-direction: column;
        //min-height: 400px;
      }
    }
  }

  // Language Switcher
  // #block-axway2020-languageswitchercontent {
  //   .dropdown-menu {
  //     border: none;

  //     &:before {
  //       content: "";
  //       display: inline-block;
  //       height: 21px;
  //       vertical-align: middle;
  //       width: 85px;
  //       background-color: $light-grey-2021;
  //     }

  //   }
  // }

  .dropdown-menu .dropdown-nav {
    background-color: $light-grey-2021;
  }
  li.products .dropdown-menu {
    /*left: 15px;*/
  }
  li.support .dropdown-menu,
  li.suporte .dropdown-menu {
    left: -86px;
    &:before {
      margin-left: 85px;
    }
  }
  li.resources .dropdown-menu,
  li.recursos .dropdown-menu,
  li.ressources .dropdown-menu,
  li.ressourcen .dropdown-menu {
    left: -165px;
    &:before {
      margin-left: 170px;
    }
  }
  li.partners .dropdown-menu,
  li.partenaires .dropdown-menu,
  li.partner .dropdown-menu {
    left: -260px;
    &:before {
      margin-left: 260px;
    }
  }
  li.login {
    @media (min-width: $lg) {
      margin-left: 0px;
    }
    .dropdown-nav {
      width: 140px !important;
    }
    .dropdown-menu {
      &:before {
        margin-left: 0px;
        width: 65px !important;
      }
    }
  }
  li.solutions .dropdown-menu,
  li.industries .dropdown-menu,
  li.setores .dropdown-menu,
  li.lösungen .dropdown-menu {
    left: -115px;
  }
  li.our-customers .dropdown-menu,
  li.nos-clients .dropdown-menu,
  li.clientes .dropdown-menu,
  li.kunden .dropdown-menu {
    left: -238px;
  }
  li.experts .dropdown-menu,
  li.especialistas .dropdown-menu,
  li.experen .dropdown-menu {
    left: -395px;
  }

  li.products .dropdown-menu .dropdown-nav,
  li.produits .dropdown-menu .dropdown-nav,
  li.produtos .dropdown-menu .dropdown-nav,
  li.produkte .dropdown-menu .dropdown-nav {
    //column-count: 2;

    .dropdown-nav-column {
      flex-direction: column;
      //min-height: 400px;
    }
    li.expanded.specialized-products.dropdown-header {
      ul {
        column-count: 2;
      }
    }
  }
  li.expanded.specialized-products.dropdown-header {
    padding-top: 30px;
  }
  li.why-axway .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.industries .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.setores .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.ressources .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.ressourcen .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.recursos .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.lösungen .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.our-customers .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.nos-clients .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.clientes .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.kunden .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.experts .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.especialistas .dropdown-menu .dropdown-nav .dropdown-nav-column,
  li.experten .dropdown-menu .dropdown-nav .dropdown-nav-column {
    flex-direction: column;
  }
  li.ressourcen .dropdown-menu .dropdown-nav .dropdown-nav-column {
    column-count: 1 !important;
  }
}
.navbar-nav.main li .dropdown-menu {
  &:before {
    //background-image: url(/themes/custom/axway2020/img/arrrow-2021.png);
    content: "";
    display: inline-block;
    height: 21px;
    /*margin-left: 20px;*/
    vertical-align: middle;
    width: 131px;
    background-color: $light-grey-2021;
  }
}
.navbar-nav.main li.industries .dropdown-menu,
.navbar-nav.main li.setores .dropdown-menu,
.navbar-nav.main li.lösungen .dropdown-menu {
  &:before {
    margin-left: 112px;
  }
}
.navbar-nav.main li.our-customers .dropdown-menu,
.navbar-nav.main li.nos-clients .dropdown-menu,
.navbar-nav.main li.clientes .dropdown-menu,
.navbar-nav.main li.kunden .dropdown-menu {
  &:before {
    margin-left: 253px;
  }
}
.navbar-nav.main li.experts .dropdown-menu,
.navbar-nav.main li.especialistas .dropdown-menu,
.navbar-nav.main li.experten .dropdown-menu {
  &:before {
    margin-left: 385px;
  }
}

#navigation
  #navbar-nav-wrapper
  .dropdown-menu
  .dropdown-nav
  .dropdown-nav-column
  li {
  font-weight: $font-weight-medium;
}

//=================
// Roboto Mono Font
//=================

///Resources SVG
// @font-face {
//   font-family: "Roboto Mono";
//   font-style: normal;
//   font-weight: 400;
//   src: url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.eot"); /* IE9 Compat Modes */
//   src: local(""),
//     url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.eot?#iefix")
//       format("embedded-opentype"),
//     /* IE6-IE8 */
//       url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.woff2")
//       format("woff2"),
//     /* Super Modern Browsers */
//       url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.woff")
//       format("woff"),
//     /* Modern Browsers */
//       url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.ttf")
//       format("truetype"),
//     /* Safari, Android, iOS */
//       url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.svg#RobotoMono")
//       format("svg"); /* Legacy iOS */
// }

// ============================================================================================================ *
//                                            BUTTONS
// ============================================================================================================ *

.font-size-12 {
  font-size: $font-size-12 !important;
}

.btn {
  padding: 0 15px;
}

.btn-red {
  background-color: $dark-red-2021;
  border: 1px solid $dark-red-2021;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  margin-right: 30px;
  font-size: 16px;
  font-weight: $font-weight-large;

  @media (max-width: $md) {
    margin-bottom: 10px;
  }
}

.btn-red:hover {
  background-color: $light-red-2021;
  border: 1px solid $light-red-2021;
}
.btn-slate {
  background-color: #4a4f54;
  border: none;
  font-weight: $font-weight-medium;

  &:hover {
    background-color: #7f8285;
    border: none;
  }
}

#contact-btn {
  background-color: $dark-red-2021;
  border: 1px solid $dark-red-2021;
  font-size: 16px;
  font-weight: $font-weight-medium;
  padding: 7px 15px !important;
  right: -35px;
  line-height: 20px !important;

  &:hover {
    background-color: $light-red-2021;
    border: 1px solid $light-red-2021;
  }

  @media (max-width: $sm - 1) {
    //right: -2px;
    right: 20px;
    background-color: #006580;
    border: 1px solid #006580;
    bottom: 90px;
  }
}

.btn-secondary {
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: $dark-gray-2024;
  background-color: transparent;
  border: 1px solid #7e71ff;
  padding: 5px 20px 10px 20px;

  &:after {
    content: "";
    background-image: url("/themes/custom/axway2020/img/purple_arrow.svg");
    display: inline-block;
    height: 15px;
    width: 21px;
    margin-left: 20px;
    vertical-align: middle;
  }
  &:hover {
    background-color: #7e71ff;
    &:after {
      background-image: url("/themes/custom/axway2020/img/arrrow-2021-white.png");
    }
  }
}

.btn-tertiary {
  font-family: "Roboto Mono";
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: $dark-gray-2024;
  text-decoration: none;
  position: relative;

  span {
    border-bottom: 1px solid $dark-gray-2024;
    padding-bottom: 10px;
    text-transform: capitalize;
  }

  &:hover {
    color: $dark-gray-2024;
    span {
      border-bottom: 1px solid $violet-2021;
      padding-bottom: 5px;
    }
    &:after {
      transform: translate(10px);
      transition: all 0.5s ease;
    }
  }
  &:after {
    content: "";
    background-image: url("/themes/custom/axway2020/img/purple_arrow.svg");
    display: inline-block;
    height: 15px;
    width: 21px;
    margin-left: 20px;
    vertical-align: middle;
  }

  &.left {
    &:before {
      background-image: url("/themes/custom/axway2020/img/purple_arrow.svg");
      content: "";
      display: inline-block;
      height: 15px;
      width: 21px;
      margin-right: 20px;
      transform: rotate(180deg);
      vertical-align: middle;
    }

    &:after {
      content: none;
    }

    &:hover {
      &:after {
        transform: none;
      }

      &:before {
        transform: rotate(180deg) translate(10px);
        transition: all 0.5s ease;
      }
    }
  }
}

.btn-tertiary-white {
  font-family: "Roboto Mono";
  font-weight: $font-weight-regular;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: white;
  text-decoration: none;
  position: relative;

  span {
    border-bottom: 1px solid white;
    padding-bottom: 5px;
    text-transform: capitalize;
  }

  &:hover {
    color: white;
    span {
      border-bottom: 1px solid $violet-2021;
      padding-bottom: 0px;
    }
  }
  &:after {
    content: "";
    background-image: url("/themes/custom/axway2020/img/arrrow-2021-white.png");
    display: inline-block;
    height: 15px;
    width: 21px;
    margin-left: 20px;
    vertical-align: middle;
  }
}
html[lang="fr"],
html[lang="de"] {
  .btn-tertiary,
  .btn-tertiary-white {
    span {
      text-transform: unset;
    }
  }
}
/*&:before {
content: '';
background-image: url('/themes/custom/axway2020/img/arrrow-2021.png');
display: inline-block;
height: 3px;
margin-right: 4px;*/
/* vertical-align: middle; */
/*width: 31px;
}*/
/*&:after {
  content: "";

  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;

  border-width: 0 0 1px;
  border-style: solid;
}
&:hover {
  &:after {
    content: "";

    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    color: $violet-2021;
    border-width: 0 0 1px;
    border-style: solid;
  }
}*/
// ============================================================================================================ *
//                                            FOOTER - TEASER
// ============================================================================================================ *

#footer {
  background: black;
  /*margin-top: 80px;*/
  padding: 0;
  background-image: url("/themes/custom/axway2020/img/footer_cube.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  min-height: 660px;
  @media (max-width: $xxl) {
    padding-left: 50px !important;
  }
  @media (min-width: 1400px) {
    .col-xl-9 {
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-xl-3 {
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
  }
  @media (max-width: 1400px) {
    background-image: none !important;
  }
  @media (max-width: $lg) {
    background-position-x: 845px;
    background-position-y: 410px;
  }
  @media (max-width: $lg) {
    min-height: 600px !important;
  }
  @media (max-width: $sm) {
    min-height: 0px !important;
  }
  @media (max-width: 574px) {
    padding-left: 50px !important;
  }
  .container {
    padding: 100px 15px 80px;
    @media (max-width: $lg) {
      padding: 20px 5px 20px !important;
    }
    .col-md-12 {
      margin: 0;
    }
    @media (max-width: $lg) {
      .col-md-12.col-xl-3 {
        padding-left: 5px;
      }
    }
  }
  .footer-logo-axway {
    display: block !important;
    width: 100%;
    @media (max-width: $sm) {
      text-align: center;
    }
  }
  img.footer-logo {
    width: 130px;
    margin: 0 0 60px 12px;
    @media (max-width: $sm) {
      margin: 0px auto;
      margin-bottom: 40px;
    }
  }

  #block-footer-menu-2021 {
    @media (max-width: $sm) {
      display: none;
    }
    ul.navbar-nav.main {
      flex-direction: row;

      li,
      li a {
        font-family: "Roboto Condensed";
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        text-align: left;
        color: white;
        text-transform: uppercase;
        list-style: none;
        /*padding-right: 25px;*/
        padding-right: 15px
      }
      li.expanded a {
        padding-bottom: 15px !important;
      }

      a.dropdown-toggle::after {
        display: none;
      }
    }

    ul.main.dropdown-nav-column {
      padding-left: 0;
      padding-top: 5px;
      li,
      li a {
        font-family: Roboto;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        text-align: left;
        color: white;
        padding-right: 0;
        text-transform: initial;
        
      }
      li.active a.is-active {
        background: unset !important;
        color: unset !important;
        position: relative;
        &:before {
          content: " ";
          position: absolute;
          top: 3px;
          left: -15px;
          height: 10px;
          width: 10px;
          background: red;
        }
      }
    }
  }
  /* RIGHT MENU */

  #block-footer-right-menu-2021 {
    column-count: 2;
    //max-width: 200px;
    max-width: 250px;
    @media (max-width: $lg) {
      column-count: unset;
      max-width: 100%;
      display: flex;
      justify-content: center;
    }

    @media (max-width: $sm) {
      display: none;
    }

    ul.navbar-nav.top-right-menu {
      flex-direction: column;
      min-height: 370px;
      @media (max-width: $lg) {
        flex-direction: row;
        min-height: 10px;
      }

      li,
      li a {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        color: white;
        padding-bottom: 15px;
        @media (max-width: $lg) {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      li.active a.is-active {
        background: unset !important;
        color: unset !important;
        position: relative;

        &:before {
          content: " ";
          position: absolute;
          top: 3px;
          left: -15px;
          height: 10px;
          width: 10px;
          background: red;
        }
      }
    }
  }
  hr.hr-top {
    display: none;
    @media (min-width: $sm+1) and (max-width: $lg) {
      display: block !important;
      border-top: 1px solid white !important;
      margin-top: 30px;
      margin-right: 50px;
    }
  }
  hr.hr-bottom {
    border-top: 1px solid #7f8285 !important;
    margin-bottom: 40px !important;
    margin-top: 40px !important;
    width: 460px !important;
    @media (max-width: $xxxl) {
      width: 290px !important;
    }
    @media (max-width: $xl) {
      width: 200px !important;
    }
    @media (max-width: $lg) {
      display: none;
    }
  }

  .col-sm-12.footer-social {
    padding: unset;
    @media (max-width: $xl) {
      display: flex;
      justify-content: center;
    }

    a.social-link {
      margin-right: 21px;
      @media (max-width: $xl) {
        margin-right: 11px !important;
      }
    }
  }
  #block-top-right-menu-2021 {
    column-count: 2;
    max-width: 200px;
  }

  .footer-awslogo {
    padding: unset;
    @media (max-width: $xl) {
      display: flex;
      justify-content: center;
    }

    .awslogo {
      max-width: 127px;
      margin-top: 40px;
    }
  }
}
#teaser {
  background: $dark-grey-2021;
  color: white;
  font-size: 11px;
  .teaser-container {
    @media (max-width: $lg) {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    @media (max-width: $sm) {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
      .col-md-3 {
        justify-content: center !important;
        padding-top: 7px;
      }
    }
    div {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
  }
  .copyright-menu {
    @media (max-width: $lg) {
      flex-direction: row !important;
    }
    @media (max-width: $sm) {
      flex-direction: column !important;
    }
    li {
      @media (max-width: $lg) {
        padding: 0px 24px 0 !important;
      }
      @media (max-width: $md) {
        padding: 0px 12px 0 !important;
      }
      @media (max-width: $sm) {
        padding: 10px !important;
      }
      border-right: none !important;

      a {
        color: white;
        font-size: 11px;
      }
    }
  }
}

#block-axway2020-copyrightmenu {
  @media (max-width: $sm) {
    margin: 0px auto;
  }
}

// ============================================================================================================ *
//                                            HP BANNER
// ============================================================================================================ *

body.path-frontpage,
.path-node-1676 {
  /* temporarily disabled */
  #products,
  .griffin-box-aqua,
  #block-views-block-customers-random-customers,
  #partner,
  h1.page-header,
  .alternating-text-and-image-rever {
    display: none;
  }

  #block-sitealert + #hero,
  #block-sitealert + .hero-wrapper #hero {
    background-position-y: 0px !important;

    .bkg-overlay {
      top: 0;

      @media (min-width: $sm) {
        // top: 154px;
        top: 102px;
      }

      @media (min-width: $md) {
        top: 135px;
        height: 200px;
      }

      // @media (max-width: 880px) {
      //   top: 129px;
      // }
    }

    &.hero-lg {
      /* sincr site alert is live
      min-height: 700px;
      // @media (max-width: $lg) {
      //   min-height: 441px;
      // }

      @media (min-width: $sm) {
        // min-height: 441px;
        min-height: 112px;
      }

      @media (min-width: $md) {
        min-height: 441px;
      }

      @media (min-width: $lg) {
        min-height: 700px;
      }*/

      // @media (max-width: $xxl) {
      //   min-height: 525px;
      // }

      .hero-content {
        @media (max-width: $md) {
          padding-top: 0;
        }

        h1 {
          @media (min-width: $lg) {
            margin-top: 63px;
          }
        }
      }
    }
  }

  .comparison-table-section {
    .container {
      @media (max-width: $md) {
        width: 100% !important;
      }

      .col-md-12 {
        @media (max-width: $md) {
          margin: 0;
          padding-left: 0;
          padding-right: 0;
          width: 100%;
        }

        h2 {
          @media (max-width: $md) {
            padding-left: 30px;
          }
        }
      }

      .tablefield-wrapper {
        @media (max-width: $md) {
          padding-top: 40px !important;
          padding-bottom: 40px !important;
        }

        .right-image {
          img {
            @media (max-width: $xl) {
              display: none;
            }
          }
        }

        .row_1 {
          & > .col_0 {
            img {
              @media (max-width: $sm) {
                width: 70px;
              }

              @media (max-width: $md) {
                width: 60px;
              }
            }
          }
        }

        td,
        th {
          @media (max-width: $sm) {
            padding: 1.375rem 0.5rem;
          }

          @media (max-width: $md) {
            padding: 1.375rem 0.15rem;
          }

          &.col_0 {
            @media (max-width: $sm) {
              font-size: 14px;
              line-height: 18px;
            }

            @media (max-width: $md) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }

        th {
          &.row_0 {
            @media (max-width: $sm) {
              font-size: 14px;
            }

            @media (max-width: $md) {
              font-size: 12px;
            }
          }
        }

        td {
          @media (max-width: $md) {
            &.chk_axway,
            &.chk_other {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  #customer {
    padding: 50px 0 0 !important;

    & > .container {
      padding-top: 0 !important;

      @media (max-width: $lg) {
        padding-top: 0;
      }
    }

    .col-md-12 {
      & > .center {
        padding-top: 50px;
      }
    }
  }

  .customer-logo {
    margin-bottom: 0px !important;
  }

  .customer-promotion-graph {
    padding-bottom: 50px !important;
  }

  #featured-promotion-section {
    .bigone {
      h2.card-title {
        font-family: Roboto Slab, Roboto, Arial, Helvetica, sans-serif;

        @media (max-width: 640px) {
          // font-size: 20px !important;
          // margin-bottom: 0 !important;
          // margin-top: 40px !important;
        }

        @media (max-width: 375px) {
          // margin-top: 60px !important;
        }
      }
    }

    .card-body.d-flex.align-items-center.float-right.p-5 {
      @media (max-width: 640px) {
        padding: 2rem !important;
      }
    }

    .fullbg-promotion-grid {
      // background-image: url("/sites/default/files/2021-07/Homepage_Tile_827x343-LiveDemo_1.jpg");
      background-image: url("/sites/default/files/2021-07/amplify_demo.jpg");
      justify-content: flex-end;

      @media (max-width: 990px) {
        margin-left: 15px;
        margin-right: 15px;
      }

      @media (max-width: 375px) {
        background-position-x: -10px !important;
        // background-position-x: -54px !important;
      }

      .card-text {
        color: white;
      }
    }

    .w-45 {
      background: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // width: 65% !important;
      width: 100% !important;

      @media (min-width: 800px) and (max-width: 1289px) {
        // padding-top: 80px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        flex-direction: row;
      }

      @media (max-width: 640px) {
        background: none !important;
        width: 100% !important;
      }

      .card-text {
        width: 100%;

        @media (min-width: 768px) and (max-width: 991px) {
          width: 45%;
        }

        p {
          margin-top: 20px;

          a {
            @media (min-width: 768px) and (max-width: 991px) {
              font-size: 14px;
            }
          }
        }
      }

      &.float-right {
        @media (max-width: 375px) {
          padding: 0 !important;
        }
      }
    }
  }

  #features ul li {
    font-weight: bold;
  }

  #footer {
    @media (max-width: $xxl) {
      padding-left: 50px;
    }

    .col-sm-12 {
      & > .footer-social {
        a {
          img {
            @media (max-width: $lg) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .container {
      @media (max-width: $xl) {
        padding: 100px 0p 80px;
      }
    }

    hr {
      @media (max-width: $lg) {
        width: 100px !important;
      }

      @media (max-width: $xl) {
        width: 200px !important;
      }

      @media (max-width: $xxl) {
        width: 290px !important;
      }
    }
  }

  #hero {
    border-bottom: 1px solid;
    box-shadow: unset !important;
    /*@media (max-width: $xxxl) {
      background-size: 50% !important;
      background-position-x: right !important;
      background-position-y: bottom !important;
    }*/
    @media (max-width: $lg) {
      /*background-position-x: 390px !important;*/
      background-position-x: 120% !important;
      background-position-y: 85px !important;
      /*background-size: 60% !important;*/
    }
    @media (max-width: $md + 112px) {
      .bkg-overlay {
        background: #ffffffd1 !important;
        display: block !important;
        background-position-x: 300px !important;
        top: 180px;
        height: 190px;
        width: 750px;
      }
      .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    @media (max-width: $md) {
      .bkg-overlay {
        height: 230px;
        top: 170px;
        width: 700px;
      }
    }
    @media (max-width: $sm) {
      .bkg-overlay {
        height: 100%;
        top: 0px;
        width: 100%;
      }
    }
    @media (max-width: $sm) {
      background-position-x: 300px !important;
    }
    @media (max-width: 375px) {
      background-position-x: -110px !important;
      background-size: 200% !important;
    }

    .hero-content {
      @media (max-width: $md) {
        padding-top: 100px;
      }
    }
  }

  // .intercom-lightweight-app-launcher {
  //   display: none;
  // }

  .hero-lg {
    background-color: #f3f3f3 !important;
    min-height: 800px;
    padding: 0 !important;
    background-position-x: 108% !important;
    background-position-y: 98px !important;
    background-size: 950px 700px !important;

    /*@media (max-width: $xxxl) {
      min-height: 690px;
    }*/
    @media (max-width: $xxl+100px) {
      /*background-size: 50% !important;*/
      background-size: 700px 525px !important;
      min-height: 625px;
    }
    @media (max-width: $xl) {
      min-height: 540px;
      background-size: 620px 440px !important;
    }
    @media (max-width: $lg) {
      min-height: 525px;
    }
    @media (max-width: $md) {
      min-height: 425px;
    }

    .hero-content {
      h1 {
        font-family: Roboto Slab, Roboto, Arial,
          Helvetica, sans-serif;
        line-height: 56px !important;
        font-size: 42px !important;
        max-width: 700px;
        margin-top: 105px;
        padding-right: 0px !important;
        color: $dark-gray-2024 !important;
        font-weight: 500 !important;

        @media (max-width: $xl) {
          line-height: 48px !important;
          font-size: 35px !important;
        }

        @media (max-width: $lg) {
          /*font-size: 32px !important;
          line-height: 46px !important;*/
          font-size: 30px !important;
          line-height: 35px !important;
        }
        @media (max-width: $sm) {
          margin-top: 0px;
        }
        @media (max-width: 375px) {
          padding-right: 0px !important;
          font-size: 25px !important;
          line-height: 30px !important;
        }
      }

      p {
        max-width: 700px;
        font-size: 18px !important;
        line-height: 28px !important;
        color: $dark-gray-2024 !important;
        padding-right: 80px !important;
        font-weight: $font-weight-medium !important;
        @media (max-width: $lg) {
          line-height: 26px !important;
        }
        @media (max-width: $md) {
          padding-right: 30px !important;
        }
        @media (max-width: 375px) {
          padding-right: 0px !important;
        }
      }

      @media (max-width: $md) {
        background: none !important;
        //padding-top: 0px !important;
      }
    }
  }

  .right-image {
    display: block !important;
  }

  a.site-alert {
    &:after {
      background-image: url(/themes/custom/axway2020/img/arrrow-2021-white.png);
      content: "";
      display: inline-block;
      height: 15px;
      margin-left: 20px;
      margin-top: -1px;
      vertical-align: middle;
      width: 21px;
    }

    &:hover {
      &:after {
        transform: translate(10px);
        transition: all 0.5s ease;
      }
    }
  }

  #site-alert-close {
    background-image: url("/sites/default/files/2021-04/site_alert_close.svg") !important;
  }

  .tablefield-wrapper {
    .right-image {
      img {
        display: none !important;
      }
    }
  }

  #teaser {
    .col-md-3.text-left {
      @media (max-width: $xxl) {
        justify-content: flex-end;
      }
    }

    .text-left {
      @media (max-width: $xxl) {
        padding-left: 35px;
      }
    }
  }

  #usp {
    background-size: 466px 345px !important;
  }

  #usp .container .col-md-6.usp-right .col.empty {
    @media (max-width: 1440px) {
      display: none;
    }
  }
}

// ============================================================================================================ *
//                                            STAT BOXES 2021
// ============================================================================================================ *
.grey-n-sm-reverse-bg {
  background-color: $light-grey-2021;
  @media (max-width: $md) {
    background-color: $white;
  }
}
.stat-boxes {
  position: relative;
  padding: unset;
  background: white;
  color: white;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;

  .container {
    margin-left: 0px;
  }

  h2 {
    color: $teal-2021;
  }
  p {
    color: $dark-gray-2024;
    font-size: 20px;
    line-height: 1.5em;
  }

  .container {
    //max-width: $xl + 60px;
    max-width: $xxl;
    width: 93.75%;

    @media (max-width: $xxl) {
    }

    .row {
      .col-md-3 {
        @media (min-width: 768px) {
          padding: 40px;
          border-right: 1px solid $light-grey-2021;
          min-height: 285px;
        }
        @media (max-width: $md) {
          padding: 40px;
        }
        @media (min-width: $md) {
          padding: 70px 80px;
        }
      }
      .col-md-8 {
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          align-self: flex-start;
        }
      }

      .col-md-4.center-child {
        //display: block;
        img {
          // margin-top: $spacing-lg;
          //  width: 100%; Removing to allow images to maintain natural size.

          @media (max-width: $md - 1px) {
            margin-top: 30px;
            width: 90%;
          }
        }
      }
    }
  }

  .griffin {
    display: block;
    position: absolute;
    top: -90px;
    left: 0;
    height: 560px;
    width: 560px;
    background: transparent left top no-repeat;
    background-size: contain;

    @media (max-width: $md) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center center;
    }
  }

  .stat-box {
    // add to .row for stat box

    @media (max-width: $md) {
      text-align: center;
      .field--item.col-md-4:last-child p {
        margin-bottom: 0px;
      }
    }

    h2,
    h3 {
      /*margin-bottom: $spacing-sm;*/
      font-size: 30px;
      line-height: 40px;
      font-weight: $font-weight-large;
      color: $teal-2021;
    }
    p {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: $font-weight-medium;
      color: $dark-gray-2024;

      @media (max-width: $md) {
        margin-bottom: $spacing-lg;
      }
    }
  }

  .cta-box {
    // add to .row for cta box
  }

  .btn {
    margin-right: 23px;

    &:last-child {
      margin-right: 0;
    }
  }

  .img-container {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    &:hover {
      .play-btn {
        opacity: 0.9;
      }
    }

    .play-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }
}

// ==================================== ==================================== ==================================== *
//                                            FEATURES
// ==================================== ==================================== ==================================== *
#features {
  padding-top: $padding-top-2021;

  h2 {
    font-weight: $font-weight-large;
    font-size: 30px;
    color: $dark-gray-2024;
    max-width: 460px;
    line-height: 40px;

    @media (min-width: $md) {
      max-width: none;
    }

    @media (min-width: $xxl) {
      max-width: 460px;
    }
  }

  div.container {
    //max-width: 1260px;
    //max-width: $xxl;
    // max-width: 1600px;
    // width: 100%;
    padding-top: $padding-top-2021;
    padding-bottom: $padding-bottom-2021;
    padding-right: 35px;
    background-color: $light-beige;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 90px;

    @media (min-width: $sm) {
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    }

    @media (min-width: $lg) {
      background-image: url("/themes/custom/axway2020/img/white-square.jpg");
      // width: 93.75%;
      padding-right: 15px;
    }

    @media (min-width: $xxl) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1600px;
      width: 100%;
    }
  }

  div.row {
    // align-items: center;
    // min-height: 500px;
    margin: auto;

    @media (min-width: $xl) {
      max-width: 1440px;
    }
  }

  div.copy {
    display: flex;
    align-items: center;

    @media (min-width: $xl) {
      border-right: 1px solid white;
    }
  }

  div.feature-body {
    position: relative;

    @media (min-width: $lg) {
      padding-top: 64px;
    }
  }

  div.feature-footer {
    align-items: center;
    // margin-left: -15px;
    // margin-right: -15px;
    max-width: none;

    @media (min-width: $xl) {
      border-top: 1px solid white;
    }

    .col-xl-6 {
      // padding-top: 80px;
      // padding-left: 117px;
      padding-top: 50px;
      padding-bottom: 50px;

      @media (min-width: $lg) {
        padding: 80px 0 83px 117px;
      }

      div {
        display: none;
        // margin-top: 15px;

        &.target {
          display: block;
          -webkit-animation: fade-in-bottom 0.6s
            cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
            both;
          min-height: 150px;
          // padding-left: 15px;

          @media (min-width: $lg) {
            max-width: 490px;
          }

          @media (min-width: $xxl) {
            // padding-left: 90px;
            padding-left: 0px;
          }

          @media (min-width: 1600px) {
            padding-left: 68px;
          }

          // @media (min-width: 1480px) {
          //   padding-left: 0;
          // }

          p {
            font-size: 18px;
            font-weight: $font-weight-medium;
            line-height: 28px;
            color: $dark-gray-2024;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  div.image {
    @media (min-width: $md) {
    }

    @media (min-width: $xl) {
      position: absolute;
      right: -460px;
      top: -85px;
      max-width: 1050px;
    }

    @media (min-width: $xxl) {
      max-width: 1220px;
    }
  }

  ul {
    margin: 30px 0 45px 0px;
    padding-left: 15px;

    @media (min-width: $md) {
      padding-left: 40px;
    }

    @media (min-width: $lg) {
      margin: 0px 0 60px 50px;
    }

    li {
      color: $dark-grey-2021;
      font-family: "Roboto Condensed", Arial,
        Helvetica, sans-serif;
      list-style: none;
      margin-bottom: 10px;
      padding-left: 15px;
      text-transform: uppercase;
      line-height: 38px;

      &:hover,
      &.active {
        color: #000000;
        list-style: none;

        &::marker {
          content: "\25A0";
          color: #7b68ee;
        }
      }

      &:hover {
        cursor: pointer;
      }

      @media (min-width: $xxl) {
        margin-bottom: 0;
      }
    }
  }

  /*a {
    color: #353535;
    font-family: "Roboto Mono", Courier, sans-serif;
    font-size: 16px;
    font-weight: 300;

    span {
      border-bottom: 1px solid #353535;
    }

    &::after {
      content: "\2794";
      color: #7b68ee;
      padding-left: 15px;
    }
  }*/

  img {
    display: none;
    // max-width: 900px;
    // height: auto;

    &.show {
      display: block;
      -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
      animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-12-5 21:2:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

// ============================================================================================================ *
//                                            CAPABILITIES
// ============================================================================================================ *
#capabilities {
  background-image: url("/themes/custom/axway2020/img/capabilities_bkg_v3.jpg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 590px 692px;
  padding: $spacing-xl 0;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;

  @media (min-width: $md) and (max-width: 1600px) {
    background-size: 374px 461px;
  }

  // .cap-bkg {
  //   position: absolute;
  //   width: 590px;
  //   height: 692px;
  //   bottom: 0;
  //   left: 0;
  // }

  .container {
    max-width: 1440px;
    width: 100%;

    // .col-lg-7 {
    //   @media (min-width: $md) {
    //     margin-left: 4.2%;
    //   }
    // }
  }
  li {
    color: #000;
    font-weight: 400;
  }

  h3 {
    color: $dark-gray-2024;
    font-size: 32px;
  }

  .capability {
    margin-bottom: 45px;

    h4 {
      color: $teal-2021;
      font-size: 24px;
      line-height: 1.3;
      margin-bottom: 20px;

      @media (min-width: $md) {
        padding-right: $spacing-md;
      }
    }

    p {
      color: $dark-gray-2024;
      font-size: 18px;
      line-height: 28px;
      font-weight: $font-weight-medium;
      @media (min-width: $md) {
        padding-right: $spacing-md;
      }
    }
  }
  .only_arrow {
    background-image: url(/themes/custom/axway2020/img/purple_arrow.svg);
    content: "";
    display: inline-block;
    height: 15px;
    margin-left: 10px;
    vertical-align: middle;
    width: 21px;
    float: right;
    margin-top: 5px;
  }
  .main-link {
    margin-top: 30px;
    margin-bottom: 0px !important;
  }
}
// TEMPLATE #1
#capabilities.design1 {
  h3 {
    color: $teal-2021;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;

    @media (min-width: $md) {
      padding-right: $spacing-md;
    }
  }
}
// TEMPLATE #3
#capabilities.design3 {
  .light-grey-2021-bg {
    background-color: #f4f4f4;
  }
  .events {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    h4,
    h3 {
      min-height: 65px;
    }
  }
  .capability-bottom {
    align-self: flex-end;
  }
  .name {
    font-family: "Roboto Condensed" !important;
    font-weight: bold !important;
    font-size: 16px !important;
    letter-spacing: 0.01em;
    line-height: 24px !important;
    text-align: left;
    color: $dark-gray-2024 !important;
  }
  .col-lg-3.para p {
    color: $dark-gray-2024;
    font-size: 18px;
    font-weight: 400;
  }
  .btn-secondary {
    font-weight: 400 !important;
  }
  h4,
  h3 {
    color: #016580;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    h3,
    h4 {
      padding-right: 30px;
    }
  }
}
// TEMPLATE #4
#capabilities.design4 {
  .fullwidth-bg-color {
    left: calc(-1 * (100vw - 100%) / 2);
    position: relative;
    width: 100vw;
    padding-bottom: 100px;
  }
  .bg-white {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .capability-body {
    padding: 36px;
    background-color: #fff;
  }
  .expert {
    display: flex;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }
  .capability h3,
  h4 {
    padding-right: 30px;
    width: 88%;
    float: left;
    color: #016580;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  .capability p {
    padding-right: 5px;
  }
  @media (min-width: 768px) {
    h3,
    h4 {
      padding-right: 30px;
    }
  }
}

// TEMPLATE #5
#capabilities.design5 {
  .bg-white {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .capability-body {
    padding: 36px;
    background-color: #fff;
  }

  .expert {
    display: flex;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .p400,
  .p400 p {
    color: $dark-gray-2024;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .capability h4,
  h3 {
    padding-right: 30px;
    width: 88%;
    float: left;
    color: #016580;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;
  }

  .capability p {
    padding-right: 5px;
  }
}

// TEMPLATE #6
#capabilities.design6 {
  .fullwidth-bg-color {
    left: calc(-1 * (100vw - 100%) / 2);
    position: relative;
    width: 100vw;
    padding-bottom: 100px;
  }

  .bg-white {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex: auto;
  }

  .capability-body {
    padding: 36px;
    background-color: #fff;
  }
  .expert {
    display: flex;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .h100grey {
    height: 100%;
    background-color: #f4f4f4 !important;
  }

  .capability h3,
  h4 {
    padding-right: 30px;
    width: 88%;
    float: left;
    color: #016580;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;
  }

  .capability h3.no-link,
  h4.no-link {
    padding-right: 0;
    width: 100%;
  }

  .capability p {
    padding-right: 5px;
  }

  .btn-secondary {
    font-weight: 400 !important;
  }
}

// TEMPLATE #7
#capabilities.design7 {
  .bg-white {
    background-color: #fff !important;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .capability-body {
    padding: 36px;
    background-color: #f3f3f3;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .capability-body h3 {
    position: relative;
    padding-right: 30px;
    color: #016580;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;
  }

  .capability-body h3 span {
    display: block;
    width: 90%;
  }

  .capability-body h3:after {
    background-image: url(/themes/custom/axway2020/img/purple_arrow.svg);
    color: #7e71ff;
    content: " ";
    height: 15px;
    position: absolute;
    right: 0px;
    top: 8px;
    width: 21px;
  }
  #capabilities .expert {
    display: flex;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }
  .p400,
  .p400 p {
    color: $dark-gray-2024;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  @media (min-width: 992px) {
    .capability-body {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
    }
    .capability-body a {
      flex: 1 0 auto;
    }
    /*.capability-body a h3 {
      margin-bottom: 0;
    }*/
  }
}
// eof TEMPLATE #7

// TEMPLATE #8
#capabilities.design8 {
  background-color: #f4f4f4 !important;
  .events h3,
  h4 {
    min-height: 0px !important;
  }

  .events {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    margin-top: 6px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .capability-bottom {
    align-self: flex-end;
  }
  .name {
    font-family: "Roboto Condensed" !important;
    font-weight: bold !important;
    font-size: 16px !important;
    letter-spacing: 0.01em;
    line-height: 24px !important;
    text-align: left;
    color: $dark-gray-2024 !important;
  }
  .col-lg-3.para p {
    color: $dark-gray-2024;
    font-size: 18px;
    font-weight: 400;
  }
  .row.expertrow {
    justify-content: flex-end;
  }
  .btn-secondary {
    font-weight: 500;
  }
  h3,
  h4 {
    color: $teal-2021;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;

    @media (min-width: $md) {
      padding-right: $spacing-md;
    }
  }
}

// ============================================================================================================ *
//                                            SUB NAV 2021
// ============================================================================================================ *
#sub-nav {
  padding: $spacing-xl 0;

  h2,
  h3 {
    color: $dark-gray-2024;
    font-size: 32px;
    line-height: normal;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .container {
    max-width: 1440px;
    width: 100%;
  }

  .col-lg-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .col-xl-3,
  .col-xl-4,
  .col-xl-6 {
    display: flex;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .btn-secondary {
    // Paragraph-specific styles for btn-secondary class
    line-height: initial;
    font-weight: 500;
    max-width: 100%;
    padding: 10px 0 10px 16px;
    position: relative;
    width: 100%;

    @media (min-width: 1200px) and (max-width: 1350px) {
      font-size: 1vw;
    }

    &:after {
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }

  // a {
  //   border: 1px solid $violet-2021;
  //   // color: #353535;
  //   color: #000;
  //   display: block;
  //   font-family: "Roboto", sans-serif;
  //   font-size: 16px;
  //   font-weight: 500;
  //   max-width: 100%;
  //   padding: 10px 0 10px 16px;
  //   position: relative;
  //   width: 100%;

  //   &:hover {
  //     background-color: $violet-2021;
  //     color: #fff;

  //     &::after {
  //       background-image: url("/themes/custom/axway2020/img/white_arrow.svg");
  //       color: #fff;
  //     }
  //   }

  //   &::after {
  //     background-image: url("/themes/custom/axway2020/img/purple_arrow.svg");
  //     color: $violet-2021;
  //     content: " ";
  //     height: 15px;
  //     width: 21px;
  //     position: absolute;
  //     right: 10px;
  //     top: 14px;
  //   }
  // }
}

// ============================================================================================================ *
//                                            CUSTOMER PROMOTION 2021
// ============================================================================================================ *

.customer-promotion {
  background-color: white;
  padding-bottom: 50px;

  .container-fluid {
    @media (max-width: $md - 1px) {
      padding: 0;
    }
  }
  .container {
    max-width: $xxl;
    //max-width: 1440px;
    //max-width: 1260px;
    width: 93.75%;
    padding-top: $padding-top-2021;
    padding-bottom: $padding-bottom-2021;
  }
  .text-card {
    min-height: 250px;
    @media (max-width: $md - 1px) {
      // min-height: 250px;
      //display: none;
    }
  }

  .text-card {
    display: flex;
    /*align-items: center;*/
    /*padding: 0px 7%;*/
    flex-direction: column;

    @media (max-width: $md - 1px) {
      // min-height: 250px;
      min-height: auto;
    }

    @media (max-width: $lg - 1px) {
      // min-height: 250px;
      min-height: auto;
    }

    .text-card-content {
      width: 720px;
      max-width: 100%;
      margin: 0 auto;
      flex: 1 1 auto;

      .btn {
        margin-top: $spacing-sm;

        @media (max-width: $md) {
          margin-bottom: $spacing-sm;
        }
      }
      img.customer-logo {
        width: 270px;
        height: 80px;
        object-fit: cover;
        object-position: -16px;
      }
      span.customer-quote {
        font-size: 18px;
        line-height: 28px;
        font-weight: $font-weight-medium;

        p:first-child,
        h5 {
          color: $dark-gray-2024;
          font-size: 24px;
          font-style: italic;
          font-weight: 400;
          line-height: 1.5;
          margin-bottom: 20px;
        }

        p {
          font-size: 18px;
          line-height: 28px;
          font-weight: $font-weight-medium;
        }
      }
      h2.customer-ps {
        max-width: 450px;
        @media (max-width: $md) {
          width: 100%;
          padding-top: 30px;
        }
      }
    }
  }
}

// ============================================================================================================ *
//                                            RESOURCES (NEW)
// ============================================================================================================ *
#resources-new {
  background-color: #fff;
  padding: 100px 0;
  left: calc(-1 * (100vw - 100%) / 2);
  position: relative;
  width: 100vw;
  h3 {
    color: $dark-gray-2024;
    font-size: 32px;
  }

  .container {
    max-width: 1440px;
    width: 100%;
  }

  .col-lg-3 {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-base;
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: $lg) {
      // margin-bottom: 0;
    }
  }

  .row {
    &:first-child {
      align-items: center;
      margin-bottom: 41px;

      .col-lg-6 {
        padding-left: 10px;
      }

      .col-lg-3 {
        padding-left: 10px;
        padding-right: 10px;
      }

      .btn-tertiary {
        @media (min-width: $md) {
          text-align: right;
        }
      }

      a {
        // color: #353535;
        // font-family: "Roboto Mono", Courier, sans-serif;
        // font-size: 16px;
        // font-weight: $font-weight-regular;

        span {
          border-bottom: 1px solid #353535;
        }

        // &::after {
        //   content: "\2794";
        //   color: $violet-2021;
        //   padding-left: 15px;
        // }
      }
    }

    &:nth-child(2) {
    }
  }

  .card-body {
    background-color: #f4f4f4;
    padding: 36px;
  }

  .card-img-top {
    // width: 332px;
  }

  .card-title {
    color: $dark-gray-2024;
    font-family: "Roboto Condensed", Arial,
    Helvetica, sans-serif;
    font-size: 16px;
    font-weight: $font-weight-bold;
    margin-top: 10px;
    position: relative;
    text-transform: uppercase;

    span {
      display: block;
      max-width: 75%;

      &::after {
        content: " ";
        color: $violet-2021;
        font-weight: $font-weight-regular;
        position: absolute;
        right: 0px;
        background-image: url("/themes/custom/axway2020/img/purple_arrow.svg");
        width: 21px;
        height: 15px;
        top: 0px;
      }
    }
  }

  .card-text {
    // color: #353535;
    color: $dark-gray-2024;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  &.tan {
    background-color: #f4ede4;
  }
  &.white {
    background-color: #fff;
  }
  &.gray {
    background-color: #f4f4f4;
  }
  &.design3 {
    h2 {
      margin: auto;
      margin-bottom: 5rem;
      color: $dark-gray-2024;
      font-family: "Roboto",Arial,Helvetica,sans-serif;
      font-size: 30px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 1.35em;
    }
  }
}

// ============================================================================================================ *
//                                            CUSTOMER PROMOTION 2021 - HP ONLY
// ============================================================================================================ *

body.path-frontpage #customer,
body.path-node-1676 #customer {
  //padding: unset !important;
  & > .container {
    //max-width: 1440px;
    //max-width: 1260px;
    //max-width: $xxl;
    //width: 93.75%;
    padding-top: $padding-top-2021;
    padding-bottom: $padding-bottom-2021;
    max-width: 100%;
    width: 100%;

    & > div:nth-child(1) {
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
      width: 93.75%;
    }
    & > div.row.customer-logos .container {
      max-width: 100% !important;
      width: 100%;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  h2 {
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: $font-weight-large;
    line-height: 40px;
    margin-bottom: 50px;
    margin-left: unset;
    margin-right: unset;
    max-width: 450px;
    text-align: left;
    //padding-left: 50px;
    color: $dark-gray-2024;
  }
  a.btn-red {
    margin: 20px auto 10px;
  }
}

// ============================================================================================================ *
//                                            TOP CUSTOMER - CHECK THE TWIG FILES
// ============================================================================================================ *

// ============================================================================================================ *
//                                            COMPETITOR COMPARISON
// ============================================================================================================ *

.comparison-table-section {
  .container {
    background-color: white;
    max-width: $xxxl;
    width: 100%;
    padding-top: $padding-top-2021;
    padding-bottom: $padding-bottom-2021;
    p.ast {
      font-size: 11px;
      text-align: center;
      padding: 0 10px;
    }
    h2 {
      font-family: Roboto;
      font-size: 30px;
      font-style: normal;
      font-weight: $font-weight-large;
      line-height: 40px;
      margin-bottom: 0px;
      color: $dark-gray-2024;
    }

    .matrix-link {
      display: flex;
      justify-content: normal;
      margin-top: 20px;

      @media (min-width: $md) {
        justify-content: flex-end;
        margin-top: 0;
      }
    }

    .title-row {
      align-items: center;
      margin-bottom: 30px;
    }

    .tablefield-wrapper {
      width: 100%;
      margin: unset;
      background-color: $light-grey-2021;
      padding-top: 80px !important;
      padding-bottom: 60px !important;
      position: relative;

      table {
        max-width: 85%;
        width: 85%;
        margin: auto;
        border-top: none;
        thead th {
          border-bottom: none;
        }
        th {
          width: 160px;
          padding: 1.375rem 0.75rem;
          @media (max-width: $md) {
            padding: 1.375rem 0.5rem;
          }
        }
      }
      th.row_0 {
        font-stretch: condensed;
        font-family: "Roboto Condensed";
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        border-top: none;
        color: $dark-gray-2024;
        line-height: 30px;
        @media (max-width: $md) {
          font-size: 16px;
          line-height: 22px;
        }
      }

      tr,
      tr.odd,
      tr.even {
        text-align: center;
      }
      th.col_0,
      td.col_0 {
        font-family: Roboto;
        font-size: 18px;
        color: $dark-grey-2021;
        text-align: left;
        font-weight: $font-weight-medium;
        border: none;
        line-height: 28px;
      }

      /*td.row_1 {
        background: white;
      }*/
      td {
        border: 1px solid #b4b6b8;
        width: 200px;
        padding: 1.375rem 0.75rem;
      }
      td.chk_axway {
        //background-image: url(../img/chk_axway.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12%;
        background-color: white;
        color: $teal-2021;
        font-size: 18px;
      }
      td.chk_other {
        //background-image: url(../img/star.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12%;
        color: $dark-grey-2021;
        font-size: 18px;
      }
      td.redflag {
        background-image: url(../img/red_flag.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12%;
      }
      td.row_1.col_1 {
        position: relative;
        img.ribbon {
          position: absolute;
          width: 12%;
          left: 9px;
          top: 0;
        }
      }
      td.row_5 {
        border-bottom: none !important;
      }
      .table-responsive {
        border-bottom: 1px solid #b4b6b8;
      }
      .right-image {
        position: absolute;
        right: 0;
        bottom: 20px;
        top: 65%;
        z-index: 10;
        display: none;

        img {
          width: 90%;
        }
      }
    }
  }
}
.starsprite {
  background-image: url("/themes/custom/axway2020/img/stars-sprite.svg");
  background-repeat: no-repeat;
  background-size: 600%;
  margin: auto;
}
body.path-frontpage .comparison-table-section,
body.path-node-1676 .comparison-table-section {
  .container {
    max-width: $xxl;
    width: 93.75%;
  }
}
// ============================================================================================================ *
//                                            FEATURED PROMOTION GRID
// ============================================================================================================ *

#featured-promotion-section {
  .container {
    background-color: white;
    //max-width: 1260px;
    max-width: $xxl;
    width: 93.75%;
    padding-top: $padding-top-2021;
    padding-bottom: $padding-bottom-2021;

    h2 {
      font-family: Roboto;
      font-size: 30px;
      font-style: normal;
      font-weight: $font-weight-large;
      line-height: 40px;
      margin-bottom: 30px;
      color: $dark-gray-2024;
      padding-left: 15px;
    }
    .col-md-12 {
      display: flex;
      flex-direction: row;
    }
    @media (min-width: 992px) {
      .col-lg-6 {
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
        min-height: 340px;
        background-size: cover;
        background-position: center;
      }
    }

    @media (min-width: 992px) {
      .col-lg-5 {
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
      }
    }

    .card {
      min-height: 165px;
      border: none;
    }
    .tile_one {
      h2 {
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: $font-weight-large;
        line-height: 30px;
        margin-bottom: 30px;
        color: white;
        padding-left: 0;
      }
      .content {
        margin-left: 60%;
        margin-top: 5%;
        margin-right: 2%;
      }
    }
    .tile_two {
      padding-bottom: 7px;
      padding-right: 0;
    }
    .tile_three {
      padding-top: 8px;
      padding-right: 0;
    }
    .tile_two .card,
    .tile_three .card {
      background-color: $soft-grey-2021;
      padding: 25px 40px;

      h2 {
        font-size: 22px;
        font-weight: $font-weight-large;
        color: $teal-2021;
        line-height: 30px;
        padding-left: 0;
        margin-bottom: 10px;
      }
      .text-card {
        display: inline-flex;

        p {
          font-size: 18px;
          color: $dark-gray-2024;
          line-height: 28px;
          max-width: 415px;
          font-weight: $font-weight-medium;
        }

        img {
          padding-left: 40px;
        }
      }
    }
  }
}

// ============================================================================================================ *
//                                            HERO BANNER 2021
// ============================================================================================================ *
.hero-wrapper {
  height: unset !important;
  overflow: hidden;
}

#hero-2021 {
  align-items: start;
  border-bottom: 1px solid #fff;
  background-color: white;
  // background-color: #f4f4f4;
  //background-position: 100% 98px;
  background-repeat: no-repeat;
  //background-size: auto;
  display: flex;
  flex-direction: row;
  min-height: 800px;
  //padding: 100px 0px 0px;
  position: relative;
  padding: 120px 0 0;
  background-position-x: 100% !important;
  background-position-y: 98px !important;
  background-size: 950px 700px !important;
  .container {
    width: 93.75%;
  }
  &.bkg-gray {
    background-color: $light-grey-2021 !important;
  }
  &.bkg-white {
    background-color: white !important;
  }
  &.bkg-beige {
    background-color: $light-beige !important;
  }

  &.toc-align-center {
    align-items: center;
  }

  @media (max-width: $xxxl) {
    min-height: 690px;
  }
  @media (max-width: $xxl) {
    //background-size: 50% !important;
    min-height: 625px;
  }
  @media (max-width: $xl) {
    min-height: 540px;
  }
  @media (max-width: $lg) {
    min-height: 525px;
  }
  @media (max-width: $md) {
    min-height: 425px;
  }

  @media (max-width: $lg) {
    padding: $spacing-lg + 75px 0 $spacing-lg;
    background-size: cover;
  }

  @media (max-width: $md) {
    background-size: cover !important;
    background-position: top right !important;
    background-image: none !important;

    .container {
      padding-bottom: 30px;
    }
    .hero-content {
      padding-top: 20px;
    }
    .overlay-mobile {
      background-color: white;
      background: #ffffff !important;
      background-position-x: 300px !important;
      display: block !important;
      position: absolute;
      width: 100%;
      top: 0px;
      height: 100%;
      opacity: 1;
    }
  }
  @media (min-width: $md) and (max-width: $lg) {
    background-position-x: right !important;
    background-position-y: 50% !important;
    background-size: 60% !important;
  }
  @media (min-width: $lg) and (max-width: $xl2) {
    background-size: 60% !important;
    background-position-x: right !important;
    background-position-y: 50% !important;
  }
  @media (min-width: $xl2) and (max-width: 1600px) {
    background-size: 50% !important;
    background-position-x: right !important;
    background-position-y: 50% !important;
  }

  /*& > .container {
    padding-top: 140px;
  }*/

  .row {
    align-items: start;

    &.has-toc {
      align-items: normal;
    }
  }

  .hero-content {
    z-index: 1;

    a.btn-tertiary.left + h1 {
      margin-top: 60px;
    }
    /*
        h1 {
          //line-height: 1.2!important;
          color: black;
          font-family: Roboto Slab, Roboto, "Times New Roman", sans-serif;
          font-weight: 500;
          font-size: 42px;
          margin-bottom: 20px;
          line-height: 1.5;

          @media (min-width: $lg) {
            line-height: 56px !important;
          }
        }

        p {
          color: black !important;
          font-size: 18px !important;
          font-weight: 400 !important;
          // line-height: 28px !important;
          line-height: 1.6;
          padding-right: 80px !important;
          // line-height: 1.6em;

          @media (min-width: $lg) {
            max-width: 700px;
          }
        }
    */
    h1 {
      font-family: Roboto Slab, Roboto, Arial,
        Helvetica, sans-serif;
      line-height: 56px !important;
      font-size: 42px !important;
      max-width: 700px;
      margin-top: 105px;
      padding-right: 80px !important;
      color: $dark-gray-2024 !important;
      font-weight: 500 !important;
      @media (max-width: $lg) {
        font-size: 32px !important;
        line-height: 46px !important;
      }
      @media (max-width: $sm) {
        margin-top: 0px;
      }
      @media (max-width: 375px) {
        padding-right: 0px !important;
        font-size: 25px !important;
        line-height: 30px !important;
      }
    }

    p {
      max-width: 700px;
      font-size: 18px !important;
      line-height: 28px !important;
      color: $dark-gray-2024 !important;
      padding-right: 80px !important;
      font-weight: $font-weight-medium !important;
      @media (max-width: $md) {
        padding-right: 30px !important;
      }
      @media (max-width: 375px) {
        padding-right: 0px !important;
      }
    }
    .btn-hero {
      //margin-top: $spacing-md;
      @media (min-width: $lg) {
        margin-bottom: 10px;
      }
    }
  }

  .stat-box {
    margin-top: $spacing-md;

    h2 {
      color: $teal-2021;
      font-size: 36px;
      font-weight: $font-weight-regular;
    }

    p {
      color: $dark-gray-2024 !important;
      font-size: 18px !important;
      font-weight: 400 !important;
      line-height: 28px !important;
      max-width: 700px;
      padding-right: 80px !important;
      // line-height: 1.6em;
    }
  }

  .stat-offset {
    .field--item.col-md-2:first-child {
      @media (min-width: $lg) {
        margin-left: 40%;
      }
    }
  }
  .video-wrapper {
    margin-top: 105px;
    @media (max-width: $sm) {
      margin-top: 20px;
    }
  }
  .video-thumb {
    background-color: #fff;
    //box-shadow: 0px 0px 31px 3px #bab9b8;
    box-shadow: 0px 0px 31px 3px #707070;
    background-size: cover;
  }

  .small {
    color: #7a929f;
    font-size: 11px;
  }
  // LEVEL 3
  &.level-3 {
    min-height: 545px !important;
    background-position-x: 100% !important;
    background-position-y: 100% !important;
    background-size: unset !important;
    .hero-content h1 {
      margin-top: 160px;
    }
    .hero-content {
      //margin-top: 100px;
      a.btn-tertiary {
        display: block;
        padding-top: 70px;
      }
      a.btn-tertiary + h1 {
        margin-top: 60px;
      }
    }
    // CUSTOMER PAGE
    // VIDEO
    .col-lg-6.hero-content.left a.btn-tertiary {
      padding-top: 10px;
    }
    .hero-content.right.video {
      left: calc((100vw - 123%) / 2);
    }
    h1.customer {
      margin-bottom: 10px;
    }
    p.customer-types {
      font-size: 16px !important;
      font-family: "Roboto Condensed" !important;
      font-weight: bold !important;
      text-transform: uppercase;
      width: unset;
      color: $dark-gray-2024 !important;
    }
    .customer-img {
      min-height: 180px;
      display: flex;
      align-items: flex-end;
      margin-bottom: 30px;
    }
    .bkg-overlay-customer {
      background: rgba(0, 0, 0, 0.5) !important;
      top: 16px;
      left: 222px;
      height: 357px;
      width: 276px;
      position: absolute;
      z-index: 1;
      box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.2);
    }
    .video-thumb,
    .video-thumb.customer2021,
    .ss-thumb.customer2021 {
      background-size: 276px 357px;
      background-color: unset !important;
      box-shadow: unset !important;

      .btn-red {
        left: 37% !important;
        margin-right: -50%;
        position: absolute;
        top: 50%;
        z-index: 100;
        background-color: #ff0000;
        border: 1px solid #ff0000;
        &:hover {
          background-color: #b1001a;
          border: 1px solid #b1001a;
        }
      }
    }
    .ss-thumb.customer2021 {
      background-size: 276px 357px;
      background: center no-repeat;
      cursor: pointer;
      display: block;
      flex: 1;
      min-height: 337px;
      position: relative;
      width: 690px;
      padding-top: 390px;
    }
    .video-wrapper {
      margin-top: 60px;

      .bkg-overlay-customer {
        top: 83px;
        left: 55px;
        height: 248px;
        width: 445px;
      }
    }
    .video-wrapper-customer2021 {
      margin-top: -30px;

      .bkg-overlay-customer {
        top: 18px;
        left: 55px;
        height: 248px;
        width: 445px;
      }
    }
    .play-btn {
      background: url(/themes/custom/axway2020/img/btn-video-play-2021.svg)
        center center no-repeat !important;
      background-size: 47px 47px !important;
      z-index: 100;
      &:hover {
        background: url(/themes/custom/axway2020/img/btn-video-play-2021_hover.jpg)
          center center no-repeat !important;
        background-size: 47px 47px !important;
        z-index: 100;
      }
      &::after {
        display: none !important;
      }
    }

    .video-thumb,
    .video-thumb.customer2021-video {
      background-color: unset;
      background-size: contain;
      box-shadow: unset;
      width: 445px !important;
      margin-left: 40px !important;
      padding-top: 0 !important;
      min-height: 292px !important;
    }
    // Success Story in the banner
    .col-md-7.hero-content.left a.btn-tertiary.left {
      padding-top: 15px !important;
    }
    .hero-content.right {
      z-index: 1;
      left: calc((100vw - 95%) / 2);
      padding-top: 25px;
      .ss-wrapper {
        position: relative;
        width: 276px;
        height: 357px;
        background: center no-repeat;
        margin: unset;
        padding: unset;
        background-size: cover;
      }
      .bkg-overlay-customer2 {
        background: rgba(0, 0, 0, 0.5) !important;
        box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.2);
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .btn-hero {
        margin-bottom: 10px;
        background-color: $light-red-2021;
        border: 1px solid $light-red-2021;
        left: 17% !important;
        margin-right: -50%;
        position: absolute;
        top: 50%;
        z-index: 100;
        &:hover {
          background-color: $dark-red-2021;
          border: 1px solid $dark-red-2021;
        }
      }
    }
    @media (max-width: 767px) {
      background-image: none !important;
      .hero-content.right {
        left: unset;
        .bkg-overlay-customer2 {
          display: none;
        }
        .ss-wrapper {
          background-image: none !important;
          height: unset;
        }
        .btn-hero {
          left: unset !important;
          background-color: $dark-red-2021;
          border: 1px solid $dark-red-2021;
          &:hover {
            background-color: $light-red-2021;
            border: 1px solid $light-red-2021;
          }
        }
      }
    }
  }
  // LEVEL 4
  &.level-4 {
    min-height: 425px !important;
    background-position-x: 100% !important;
    background-position-y: 100% !important;
    background-size: unset !important;
    .hero-content h1 {
      margin-top: 80px;
    }
    .hero-content {
      //margin-top: 100px;
      a.btn-tertiary {
        display: block;
        padding-top: 70px;
      }

      a.btn-tertiary + h1 {
        margin-top: 60px;
      }
    }
    @media (max-width: 992px) {
      min-height: 325px !important;
    }
    @media (max-width: 768px) {
      padding: 70px 0 45px !important;

      .overlay-mobile {
        background: $light-grey-2021 !important;
      }
      .col-sm-6 {
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
    // CUSTOMER PAGE
    h1.customer {
      margin-bottom: 10px;
    }
    p.customer-types {
      font-size: 16px !important;
      font-family: "Roboto Condensed" !important;
      font-weight: bold !important;
      text-transform: uppercase;
      width: unset;
      color: $dark-gray-2024 !important;
    }
    .bkg-overlay-customer {
      background: rgba(0, 0, 0, 0.5) !important;
      top: 16px;
      left: 222px;
      height: 357px;
      width: 276px;
      position: absolute;
      z-index: 1;
      box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.2);
    }
    .video-thumb.customer2021 {
      background-size: 276px 357px;
      background-color: unset !important;
      box-shadow: unset !important;

      .btn-red {
        left: 37% !important;
        margin-right: -50%;
        position: absolute;
        top: 50%;
        z-index: 100;
        background-color: #ff0000;
        border: 1px solid #ff0000;
      }
    }
    .video-wrapper-customer2021 {
      margin-top: 60px;
      .bkg-overlay-customer {
        top: 83px;
        left: 55px;
        height: 248px;
        width: 445px;
      }
      .play-btn {
        background: url(/themes/custom/axway2020/img/btn-video-play-2021.svg)
          center center no-repeat !important;
        background-size: 47px 47px !important;
        z-index: 100;
        &::after {
          display: none !important;
        }
      }
    }
    .video-thumb.customer2021-video {
      background-color: unset;
      background-size: contain;
      box-shadow: unset;
      width: 64.5% !important;
      margin-left: 40px !important;
      padding-top: 0 !important;
      min-height: 292px !important;
    }
  }
  &.press-release {
    h1 {
      font-size: 30px !important;
      line-height: 40px !important;
      padding-right: unset !important;
    }
    @media (max-width: 1230px) {
      background-position-x: 90% !important;
    }
    @media (max-width: 992px) {
      background-position-x: 75% !important;
      h1 {
        font-size: 25px !important;
        margin-top: 20px !important;
      }
    }
    a.btn-tertiary {
      padding-top: unset !important;
    }
  }
}

// ONLY FOR THE HERO OF THE PAGE ABOUT US

// ONLY FOR THE HERO OF THE PAGE ABOUT US
.path-node-209 {
  .play-btn.Red {
    background: url(/themes/custom/axway2020/img/btn-video-play-2021.svg) center
      center no-repeat !important;
    &::after {
      display: none !important;
    }
  }
}

#links {
  margin-bottom: $spacing-xl;

  .container {
    max-width: 1260px;
    width: 93.75%;
  }

  a {
    border: 1px solid $violet-2021;
    color: #353535;
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    max-width: 100%;
    padding: 10px 0 10px 16px;
    position: relative;
    width: 100%;

    &:hover {
      background-color: $violet-2021;
      color: #fff;

      &::after {
        color: #fff;
      }
    }

    &::after {
      content: "\2794";
      color: $violet-2021;
      position: absolute;
      right: 15px;
    }
  }
}

// Form Styling
// ============================================================================================================
form {
  .form-group {
    margin: $spacing-sm 0;
  }

  a {
    border-bottom: 1px solid $dark-grey-2021;
    color: $dark-grey-2021;
    font-size: 13px;
  }

  p {
    font-weight: $font-weight-medium;
    margin-top: 15px;

    a {
      font-size: 15px;
    }
  }

  small {
    margin-bottom: 15px;

    a {
      font-size: 10px;
      font-weight: $font-weight-regular;
    }
  }

  label,
  .mktoForm label {
    color: #181d21;
    font-family: "Roboto", serif;
    font-size: 13px;
  }

  button,
  .mktoForm .mktoButtonWrap.mktoDownloadButton button.mktoButton,
  .mktoButton {
    background-color: #b1001a !important;
    border: 1px solid #b1001a !important;
    font-weight: $font-weight-regular;

    &:hover {
      background-color: red !important;
      border-color: red !important;
    }
  }
}

input[type="text"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
select,
textarea {
  border: none;
  // border-color: #f4f4f4;
  color: #7a929f;
  font-family: "Roboto", serif;
}

.mktoForm {
  .mktoButtonRow {
    padding-left: 2%;
  }

  .mktoFormRow {
    clear: none !important;
    float: left;
    padding-left: 2%;
    width: 47%;
  }
}

.form-wrapper {
  background-color: #fff;
  padding: 30px 15px;

  h3 {
    margin-bottom: $spacing-sm;
  }
}

// ============================================================================================================ *
//                                            USP - SEVERAL TEMPLATES
// ============================================================================================================ *

#usp {
  background-color: $light-grey-2021;
  min-height: 615px;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 466px 345px !important;
  @media (max-width: $xxl) {
    background-image: none !important;
  }
  .container {
    width: 93.75%;
    //padding-top: $padding-top-2021;
    max-width: 1600px;
    //padding-bottom: $padding-bottom-2021;
    //padding-left: 0;
    //padding-right: 0;
    padding: $padding-top-2021 0 $padding-bottom-2021 0;

    .col-md-6.usp-left {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
      padding-left: 0;
      padding-right: 0;

      h2 {
        font-size: 30px;
        font-weight: $font-weight-large;
        line-height: 40px;
        color: $dark-gray-2024;
        padding-left: 110px;
      }
    }
    .col-md-6.usp-right {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
      padding-left: 0;
      padding-right: 0;

      .col {
        background-color: white;
        max-height: 220px;
        max-width: 220px;
        margin-left: 24px;
        background-repeat: no-repeat;
        padding: 0;
      }
      .col.empty {
        background-color: $light-grey-2021 !important;
        @media (max-width: $sm) {
          margin-bottom: 0px;
        }
      }
      .usp-tiles-one,
      .usp-tiles-two {
        display: flex;
      }

      .usp-tiles-two {
        //padding-left: 220px;
        padding-top: 24px;
        @media (max-width: $sm) {
          padding-top: 0px;
        }
      }

      .usp-content {
        //padding: 100px 50px 33px 27px;
        padding: 80px 27px 33px 27px;

        h2 {
          font-size: 16px;
          font-family: "Roboto Condensed";
          font-weight: bold;
          //padding-top: 50px;
          //padding-left: 8px;
          line-height: 24px;
          text-transform: uppercase;
          width: unset;
          color: $dark-gray-2024;
        }

        h3 {
          font-size: 14px;
          font-family: "Roboto";
          font-weight: $font-weight-medium;
          color: $dark-gray-2024;
          //padding-left: 8px;
          line-height: 22px;
        }
      }
    }
  }
}
#usp > div > div > div.usp-tiles-one > div:nth-child(2),
#usp > div > div > div.usp-tiles-one > div:nth-child(5),
#usp > div > div > div.usp-tiles-two > div:nth-child(1),
#usp > div > div > div.usp-tiles-two > div:nth-child(3),
#usp > div > div > div.usp-tiles-two > div:nth-child(4) {
  background-color: $light-grey-2021;
}

@media (max-width: $xxl) {
  #usp > div > div > div.usp-tiles-one > div:nth-child(5),
  #usp > div > div > div.usp-tiles-two > div:nth-child(4) {
    display: none !important;
  }
}

//TEMPLATE #2 #4//

#usp.tmp2, #usp.tmp4 {
  background-color: white !important;
  min-height: 515px;
  // .container {
  //   // max-width: 1440px;
  //   // width: 100%;
  //   // padding: $padding-top-2021 15px $padding-bottom-2021;
  // }
  .col-md-12.usp-left , .col-md-12.usp-left h2{
    font-family: "Roboto",Arial,Helvetica,sans-serif;
    font-weight: 500;
    font-size: 30px;
    font-style: normal;
    line-height: 1.35em;
    text-align: left;
    color: $dark-gray-2024;
  }

  .usp-box {
    display: flex;
    flex-wrap: wrap;
  }
  .usp-box2 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    width: 100%;
    padding-left: 350px;
    padding-top: 30px;
  }

  .field--item.col-md-3 {
    background-color: #494e52;
    max-width: 220px;
    min-height: 220px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .usp-tiles-one {
    font-family: Roboto;
    font-weight: 400;
    font-size: 36px;
    line-height: 50px;
    text-align: left;
    color: white;
    padding-top: 60px;
  }

  .usp-text-one {
    font-family: Roboto;
    font-weight: $font-weight-medium;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    color: #fff;
    min-width: 190px;
  }

  .usp-box2 .field--item.col-md-3 {
    background-color: white;
  }

  .usp-box2 .usp-tiles-one {
    padding-top: 0px;
    color: #016580;
  }

  .usp-box2 .usp-text-one {
    color: $dark-gray-2024;
  }
}

//TEMPLATE #3 - for customers //
#usp.tmp3 {
  background-color: white;
  min-height: unset;
  left: calc(-1 * (100vw - 100%) / 2);
  position: relative;
  width: 100vw;
  .container {
    //width: 100%;
    width: 93.75%;
    padding: 100px 0 80px 0;
    max-width: 1440px;
    .usp-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    h1.customer-title {
      color: $dark-gray-2024;
      font-family: "Roboto", Arial, Helvetica,
      sans-serif;
      font-size: 30px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 1.35em;
      margin-bottom: 10px;
    }
    .field--item.col-lg-6,
    .field--item.col-xl-6 {
    /*.field--item.col-lg-4 {*/
      padding-right: 200px;
      @media (max-width: 1330px) {
        font-size: 20px;
      }
      /*@media (min-width:800px) and (max-width: $lg)  {
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
        padding-right: 15px;
      }*/
      @media (max-width: $lg) {
        padding-right: 5px;
      }
      @media (max-width: $md) {
        padding-right: 5px;
      }
      .customer-quote p {
        color: $dark-gray-2024;
        font-size: 20px;
        font-style: italic;
        font-weight: 400;
        line-height: 1.5;
        @media (max-width: 1330px) {
          font-size: 20px;
          padding-bottom: 20px;
        }
      }
      .customer-contact {
        font-family: Roboto;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        color: $dark-gray-2024;
      }
    }
    .field--item.col-lg-2 {
    /*.field--item.col-lg-3 {*/
      /*max-width: 230px;
      height: 230px;
      background-color: #016580;
      margin-bottom: 15px;
      margin-right: 15px;*/
      padding-left: 0;
      padding-right: 15px;
      /*@media (min-width:800px) and (max-width: $lg) {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
      }*/
    }
    .field--item.col-md-4 {
      justify-content: center;
      display: flex;
    }
    .field--item.col-sm-4 {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }

    .usp-tiles-one h2 {
      font-family: Roboto;
      font-weight: 400;
      font-size: 36px;
      line-height: 50px;
      text-align: left;
      color: white;
      padding-top: 40px;
      margin-left: 15px;
      margin-right: 15px;
      @media (max-width: 1330px) {
        padding-top: 20px;
      }
      @media (max-width: $md) {
        font-size: 30px;
      }
    }
    .usp-tiles-one {
      max-width: 230px;
      height: 230px;
      background-color: #016580;
      @media (max-width: 1330px) {
        height: 200px;
      }
      p {
        color: white;
        margin-left: 15px;
        margin-right: 15px;
        line-height: 30px;
        @media (max-width: 1330px) {
          line-height: 1.5em;
        }
      }
    }
  }
}
// ============================================================================================================ *
//                                            FLOATING HIGHLIGHTS BLOCK
// ============================================================================================================ *
.info-block {
  padding-bottom: 100px;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;
  // display: none;

  &.gray {
    background-color: #f3f3f3;
    background-position: right bottom;
    background-repeat: no-repeat;

    @media (min-width: $lg) {
      background-image: url("/themes/custom/axway2020/img/white-rect_486_222.png");
      // background-size: 90px;
    }

    .hilite {
      div {
        background-color: #fff;
      }
    }

    // REMOVE LATER
    .stat-boxes {
      display: none;
    }
    // REMOVE LATER
  }

  & > .container {
    width: 93.75%;
    @media (min-width: $xl) {
      width: 100%;
    }

    & > .row {
      & > .col-lg-6 {
        @media (min-width: $xl) {
          &:first-child {
            margin-right: -60px;
          }
          &:last-child {
            margin-left: -60px;
          }
        }
      }
    }
  }

  .hilites-right {
    justify-content: flex-end;
  }

  .datapoints {
    margin-bottom: 50px;
    margin-top: 50px;

    @media (min-width: $lg) {
      margin-bottom: 0;
    }

    .row {
      margin-bottom: $spacing-md;

      &:last-child {
        margin-bottom: 0;

        // REMOVE THIS AFTER TUESDAY
        h5 {
          width: 280px;
        }
        // REMOVE THIS AFTER TUESDAY
      }
    }

    .col-lg-6 {
      padding-left: 20px;
      padding-right: 20px;
    }

    .col-lg-7 p,
    h5 {
      color: $teal-2021;
      font-size: 24px;
      // line-height: 1.3;
      font-weight: 500;
    }

    p {
      color: $dark-gray-2024;
      font-size: 18px;
      line-height: 28px;
      font-weight: $font-weight-medium;
      // REMOVE THIS AFTER TUESDAY
      width: 270px;
    }
  }

  .hilite {
    background-color: transparent;
    margin-bottom: 22px;
    // margin-top: $spacing-sm;

    div {
      background-color: #f4f4f4;

      &.image-holder {
        background-size: cover;
        background-position: left bottom;
        background-size: contain;
        min-height: 150px;
        background-repeat: no-repeat;

        @media (min-width: $lg) {
          background-position: initial;
        }
      }
    }

    p {
      color: $dark-gray-2024;
      font-family: "Roboto Condensed", Arial,
        Helvetica, sans-serif !important;
      font-size: 16px;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      padding: 10px 20px;
      text-transform: uppercase;
      min-height: 68px;
    }
  }

  .pullquote {
    p,
    h5 {
      color: $dark-gray-2024;
      font-size: 24px;
      font-style: italic;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .header-text {
    // margin-bottom: $spacing-md;
    margin-bottom: 63px;

    @media (min-width: $lg) {
      // margin-bottom: 60px;
      margin-bottom: 90px;
    }

    &.no-text {
      @media (min-width: $lg) {
        min-height: 240px;
      }
    }

    h2 {
      color: $dark-gray-2024;
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 100px;
      // REMOVE AFTER 1/12
    }

    p {
      color: $dark-gray-2024;
      font-size: 18px;
      line-height: 28px;
      font-weight: $font-weight-medium;
    }
  }

  .stat-boxes {
    background: transparent;
    margin-top: $spacing-lg;
    position: static;

    .container {
      max-width: 100%;

      .row {
        .col-md-3 {
          border-right: none;
          padding: 70px 15px;

          &:first-child {
            display: none;
          }
        }
      }
    }

    .stat-box {
      h2 {
        @media (max-width: $xl) {
          font-size: 38px;
        }
      }
      p {
        font-weight: $font-weight-medium;
      }
    }
  }

  &.right {
    & > .col-lg-6 {
      &:first-child {
        margin-right: -60px;
      }
      &:last-child {
        margin-left: -60px;
      }
    }
  }
}

// ============================================================================================================ *
//                                            FEATURES CAROUSEL
// ============================================================================================================ *
#feature-carousel {
  min-height: 560px;
  // padding-top: $padding-top-2021;
  left: calc(-1 * (100vw - 100%) / 2);
  position: relative;
  padding-bottom: $padding-bottom-2021;
  width: 100vw;

  @media (min-width: $md) {
    left: calc(-1 * (2000px - 100%) / 2);
    width: 2000px;
  }

  h4,
  h2 {
    color: $dark-gray-2024;
    // margin-top: $spacing-md;
    margin-bottom: 20px;
    line-height: 1.3;
    font-size: 32px;
    font-weight: 500;
  }

  p {
    color: $dark-gray-2024;
    font-size: 18px;
    line-height: 28px;
    font-weight: $font-weight-medium;
    margin-bottom: 20px;
  }

  .img-holder {
    display: block;
    max-width: 630px;
    // right: -58px;

    @media (min-width: $xl) {
      max-width: 850px;
      position: absolute;
      right: -51px;
    }

    @media (min-width: $xxl) {
      max-width: 1050px;
    }
  }

  & > .container {
    max-width: 1440px;
    width: 93.75%;
  }

  .slide-contents {
    @media (min-width: $lg) {
      display: flex;
      justify-content: space-between;
      min-height: 550px;
      position: relative;
    }

    div.text-holder {
      @media (min-width: $xl) {
        padding: 0;
        max-width: 30%;
      }
    }

    ul {
      padding-left: 20px;

      li {
        color: $dark-gray-2024;
        font-size: 18px;
        line-height: 28px;
        font-weight: $font-weight-medium;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .slick-dots {
    position: static;
    list-style: none;

    li {
      cursor: pointer;
      height: auto;
      width: 20px;

      @media (min-width: $md) {
        display: inline-block;
        margin: 0 8px 0 0;
      }

      button {
        background-image: url("/themes/custom/axway2020/img/violet_ticker.svg");
        // height: 28px;
        padding: 0;
        // width: 30px;
        height: 10px;
        width: 10px;

        &:before {
          // content: "\25AA";
          // color: $violet-2021;
          // content: "\25A0";
          // font-size: 30px;
          // height: 28px;
          // width: 30px;
          content: "";
        }

        &:hover {
          background-position: -23px 0;
        }
      }

      &.slick-active {
        button {
          background-position: -23px 0;
          // &:before {
          //   color: $violet-2021;
          // }
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    // width: 576px;

    @media (min-width: $lg) {
      // width: 40%;
      width: 44%;
      position: absolute;
      // bottom: 23px;
      // bottom: 37px;
      bottom: 0px;
    }

    .dots {
      @media (min-width: $xl) {
        margin-left: 50px;
      }
    }

    .buttons {
      display: flex;
    }

    .slick-arrow {
      background-image: url("/themes/custom/axway2020/img/violet_buttons.svg");
    }

    .slick-prev,
    .slick-next {
      // height: auto;
      opacity: 0.75;
      position: static;
      transform: none;
      // width: 36px;
      width: 121px;
      height: 122px;

      &:before {
        content: "";
        // background-color: $violet-2021;
        // color: #fff;
        // font-family: "Roboto", sans-serif;
        // font-size: 48px;
        // padding: 11px 11px 14px;
        // padding: 31px 36px;
      }

      &:hover {
        opacity: 1;
      }
    }

    .slick-prev {
      // @media (min-width: $xl) {
      //   margin-right: 36px;
      //   margin-right: 86px;
      // }
    }

    .slick-next {
      background-position: 100% 0;
    }
  }

  .slider-wrapper {
    margin-top: 60px;
    // min-height: 620px;
    min-height: 680px;
    position: relative;

    @media (min-width: $md) {
      max-width: 720px;
    }

    @media (min-width: $lg) {
      // margin-left: 84px;
      margin-top: 90px;
      max-width: 920px;
      // max-width: 2000px;
    }

    @media (min-width: $xl) {
      // margin-left: 84px;
      max-width: 1140px;
      min-height: 630px;
    }

    @media (min-width: $xxl) {
      margin-left: 84px;
      max-width: 2000px;
    }
  }

  .slides {
    // background-color: $light-beige;
    // background: url("/themes/custom/axway2020/img/white-square.jpg");
    // background-position: left bottom;
    // background-repeat: no-repeat;
    // background-size: 90px;
    margin-top: 1px;
    min-height: 560px;

    & > .container {
      background-color: $light-beige;
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: 90px;
      // max-width: 1643px;
      // max-width: 1701px;
      // max-width: 1801px;
      max-width: 1600px;

      @media (min-width: $lg) {
        background-image: url("/themes/custom/axway2020/img/white-square.jpg");
      }
    }
  }
}

// ============================================================================================================ *
//                                            STICKY SUB NAV
// ============================================================================================================ *

#sticky-sub-nav {
  background-color: $light-beige;
  // left: calc(-1 * (100vw - 100%) / 2);
  // left: calc(-1 * (1774px - 100%) / 2);
  left: calc(-1 * (1600px - 100%) / 2);
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  position: relative;
  // width: 100vw;
  // width: 1774px;
  width: 1600px;

  .container {
    // max-width: 1600px;
    max-width: 1440px;
    // width: 93.75%;
  }

  @media (min-width: $lg) {
    padding: 0;
  }

  &.stuck {
    // background-color: $light-beige;
    left: calc(-1 * (100vw - 100%) / 2);
    width: 100vw;
    position: fixed;
    top: 88px;
    z-index: 1;
    // left: calc(-1 * (1790px - 100%) / 2);
    // z-index: 1000;
    // top: 178px;

    @media (min-width: $lg) {
      top: 100px;
    }
  }

  .navbar-collapse {
    // display: flex;
    // flex-direction: column;

    // @media (min-width: $lg) {
    //   flex-direction: row;
    // }
  }

  .navbar-toggler {
    margin-left: 27px;
  }
}

body.toolbar-horizontal.toolbar-tray-open {
  #sticky-sub-nav {
    &.stuck {
      top: 180px;
    }
  }
}

.top-bar-row {
  @media (min-width: $lg) {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding: 14px 15px;
    width: 100%;
  }

  ul {
    margin-top: 2rem;
    margin-bottom: 0;
    padding-left: 3rem;
    // padding-left: 20px;
    // text-align: center;

    @media (min-width: $lg) {
      justify-content: space-between;
      margin-top: 0;
      padding-left: 0;
      text-align: start;
      width: 60%;
    }

    li {
      font-size: 18px;
      font-weight: $font-weight-large;
      list-style: none;
      margin-bottom: 10px;

      @media (min-width: $lg) {
        font-size: 14px;
        margin-bottom: 0;
      }

      a {
        color: #000;
      }
    }
  }

  .btn-red {
    font-size: 16px;
    font-weight: $font-weight-medium;
    margin-left: 3rem;
    margin-right: 0;
    margin-top: 10px;
    // padding-left: 20px;
    padding-left: 15px;

    @media (min-width: $lg) {
      margin-top: 0;
      margin-right: 15px;
      // padding-left: 0;
    }
  }
}

#stickyNavs {
  // padding-left: 27px;
  // margin-top: 15px;
}

.griffin-box-slate {
  .container {
    max-width: 1600px;
  }
}

#video-cta {
  background-color: $dark-grey-2021;
}

.customer-promotion-graph {
  // Be sure to review .stat-boxes .stat-box for any changes.
  padding-bottom: 82px;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;

  &.gray {
    background-color: #f3f3f3;
    margin-top: 100px;
  }

  & > .container {
    // max-width: 1600px;
    max-width: 1440px;
    width: 100%;
  }

  .customer-logo {
    margin-bottom: 40px;
  }

  .stat-box {
    // add to .row for stat box
    margin-top: 54px;

    @media (max-width: $md) {
      text-align: center;
      .field--item.col-md-4:last-child p {
        margin-bottom: 0px;
      }
    }

    .field--item.col-md-4 {
      margin-bottom: 0;
    }

    h2,
    h3 {
      /*margin-bottom: $spacing-sm;*/
      font-size: 30px;
      line-height: 40px;
      font-weight: $font-weight-large;
      color: $teal-2021;

      @media (max-width: $xl) {
        font-size: 48px;
      }
      @media (max-width: $lg) {
        font-size: 45px;
      }
      @media (max-width: $md) {
        font-size: 70px;
      }
      @media (max-width: $sm) {
        font-size: 60px;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: $font-weight-medium;
      color: $dark-gray-2024;

      @media (max-width: $md) {
        margin-bottom: $spacing-lg;
      }
    }
  }

  .text-card-content {
    h2 {
      &.customer-ps {
        // width: 72%;
        width: 67%;
      }
    }

    p {
      color: $dark-gray-2024;
      font-family: Roboto, "sans-serif";
      font-weight: normal;
      // font-style: italic;
      font-size: 24px;
      line-height: 36px;
      text-align: left;
    }
  }
}

.promo-ribbon {
  // align-items: center;
  background-color: #4a4f54;
  // display: flex;
  // height: 233px;
  // height: 333px;
  left: calc(-1 * (100vw - 100%) / 2);
  margin-bottom: 100px;
  // padding: 60px 0 40px;
  padding: 30px 0;
  position: relative;
  width: 100vw;

  @media (min-width: $lg) {
    max-height: 333px;
    padding: 0;
  }

  .container {
    max-width: 1440px;
    width: 100%;
  }

  .promo-text {
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: $lg) {
      align-items: flex-start;
    }

    .btn {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .promo-image {
    align-items: flex-end;
    display: none;
    position: relative;
    flex-direction: row-reverse;

    @media (min-width: $md) {
      display: flex;
    }

    @media (min-width: 1285px) {
      top: -13px;
    }

    @media (min-width: 1332px) {
      top: -28px;
    }
  }

  h2,
  h3 {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    line-height: 1.3;
    margin-bottom: 25px;
    text-align: center;

    @media (min-width: $lg) {
      text-align: left;
    }
  }

  img {
    // position: absolute;
    // top: -80px;
    // top: -142px;
    // top: -139px;
    // top: -143px;
    // max-width: 464px;
    // max-width: 612px;
    // max-width: 664px;
    // height: auto;

    &.img-fluid {
      max-height: 361px;
    }
  }
}

.path-node-1402 {
  .hero-wrapper {
    #hero-2021 {
      background-color: #fff;
      background-position-y: 0px !important;
      box-shadow: none;
      padding: 22px 0 0;

      .hero-content {
        h1 {
          padding-right: 93px;
        }

        p {
          @media (min-width: $lg) {
            max-width: 675px;
          }
        }
      }

      @media (min-width: $lg) and (max-width: $xl2) {
        background-position-y: 0% !important;
      }

      @media (min-width: $xl2) and (max-width: 1600px) {
        background-position-y: 0 !important;
        padding: 0;
      }
    }
  }

  #title-and-body {
    .amplify-bullets {
      margin-bottom: 36px;

      @media (min-width: $lg) {
        margin-top: 125px;
        margin-bottom: 50px;
      }

      ol {
        counter-reset: new-counter;
        list-style: none;
        padding-left: 0;

        @media (min-width: $lg) {
          display: flex;
        }

        li {
          color: $dark-gray-2024;
          counter-increment: new-counter;
          font-size: 18px;
          font-weight: 400;
          height: 190px;
          line-height: 28px;
          margin: 0 0 1.5rem;

          @media (min-width: $sm) {
            height: 134px;
          }

          &:before {
            --size: 31px;
            background: #4a4f54;
            /* font-size: 1.5rem; */
            /*background: #707070;*/
            color: white;
            content: "0" counter(new-counter);
            font-family: "Roboto Mono", sans-serif;
            font-size: 16px;
            font-weight: 400;
            left: calc(-1 * var(--size) + 45px);
            line-height: var(--size);
            padding: 1px;
            position: absolute;
            text-align: center;
            top: -50px;
            width: var(--size);
          }
        }
      }
    }

    .amplify-graphic {
      display: none;

      @media (min-width: $lg) {
        display: block;
      }
    }

    .amplify-graphics {
      @media (min-width: $lg) {
        justify-content: center;
        margin-top: 80px;
      }
    }

    .amplify-mobile {
      display: block;

      @media (min-width: $md) {
        display: none;
      }
    }

    .amplify-tablet {
      display: none;

      @media (min-width: $md) {
        display: block;
      }

      @media (min-width: $lg) {
        display: none;
      }
    }

    .container {
      max-width: 1440px;
      width: 100%;
    }

    .pullquote {
      h5 {
        color: $dark-gray-2024;
        font-size: 32px;
      }

      p {
        font-size: 20px;
        margin-top: 20px;
      }
    }

    .why-amplify-links {
      padding: 40px 0;

      a:nth-child(1) {
        margin-right: 91px;
        @media (max-width: $md) {
          margin-right: 0px;
          display: flex;
        }
      }

      @media (min-width: $lg) {
        display: flex;
        margin-bottom: 80px;
        justify-content: flex-end;
        flex-direction: row;
      }

      @media (max-width: $md) {
        flex-direction: column;
      }
    }

    h2 {
      color: $dark-gray-2024;
      font-size: 30px;
      font-weight: 500;
      margin: 0;
      // margin-bottom: 20px;
      // margin-top: 100px;
    }

    p {
      color: $dark-gray-2024;
      font-size: 18px;
      line-height: 28px;
      font-weight: $font-weight-medium;
      margin-bottom: 80px;
      // padding-left: 37px;
    }
  }

  .info-block {
    .header-text {
      h2 {
        padding-right: 60px;
      }
    }
  }

  .customer-promotion {
    .text-card {
      .text-card-content {
        flex: 0.84 1 auto;

        img.customer-logo {
          object-fit: cover;
          object-position: 50% 50%;
          // width: 101px;
          max-width: 50%;
          height: auto;
        }
      }
    }
  }

  .capability-body {
    background-color: #f3f3f3;
  }
}
// ============================================================================================================ **
//                                            TEXT COLUMNS
// ============================================================================================================ **

#text-columns {
  background-color: #f3ece3;
  width: 100vw;
  left: calc(-1 * (100vw - 100%) / 2);
  position: relative;

  .container {
    padding: $padding-top-2021 15px 0px;
  }

  .left-title {
    background-color: unset;
  }

  .left-title .usp-tiles-title {
    font-family: Roboto;
    font-weight: $font-weight-medium;
    font-size: 32px;
    line-height: 42px;
    text-align: left;
    color: $dark-gray-2024;
  }

  .field--item.col-md-3 {
    max-width: 340px;
    background-color: unset;
    margin-right: auto;
    min-height: 260px;

    .usp-tiles-one {
      font-family: Roboto;

      font-weight: $font-weight-medium;
      font-size: 24px;
      line-height: 32px;
      text-align: left;
      color: $teal-2021;
      padding-top: 0;
      margin-bottom: 20px;
    }

    .usp-text-one {
      font-family: Roboto;
      font-weight: $font-weight-medium;
      font-size: 18px;
      line-height: 28px;
      text-align: left;
      color: $dark-gray-2024;
    }
  }
}
// ============================================================================================================ **
//                                            CTAS 2021
// ============================================================================================================ **

#cta-2021.tmp1 {
  margin-bottom: 30px;

  .container {
    padding: $padding-top-2021 15px 0px;
  }
  .cta2021-title {
    font-family: Roboto;
    font-weight: $font-weight-medium;
    font-size: 32px;
    line-height: 42px;
    text-align: left;
    color: $dark-gray-2024;
    margin-top: 15px;
    margin-bottom: 25px;
    max-width: 345px;
  }
  .cta2021-subtitle {
    font-family: Roboto;
    font-weight: $font-weight-medium;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    color: $dark-gray-2024;
    max-width: 345px;
    a.btn-red {
      margin-top: 50px;
    }
  }
  @media (min-width: 768px) {
    .left-title.col-md-6 {
      -ms-flex: 0 0 40%;
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
  @media (min-width: 768px) {
    .field--item.col-md-6 {
      -ms-flex: 0 0 60%;
      flex: 0 0 60%;
      max-width: 60%;
    }
  }
}

#cta-2021.tmp2 {
  .container {
    padding: $padding-top-2021 15px 0px;
  }
  .cta2021-title {
    font-family: Roboto;
    font-weight: $font-weight-medium;
    font-size: 32px;
    line-height: 42px;
    text-align: left;
    color: $dark-gray-2024;
  }
  .cta2021-subtitle {
    font-family: Roboto;
    font-weight: $font-weight-medium;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    color: $dark-gray-2024;
    max-width: 585px;
    a.btn-secondary {
      margin-top: 50px;
    }
  }
  @media (min-width: 768px) {
    .left-title.col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media (min-width: 768px) {
    .field--item.col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.customer-promotion-multi {
  min-height: 470px;
  padding: 60px 0 100px 0;

  .container {
    width: 100%;
    max-width: 1440px;
  }

  .promotion {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding-left: 0;
    padding-right: 40px;

    @media (max-width: $sm) {
      padding-right: 0;
    }

    @media (max-width: $md) {
      margin-bottom: 22px;
    }

    &:last-child {
      @media (max-width: $md) {
        margin-bottom: 0;
      }
    }

    .promotion-card {
      display: flex;
      // flex: 1 1 auto;
      flex-direction: column;
      padding-left: 20px;
      .img-fluid {
        max-width: 60%;
      }
    }

    .promotion-info {
      font-size: 14px;
    }

    .promotion-title {
      font-family: Roboto, sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.5;
      margin-bottom: 10px;

      a {
        // color: #000;
        color: $teal-2021;
      }
    }

    .promotion-subtitle {
      p {
        // color: #494e52;
        color: $dark-gray-2024;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 25px;
      }
    }

    .promotion-link {
      font-size: 18px;
    }

    .promotion-body {
      flex: 1 1 auto;
      padding: 0;
    }

    .promotion-content {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      padding: 25px 20px 20px;
      position: relative;
    }

    .promotion-image {
      margin-bottom: 0;
    }

    .promo-footer {
      padding: 0;

      // a {
      //   color: #353535;
      // }
    }
  }

  h2 {
    color: $dark-gray-2024;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.3;
    padding-bottom: 15px;
    // padding-left: 15px;
  }

  p {
    color: $dark-gray-2024;
    font-size: 18px;
    line-height: 28px;
    font-weight: $font-weight-medium;
  }
}

.toc {
  background-color: #eceded;
  padding-left: 0;
  // left: calc(-1 * (708px - 100%) / 2);
  // position: relative;
  // min-width: 720px;
  // max-width: none;
  // position: absolute;
  // right: 0;
  height: 506px;

  @media (max-width: $md) {
    position: relative;
    height: auto;
    padding: 20px;
  }

  @media (min-width: $lg) {
    position: absolute;
    right: 0;
  }

  @media (min-width: $xl) {
    max-width: 614px;
  }
}

#toc {
  display: flex;
  flex-direction: column;

  @media (min-width: $xl) {
    // max-width: 43%;
    // --toc-width: 78%;
    // width: calc(78% - 1px);
    max-width: 78%;
  }
  .amplify-badge {
    align-self: flex-end;

    img {
      width: 101px;
      height: 101px;
    }
  }
}

#toc-inner {
  background-color: #fff;
  // align-self: flex-start;
  padding: 22px 30px;

  @media (min-width: $xl) {
    // $toc-width: 78%;
    align-self: flex-start;
    max-width: 78%;
  }

  p {
    color: $dark-gray-2024;
    font-size: 18px;
    line-height: 28px;
    font-weight: $font-weight-medium;
  }

  ul {
    display: block;
    margin-top: 5px;
    padding-left: 0;

    li {
      font-size: 14px;
      margin-top: 11px;

      &.active {
        span {
          font-weight: $font-weight-bold;
        }
      }

      a {
        color: #000;
        font-weight: $font-weight-medium;
      }
    }
  }
}

// 1579 - Why Amplify?
.path-node-1579 {
  #capabilities {
    background-size: 296px 346px;
  }
}

// 1506 - Make the Right Choice
.path-node-1506 {
  #toc-inner {
    width: 78%;
  }
}

///Resources SVG
@font-face {
  font-family: "RobotoMono-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/themes/custom/axway2020/fonts/roboto/roboto-mono-v13-latin-regular.svg#RobotoMono")
      format("svg"); /* Legacy iOS */
}
#resources-new svg {
  font-family: "Roboto Mono";
}

// ====================================
//         Site Alert
// ====================================
#block-sitealert {
  // position: relative;
  // padding-top: 115px;

  // .site-alert-wrapper {
  //   background-color: #d22630;
  // }
  margin-top: 90px;

  &.no-alert {
    .site-alert {
      display: none;
    }

    & + #hero-2021 {
      @media (max-width: $lg) {
        padding-top: 120px;
      }
    }

    & + .hero-lg {
      padding-top: 124px;
    }
  }

  .site-alert {
    // background-color: #d22630;
    // background-color: #b1001a; //red
    background-color: #016580; //teal
    // padding-top: 115px;
    // padding-top: 78px;
    // padding-bottom: 1em;
    // margin-top: 54px;
    // margin-top: 90px;
    // margin-top: 104px;
    //padding: 5px 0;
    padding: 3px 0;

    // @media (max-width: $sm) {
    //   padding-top: 95px;
    // }

    #site-alert-close {
      background-image: url("/themes/custom/axway2020/img/site_alert_close.svg");
      cursor: pointer;
      position: absolute;
      width: 21px;
      height: 21px;
      right: 8px;
      // top: 110px;
      align-self: center;
      background-size: 15px 15px;
      background-repeat: no-repeat;
      margin-top: 6px;

      @media (max-width: $md) {
        // top: 95px;
      }
    }

    .container {
      // max-width: 1260px;
      // width: 93.75%;
      max-width: 1440px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .row {
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
    }

    div.severity-low,
    div.severity-medium,
    div.severity-high {
      background: none;
      background-image: none;
      border: none;
      margin-bottom: 0;

      .text {
        padding: 10px 0;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: #fff;

          @media (max-width: $sm) {
            margin-bottom: 5px !important;
          }
        }
        p {
          color: #fff;
          font-size: 20px;
          font-family: "Roboto", sans-serif;
          // font-weight: 400;
          font-weight: 500;

          a {
            color: #fff;
            font-size: 18px;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
          }
        }

        @media (min-width: $sm) {
          padding-bottom: 10px;
        }
      }
    }
  }

  @media (min-width: $sm) {
    // margin-top: 48px;
    margin-top: 88px;
  }

  @media (min-width: $lg) {
    // margin-top: 63px;
    margin-top: 102px;
  }
}

html[lang="en"] {
  .alert-en {
    display: flex;
  }
  .alert-fr,
  .alert-de,
  .alert-ptbr {
    display: none;
  }
}

html[lang="fr"] {
  .alert-fr {
    display: flex;

    .col-md-10 {
      flex: 0 0 79%;
      max-width: 79%;
    }
  }
  .alert-en,
  .alert-de,
  .alert-ptbr {
    display: none;
  }
}

html[lang="de"] {
  .alert-de {
    display: flex;
  }
  .alert-en,
  .alert-fr,
  .alert-ptbr {
    display: none;
  }
}

html[lang="pt-br"] {
  .alert-ptbr {
    display: flex;
  }
  .alert-en,
  .alert-fr,
  .alert-de {
    display: none;
  }

  body.path-node-159 {
    .customer-promotion .text-card .text-card-content img.customer-logo {
      height: auto;
      object-fit: none;
      object-position: initial;
      width: auto;
    }
  }

  body.path-node-1650 {
    @media (min-width: $lg) {
      #feature-carousel .slide-contents {
        min-height: 875px;
      }
    }
  }

  body.path-node-1385,
  body.path-node-1388,
  body.path-node-1402 {
    @media (min-width: $lg) {
      .top-bar-row {
        justify-content: space-around;
      }
    }
  }
}
// ====================================
//         Lottiefiles
// ====================================

.hero-content h1 sup {
  font-size: 24px;
  left: 1px;
}
.hero-content p sup {
  left: 1px;
  font-size: 11px;
  top: 12px;
}
.axw-lottie {
  width: 1000px;
  height: 1000px;
}
.hplottie {
  /*width: 80%;*/
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: -65px;
  /*left: 400px;*/
  right: 0;
  margin-right: -3px;
}
@media (max-width: 1540px) {
  .hplottie {
    top: -70px;
  }
  .axw-lottie {
    width: 800px;
    height: 800px;
  }
}
@media (max-width: 1200px) {
  .hplottie {
    top: -112px;
    left: 290px;
  }
  .axw-lottie {
    width: 750px;
    height: 750px;
  }
}
@media (max-width: 1104px) {
  .hplottie {
    top: -47px;
    left: 290px;
  }
}
@media (max-width: 992px) {
  .hplottie {
    top: -130px;
    left: 290px;
  }
  .axw-lottie {
    width: 750px;
    height: 750px;
  }
}
@media (max-width: 970px) {
  .hplottie {
    top: 5px;
    left: 240px;
  }
  .axw-lottie {
    width: 600px;
    height: 600px;
  }
}
@media (max-width: 960px) {
  .hplottie {
    top: 33px;
  }
}
@media (max-width: 880px) {
  .hplottie {
    display: none;
  }
  body.path-frontpage #hero .bkg-overlay {
    display: none !important;
  }
}
@media (max-width: 80px) {
  .hplottie {
    top: -100px;
    left: 60px;
    display: block;
    position: relative;
  }
  .axw-lottie {
    width: 600px;
    height: 600px;
  }
  .hero-content h1 {
    margin-top: 0px !important;
  }
}

html[lang="fr"] {
  @media (max-width: 1104px) {
    .hplottie {
      top: -112px;
    }
  }
  @media (max-width: 1047px) {
    .hplottie {
      top: -83px;
    }
  }
  @media (max-width: 992px) {
    .hplottie {
      top: -125px;
    }
  }
  @media (max-width: 970px) {
    .hplottie {
      top: 5px;
    }
  }
}

html[lang="de"] {
  @media (max-width: 1263px) {
    .hplottie {
      top: -58px;
    }
  }
  @media (max-width: 1200px) {
    .hplottie {
      top: -95px;
    }
  }
  @media (max-width: 1124px) {
    .hplottie {
      top: -48px;
    }
  }
  @media (max-width: 1102px) {
    .hplottie {
      top: -20px;
    }
  }
  @media (max-width: 998px) {
    .hplottie {
      top: 10px;
    }
  }
  @media (max-width: 992px) {
    .hplottie {
      top: -105px;
    }
  }
  @media (max-width: 979px) {
    .hplottie {
      top: -70px;
    }
  }
  @media (max-width: 970px) {
    .hplottie {
      top: 60px;
    }
  }
  @media (max-width: 919px) {
    .hplottie {
      top: 85px;
    }
  }
}
html[lang="pt-br"] {
  @media (max-width: 1200px) {
    .hplottie {
      top: -110px;
    }
  }
  @media (max-width: 1200px) {
    .hplottie {
      top: -95px;
    }
  }
  @media (max-width: 1177px) {
    .hplottie {
      top: -46px;
    }
  }
  @media (max-width: 1061px) {
    .hplottie {
      top: -20px;
    }
  }
  @media (max-width: 992px) {
    .hplottie {
      top: 34px;
    }
    .axw-lottie {
      width: 600px;
      height: 600px;
    }
  }
  @media (max-width: 985px) {
    .hplottie {
      top: 60px;
    }
  }
}

// ONLY FOR THE AXWAY and AWS  PAGE
.path-node-930 {
  .slick-slide img.img-fluid {
    max-width: 70% !important;
  }
  .slick-track {
    width: 1440px !important;
  }
  #customer.fullslider {
    padding: 60px 0 100px 0;
  }
}

// ONLY FOR THE Open Banking page
.path-node-867 {
  .slide .img-fluid,
  .img-thumbnail {
    max-width: 70% !important;
  }
  .promo-ribbon {
    margin-bottom: unset;
  }
  #footer {
    margin-top: unset;
  }
  #customer.fullslider {
    padding-top: 50px;
  }
  .video-thumb {
    .play-btn {
      display: block;
      .Red {
        background: url(/themes/custom/axway2020/img/btn-video-play-2021.svg)
          center center no-repeat !important;
        &:after {
          display: none !important;
        }
      }
    }
  }
}

// ====================================
// LIST OF CUSTOMERS
// ====================================
.block-views-blockcustomers-featured-customers .promotion-card .img-fluid {
  height: 90px;
  margin-left: 20px;
}
.view-customers .customer-list-logo {
  display: flex;
  height: 80px;
  margin-bottom: 10px;
  align-items: center;
  /*align-items: end;*/
}
// ====================================
// Content type CUSTOMER
// ====================================
.page-node-type-customer {
  img.customer_logo {
    width: 40%;
  }
  #hero-2021.level-3 .customer-img {
    min-height: 130px!important;
  }
  #hero-2021.level-3 .hero-content.right .bkg-overlay-customer2 {
    background: none!important;
  }
  #capabilities.design9 {
    background-image: none;
    background-color: #F4EDE4;
  }
  #capabilities.design9 .events {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
  }

  #capabilities.design9 .col-lg-3.para p {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: $dark-gray-2024;
  }
  #capabilities.design9 button {
    background-color: unset;
    border: none;
    width: 100%;
    text-align: left;
    margin-left: 30px;
    color: #016580;
  }
  #capabilities.design9 button[aria-expanded="false"]:after {
    background-image: url(/themes/custom/axway2020/img/purple_arrow.svg);
    content: "";
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 20px;
    height: 15px;
    margin-left: 20px;
    vertical-align: middle;
    width: 21px;
  }
  #capabilities.design9 button[aria-expanded="true"]:after {
    background-image: url(/themes/custom/axway2020/img/purple_arrow.svg);
    content: "";
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 20px;
    height: 15px;
    margin-left: 20px;
    vertical-align: middle;
    width: 21px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  #capabilities.design9 .card {
    border: none;
  }
  #capabilities.design9 .card-header {
    background-color: #FFFFFF!important;
  }
  #capabilities.design9 .card-body {
    padding: 5.5rem 0 5.5rem 5.5rem;
    background: #F4EDE4!important;
  }
  #capabilities.design9 #accordion {
    width: 100%;
  }
  #capabilities.design9 h3 {
    margin-bottom: unset;
    min-height: auto;
    color: #016580;
    font-size: 24px;
    line-height: 1.3;
    text-transform: none;
  }
  #capabilities.design9 ul {
    color: $dark-gray-2024;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    font-family: Roboto !important;
  }
  @media screen and (min-width: 768px) {
    #capabilities.design9 h3 {
      padding-right: 30px;
    }
  }
  @media screen and (max-width: 576px) {
    #capabilities.design9 .card-header {
      padding: .75rem 0;
      min-height: 80px;
    }
    #capabilities.design9 button {
      margin-left: 0px;
      color:#016580;
    }
    #capabilities.design9 button h3 {
      white-space: normal;
      margin-right: 20px;
    }
    #capabilities.design9 button[aria-expanded="true"]:after, #capabilities.design9 button[aria-expanded="false"]:after {
      right: 0px;
    }
    #capabilities.design9 .card-body {
      padding: 5.5rem 0.5rem;
    }
  }
  #capabilities.design9 button.btn.btn-link {
    text-decoration: none;
  }
  #resources-new .card-2021 {
    font-family: "Roboto Condensed", Arial, Helvetica, sans-serif !important;
  }
}

// ====================================
// EVENT and WEBINAR REDESIGN
// ====================================
.path-node-110,
.path-node-211,
.path-node-2546 {
  #events {
    background-color: #FFFFFF !important;
  }

  #featured-event {
    display: none !important;
  }

  #events .container {
    max-width: 1460px !important;
  }

  #events .col-lg-4 {
    padding-right: 0px !important;
    padding-bottom: 20px !important;
  }

  #block-axway2020-views-block-events-webinars-block h2.block-title {
    font-family: Roboto;
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    text-align: left;
    color: $dark-gray-2024;
    margin-bottom: 50px;
  }

  #block-axway2020-views-block-events-webinars-block .event-card {
    background-color: #016580 !important;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: unset !important;
    min-height: 222px !important;
    min-width: auto !important;
    overflow: hidden;
    padding: 30px 40px 20px !important;
    position: relative;
    text-decoration: none;
    background-image: url("/sites/default/files/events/webinars.svg");
    background-size: 15%;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  #block-axway2020-views-block-events-events-block .event-card {
    background-color: #016580;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: unset !important;
    min-height: 222px !important;
    min-width: auto !important;
    overflow: hidden;
    padding: 30px 40px 30px !important;
    position: relative;
    text-decoration: none;
    background-image: url("/sites/default/files/events/World-Map.svg");
  }

  .event-title {
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    text-align: left !important;
    color: #fff !important;
    margin-bottom: 20px !important;
    min-height: 125px !important;
  }

  .date {
    font-family: "Roboto Condensed";
    background: unset !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    height: unset !important;
    letter-spacing: 0.01em !important;
    line-height: 24px !important;
    padding: unset !important;
    position: relative !important;
    right: unset !important;
    text-transform: uppercase !important;
    top: unset !important;
  }

  .event-location {
    font-family: Roboto;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    text-transform: capitalize !important;
  }

  #events .view-filters.form-group {
    margin: auto;
    max-width: 100%;
    width: 95%;
  }

  #views-exposed-form-events-events-block,
  #views-exposed-form-events-webinars-block {
    margin: 55px 0px;
  }

  #events .view-filters.form-group {
    width: 98% !important;
  }

  #events .view-filters.form-group .form-inline {
    justify-content: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  #events select[data-drupal-selector='edit-industries'],
  #events select[data-drupal-selector='edit-country'],
  #events select[data-drupal-selector='edit-topic'],
  #events select[data-drupal-selector='edit-field-topic-target-id-selective'],
  #events select[data-drupal-selector='edit-audience'] {
    background-color: #F4F4F4;
    background-position: right 15px center;
    background-size: 15px auto;
    border: unset !important;
    /*border: 1px solid rgba(0, 0, 0, 0.3);*/
    border-radius: 0px;
    box-shadow: none;
    color: #7F8285;
    cursor: pointer;
    font-size: 20px;
    height: 50px !important;
    line-height: 30px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top: 30px;
    max-width: 210px;
    min-width: 195px;
    padding-left: 20px;
    width: 250px;
    margin-left: 20px;
    border-bottom: none;
    font-weight: 400;
    text-align: left;
    text-transform: capitalize;
  }

  button#edit-submit-events--2 {
    display: none;
  }

  .form-inline .form-group {
    margin-right: unset !important;
  }

  #block-axway2020-views-block-events-webinars-block .browse-case,
  #block-axway2020-views-block-events-events-block .browse-case,
  .view-display-id-webinars_block .browse-case,
  .view-display-id-on_demand_block .browse-case {
    display: block !important;
    margin-bottom: -135px;
    text-align: left;
    max-width: 1230px;
    text-transform: uppercase;
    color: $dark-gray-2024;
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-left: 15px;
  }

  .view-filters .form-select {
    background: url(/sites/default/files/events/arrow15.svg) no-repeat;
    background-size: 10px !important;
  }

  span.only_arrow_white {
    background-image: url(/sites/default/files/events/white_arrow.svg);
    content: "";
    display: inline-block;
    float: right;
    height: 15px;
    margin-left: 10px;
    margin-top: 10px;
    vertical-align: middle;
    width: 21px;
  }

  span.ET {
    display: inline-block;
    width: 91%;
  }

  #events select[data-drupal-selector='edit-field-topic-target-id-selective'],
  #on-demand-events select[data-drupal-selector='edit-field-on-demand-topic'] {
    width: 260px !important;
    max-width: 100% !important;
  }

  #edit-reset--4,
  #edit-audience--5 {
    display: none !important;
  }

  .form-actions {
    display: none !important;
  }

  label.control-label.option {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
  }

  .form-checkboxes {
    margin-top: 22px;
  }

  .control-label {
    margin-bottom: 0px !important;
  }

  .checkbox input[type="checkbox"],
  .form-check input[type="checkbox"] {
  }

  .form-checkboxes {
    display: flex !important;
    flex-direction: row !important;
  }

  .checkbox .box, .form-check .box {
    background-color: #F4F4F4;
    border: 1px solid #F4F4F4;
    width: 30px !important;
    height: 30px;
  }

  label.control-label.option {
    display: flex !important;
    font-weight: 400;
  }

  .checkbox input[type="checkbox"]:checked + .box:after, .form-check input[type="checkbox"]:checked + .box:after {
    content: " " !important;
    font-size: 30px;
    font-weight: bold;
    left: 0;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0px;
    background-color: #016580;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(https://www.axway.com/sites/default/files/2022-04/chkbox.svg);
  }

  #on-demand-events {
    background-color: #FFFFFF !important;
  }

  #on-demand-events .container {
    max-width: 1460px !important;
  }

  #on-demand-events .col-lg-4 {
    padding-right: 0px !important;
    padding-bottom: 20px !important;
  }

  #block-axway2020-views-block-events-on-demand-block h2.block-title {
    font-family: Roboto;
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    text-align: left;
    color: $dark-gray-2024;
    margin-bottom: 50px;
  }

  #block-axway2020-views-block-events-on-demand-block .event-card {
    background-color: #016580 !important;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: unset !important;
    min-height: 222px !important;
    min-width: auto !important;
    overflow: hidden;
    padding: 30px 40px 20px !important;
    position: relative;
    text-decoration: none;
    background-image: url("/sites/default/files/events/webinars.svg");
    background-size: 15%;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  #on-demand-events .view-filters.form-group {
    margin: auto;
    max-width: 100%;
    width: 95%;
  }

  #views-exposed-form-events-on-demand-block {
    margin: 55px 0px;
  }

  #on-demand-events .view-filters.form-group {
    width: 98% !important;
  }

  #on-demand-events .view-filters.form-group .form-inline {
    justify-content: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  #on-demand-events select[data-drupal-selector='edit-field-on-demand-topic'] {
    background-color: #FFFFFF;
    background-position: right 15px center;
    background-size: 15px auto;
    border: unset !important;
    /*border: 1px solid rgba(0, 0, 0, 0.3);*/
    border-radius: 0px;
    box-shadow: none;
    color: #7F8285;
    cursor: pointer;
    font-size: 20px;
    height: 50px !important;
    line-height: 30px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top: 30px;
    max-width: 210px;
    min-width: 195px;
    padding-left: 20px;
    width: 250px;
    margin-left: 20px;
    border-bottom: none;
    font-weight: 400;
    text-align: left;
    text-transform: capitalize;
  }

  button#edit-submit-events--4,
  button#edit-submit-events--5,
  button#edit-reset--5 {
    display: none;
  }
  #block-axway2020-views-block-events-webinars-block .event-card.amplify_api_management_platform,
  #block-axway2020-views-block-events-on-demand-block .event-card.amplify_api_management_platform,
  #block-axway2020-views-block-events-events-block .amplify_api_management_platform
  {
    background-color: #D12630!important;
    background-image: url(/sites/default/files/events/amplify_api_management_platform.svg)!important;
    background-repeat: no-repeat!important;
    background-position: bottom right!important;
    background-size: 116px 116px!important;
  }
  #block-axway2020-views-block-events-webinars-block .event-card.b2b_integration,
  #block-axway2020-views-block-events-on-demand-block .event-card.b2b_integration,
  #block-axway2020-views-block-events-events-block .b2b_integration
  {
    background-color: #016580!important;
    background-image: url(/sites/default/files/events/b2b_integration.svg)!important;
    background-repeat: no-repeat!important;
    background-position: bottom right!important;
    background-size: 116px 116px!important;
  }
  #block-axway2020-views-block-events-webinars-block .event-card.managed_file_transfer,
  #block-axway2020-views-block-events-on-demand-block .event-card.managed_file_transfer,
  #block-axway2020-views-block-events-events-block .managed_file_transfer
  {
    background-color: #7E71FF!important;
    background-image: url(/sites/default/files/events/managed_file_transfer.svg)!important;
    background-repeat: no-repeat!important;
    background-position: bottom right!important;
    background-size: 116px 116px!important;
  }
  #block-axway2020-views-block-events-webinars-block .event-card.managed_cloud_services,
  #block-axway2020-views-block-events-on-demand-block .event-card.managed_cloud_services,
  #block-axway2020-views-block-events-events-block .managed_cloud_services
  {
    background-color: #B5B7B9!important;
    background-image: url(/sites/default/files/events/managed_cloud_services02.svg)!important;
    background-repeat: no-repeat!important;
    background-position: bottom right!important;
    background-size: 116px 116px!important;
  }
  #block-axway2020-views-block-events-webinars-block .event-card.open_banking,
  #block-axway2020-views-block-events-on-demand-block .event-card.open_banking,
  #block-axway2020-views-block-events-events-block .open_banking
  {
    background-color: #494E52!important;
    background-image: url(/sites/default/files/events/open_banking.svg)!important;
    background-repeat: no-repeat!important;
    background-position: bottom right!important;
    background-size: 116px 116px!important;
  }
  #block-axway2020-views-block-events-webinars-block .event-card.financial_accounting_hub,
  #block-axway2020-views-block-events-on-demand-block .event-card.financial_accounting_hub,
  #block-axway2020-views-block-events-events-block .financial_accounting_hub
  {
    background-color: #B1001A!important;
    background-image: url(/sites/default/files/events/financial_accounting_hub.svg)!important;
    background-repeat: no-repeat!important;
    background-position: bottom right!important;
    background-size: 116px 116px!important;
  }
  #block-axway2020-views-block-events-webinars-block .event-card.specialized_products,
  #block-axway2020-views-block-events-on-demand-block .event-card.specialized_products,
  #block-axway2020-views-block-events-events-block .specialized_products
  {
    background-color: #7F8285!important;
    background-image: url(/sites/default/files/events/specialized_products.svg)!important;
    background-repeat: no-repeat!important;
    background-position: bottom right!important;
    background-size: 116px 116px!important;
  }
  #footer {
    margin-top: unset!important;
  }
}
//EVENTS
.path-node-110 {
  #on-demand-events {
    display: none!important;
  }
  @media (max-width: 1200px) {
    #events select[data-drupal-selector='edit-industries'],
    #events select[data-drupal-selector='edit-country'],
    #events select[data-drupal-selector='edit-topic'],
    #events select[data-drupal-selector='edit-audience'] {
      width: auto !important;
      min-width: unset !important;
    }
  }
  @media (max-width: 1130px) {
    #hero-2021.level-4 {
      background-position-x: 70% !important;
    }
  }

  @media (max-width: 1010px) {
    #block-axway2020-views-block-events-webinars-block .browse-case,
    #block-axway2020-views-block-events-events-block .browse-case,
    .view-display-id-webinars_block .browse-case,
    .view-display-id-on_demand_block .browse-case {
      margin-bottom: -93px !important;
    }
  }
}

// ====================================
// PRICING PAGE 1331
// ====================================
.path-node-1331 {
  p.form_label {
    margin-bottom: unset !important;
    margin-top: 30px !important;
    font-family: Roboto !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 30px !important;
  }
  .btn-red {
    height: 42px!important;
  }
  .gdpr_message {
    font-size: 12px;
    display: none;
  }
  .row.hp-customers .logo {
    display: flex;
    justify-content: center;
  }
  #email-init2 {
    width: 344px;
    height: 42px;
    background: #fff;
    display: inline-block;
    margin-bottom: unset!important;
  }
  #email-init2::placeholder {
    color: #7F8285!important;
  }
  .customer-promotion-multi .promotion .promotion-card .img-fluid {
    max-width: 100% !important;
  }

  #title-and-body {
    padding: 50px 0 100px !important;
  }

  .row.hp-customers {
    align-items: center !important;
  }

  .customer-promotion-multi {
    padding: 60px 0 10px 0 !important;
  }

  #footer {
    margin-top: unset !important;
  }

  .promo-ribbon {
    margin-bottom: unset !important;
  }

  .capability-body {
    background-color: #F4F4F4 !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #capabilities {
    padding: 90px 0 0 !important;
  }

  .promo-ribbon-email {
    background-color: #7F8285 !important;
    width: 344px;
    height: 42px;
    background: #fff;
    display: inline-block;
    color: #FFFFFF !important;
    margin-bottom: unset!important;
  }

  .promo-ribbon-email::placeholder {
    color: #FFFFFF!important;
    height: 26px;
    font-family: Roboto!important;
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 30px;
    text-align: left;
  }
  .promo-ribbon h3 {
    margin-bottom: 20px!important;
  }
  .promo-ribbon .promo-text .btn {
    height: 42px;
    width: 120px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $dark-gray-2024;
    /*width: 200px;*/
  }
  #GDP2 {
    color: white;
    a {
      color: white;
      text-decoration: underline;
    }
  }

  #hero-2021.level-3 .hero-content h1 {
    margin-top: 85px !important;
    margin-bottom: 30px !important;
  }

  .form_label {
    margin-bottom: unset !important;
    margin-top: 10px !important;
    font-family: Roboto !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 30px !important;
  }
  .form_label_pr {
    margin-bottom: unset !important;
    margin-top: 10px !important;
    font-family: Roboto !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: white;
  }
  form {
    margin: unset !important;
  }

  .GDPM, .GDPM2 {
    min-width: 150px;
    display: block;
    min-height: 65px;
    margin-top: 5px;
  }
  .modal-content {
    h3 {
      font-family: Roboto;
      font-weight: 500;
      line-height: 42px;
      text-align: left;
      color: $dark-gray-2024;
      width: 410px;
    }
  }
  .mktoForm {
    .mktoButton {
      background-color: #b1001a !important;
      border: 1px solid #b1001a !important;
      font-family: Roboto;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #fff;
    }
    .mktoButton:hover {
      background-color: $light-red-2021!important;
      border: 1px solid $light-red-2021!important;
    }
    input, select {
      background-color: #ECEDED;
    }
    input::placeholder, select::placeholder {
      color: #7f8285!important;
    }
    label.mktoLabel {
      font-family: Roboto!important;
      font-weight: normal;
      color: $dark-gray-2024;
      font-size: 14px;
    }
    .mktoAsterix {
      display: none!important;
    }
  }
  .modal .modal-body {
    max-height: 85%!important;
  }
  @media (max-width: 1472px)
  {
    #hero-2021.level-3 .hero-content h1 {
      margin-top: 65px !important;
    }
  }
  @media (max-width: 1375px)
  {
    #hero-2021.level-3 {
      background-position-x: 543px !important;
    }
  }
  @media (max-width: 1293px)
  {
    #email-init2, .promo-ribbon-email {
      width: 230px!important;
    }
  }
  @media (max-width: 1100px)
  {
    #hero-2021.level-3 .hero-content h1 {
      margin-top: 15px !important;
    }
    #hero-2021 .hero-content p {
      padding-right: unset !important;
    }
  }
  @media (max-width: 992px) {
    .promo-ribbon {
      padding: unset !important;
    }
    .promo-ribbon h3 {
      margin-bottom: unset !important;
    }
    .btn-red {
      width: 130px !important;
    }
  }
  @media (max-width: 768px) {
    .btn-red {
      margin-bottom: unset!important;
    }
    #hero-2021 {
      padding: 110px 0 0px!important;
    }
    #hero-2021 .hero-content {
      flex: 0 0 100%!important;
      max-width: 100%!important;
      padding-top: unset!important;
    }
    #hero-2021 .stat-box {
      display: none!important;
    }
    #hero-2021 .overlay-mobile {
      display: none!important;
    }
    .promo-ribbon h3 {
      margin-top: 10px !important;
    }
  }
}


.path-node-1402 {
  @media (max-width: 1362px) {
    #hero-2021 .hero-content h1 {
      margin-right: unset!important;
    }
  }
  @media (max-width: 1200px) {
    span.sndline {
      display: none!important;
    }
  }
}

// ====================================
// SECURETRANSPORT PAGE 242 MFT PAGE 1388 CSOS PAGE 2005 B2B PAGE 2436 AXWAY SUMMIT 2437 AMPLIFY API MANAGEMENT 2500 AMPLIFY API MANAGEMENT 2522 WHY AXWAY 2509
// ====================================
.path-node-242, .path-node-1388, .path-node-2005, .path-node-2436, .path-node-2500, .path-node-2522, .path-node-1402, .path-node-2509 {
  .mktoForm.mktoLayoutAbove .mktoField, .mktoForm.mktoLayoutAbove .mktoLogicalField, input[type="text"] {
    font-weight: 400 !important;
  }

  p.form_label {
    margin-bottom: unset !important;
    margin-top: 30px !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
  }

  #feature-carousel {
    padding-bottom: 50px;
  }

  .btn-red {
    height: 42px !important;
    width: 275px;
  }

  .gdpr_message {
    font-size: 12px;
    display: none;
  }

  .row.hp-customers .logo {
    display: flex;
    justify-content: center;
  }

  #GDP {
    max-width: 460px;
  }

  #email-init2 {
    width: 344px;
    height: 42px;
    background: #ECEDED;
    display: inline-block;
    margin-bottom: unset !important;
    font-weight: 400 !important;
  }

  #email-init2::placeholder {
    color: #7F8285 !important;
  }

  .customer-promotion-multi .promotion .promotion-card .img-fluid {
    max-width: 100% !important;
  }

  .info-block {
    padding-bottom: 50px;

    .header-text {
      margin-bottom: unset !important;
    }

    .col-lg-6.sacs {
      display: none;
    }

    .datapoints p {
      width: 100% !important;
    }
  }

  #title-and-body {
    padding: 50px 0 20px !important;
    padding: 100px 0 0px !important;
  }

  .row.hp-customers {
    align-items: center !important;
  }

  .customer-promotion-multi {
    padding: 60px 0 40px 0 !important;
  }

  #sub-nav {
    left: calc(-1 * (100vw - 100%) / 2) !important;
    padding: 80px 0 80px !important;
    position: relative !important;
    width: 100vw !important;
    background-color: #F4EDE4 !important;
  }

  #footer {
    margin-top: unset !important;
  }

  .promo-ribbon {
    background-color: #016580;
    margin-bottom: unset !important;
    min-height: 350px !important;
  }

  @media (min-width: 768px) {
    /*.promo-ribbon .promo-image {
      top: -1px;
      left: 232px;
    }*/
    .promo-ribbon .promo-image {
      left: unset !important;
      top: unset !important;
      display: block;
    }
  }

  .capability-body {
    background-color: #F4F4F4 !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #capabilities.design9 button {
    min-height: fit-content !important;
  }

  #capabilities {
    padding: 90px 0 0 !important;
  }

  #capabilities.design5 {
    .capability-body {
      background-color: #016580 !important;
    }

    .capability h4 span {
      color: #FFFFFF !important;
      font-weight: 500 !important;
    }
    .capability p {
      color: #FFFFFF !important;
      font-weight: 400 !important;
    }
    .only_arrow {
      background-image: url(/sites/default/files/events/white_arrow.svg) !important;
    }
  }

  #capabilities {
    .col-lg-3.para p {
      color: $dark-gray-2024;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .promo-ribbon-email {
    background-color: #ECEDED !important;
    width: 344px;
    height: 42px;
    background: #fff;
    display: inline-block;
    color: #7F8285 !important;
    margin-bottom: unset !important;
  }

  .promo-ribbon-email::placeholder {
    color: #7F8285 !important;
    height: 26px;
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px;
    text-align: left;
  }

  .promo-ribbon h3 {
    margin-bottom: 20px !important;
  }

  /*#resources-new {
    display: none;
  }*/

  .promo-ribbon-email {
    background-color: #ECEDED !important;
  }

  .promo-ribbon-email::placeholder {
    color: #7F8285 !important;
  }

  .promo-ribbon .promo-text .btn {
    height: 42px;
    width: 120px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $dark-gray-2024;
    /*width: 200px;*/
  }

  #GDP2 {
    color: white;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  span#GDP3 {
    line-height: 20px;
  }

  .promo-ribbon .promo-text .btn {
    width: 300px !important;
  }

  input#email-init3 {
    margin-bottom: 20px;
  }

  #hero-2021.level-3 .hero-content h1 {
    margin-top: 85px !important;
    margin-bottom: 30px !important;
  }

  #hero-2021 .hero-content h1 {
    margin-top: 25px !important;
    padding-right: unset !important;
  }

  #hero-2021 {
    padding: 75px 0 0 !important;
  }

  #hero-2021.level-7 {
    height: 515px!important;
    min-height: unset!important;
  }

  .form_label {
    margin-bottom: unset !important;
    margin-top: 10px !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
  }

  .form_label_pr {
    margin-bottom: unset !important;
    margin-top: 10px !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: white;
  }

  form {
    margin: unset !important;
  }

  .info-block .hilite p {
    padding: 15px 17px !important;
    min-height: 130px !important;
  }

  .GDPM, .GDPM2 {
    min-width: 150px;
    display: block;
    min-height: 65px;
    margin-top: 5px;
  }

  .modal-content {
    h3 {
      font-family: Roboto;
      font-weight: 500;
      line-height: 42px;
      text-align: left;
      color: $dark-gray-2024;
      width: 410px;
    }
  }

  .mktoForm {
    .mktoButton {
      background-color: #b1001a !important;
      border: 1px solid #b1001a !important;
      font-family: Roboto;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #fff;
    }

    .mktoButton:hover {
      background-color: $light-red-2021 !important;
      border: 1px solid $light-red-2021 !important;
    }

    input, select {
      background-color: #ECEDED;
    }

    input::placeholder, select::placeholder {
      color: #7f8285 !important;
    }

    label.mktoLabel {
      font-family: Roboto !important;
      font-weight: 400 !important;
      color: $dark-gray-2024;
      font-size: 14px;
    }

    .mktoAsterix {
      display: none !important;
    }
  }

  .modal .modal-body {
    max-height: 85% !important;
  }

  @media (max-width: 1472px) {
    #hero-2021.level-3 .hero-content h1 {
      margin-top: 65px !important;
    }
  }
  @media (max-width: 1448px) {
    #email-init2 {
      width: 301px !important;
    }
  }
  @media (max-width: 1375px) {
    #hero-2021.level-3 {
      background-position-x: 543px !important;
    }
  }
  @media (max-width: 1350px) {
    #email-init2 {
      width: 275px !important;
    }
    .promo-ribbon-email {
      width: 230px !important;
      margin-top: 10px !important;
    }
    .btn.btn-white {
      margin-top: 10px !important;
    }
  }
  @media (max-width: 1205px) {
    span.sndline {
      display: none;
    }
  }
  @media (max-width: 1119px) {
    .promo-ribbon-email {
      width: 301px !important;
    }
    #hero-2021 video {
      width: 450px !important;
    }

  }
  @media (max-width: 1100px) {
    #hero-2021.level-3 .hero-content h1 {
      margin-top: 15px !important;
    }
    #hero-2021 .hero-content p {
      padding-right: unset !important;
    }
  }
  @media (max-width: 992px) {
    #hero-2021.level-7 {
      height: unset !important;
    }
    .bg-video-wrap:before {
      background-image: url(/sites/default/files/2022-04/new-layer-07.png) !important;
    }
    .hero-video {
      justify-content: center !important;
    }
    #hero-2021 video {
      width: 100% !important;
    }
    .promo-ribbon {
      padding: unset !important;
    }
    .promo-ribbon h3 {
      margin-bottom: unset !important;
    }
    /*.btn-red {
      width: 130px !important;
    }*/
    .info-block .col-lg-6 {
      -ms-flex: 0 0 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  /*@media (max-width: 930px) {
    #hero-2021 video {
      width: 400px !important;
    }
  }*/
  @media (max-width: 768px) {

    .btn-red {
      margin-bottom: unset !important;
    }
    #hero-2021 {
      padding: 110px 0 0px !important;
    }
    #hero-2021 video {
      width: 100% !important;
    }
    #hero-2021 .hero-content {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding-top: unset !important;
    }
    .bg-video-wrap:before {
      display: none !important;
    }
    #hero-2021 .stat-box {
      display: none !important;
    }
    #hero-2021 .overlay-mobile {
      display: none !important;
    }
    .promo-ribbon h3 {
      margin-top: 30px !important;
    }
    .promo-ribbon {
      min-height: 280px !important;
    }
    .customer-promotion-multi {
      left: calc(-1 * (100vw - 100%) / 2);
      padding-bottom: 100px;
      position: relative;
      width: 100vw;

      .col-md-12.para {
        width: 100% !important;
      }
    }
    .info-block {
      padding-bottom: unset !important;
    }
    #uberflip-tiles {
      ul.clearfix {
        display: block !important;
      }

      .tile {
        width: 315px !important;
      }
    }
    .GDPM, .GDPM2 {
      min-height: unset!important;
    }
  }
  @media (max-width: 576px) {
    #uberflip-tiles .tile {
      width: 80% !important;
    }
    #uberflip-tiles ul.clearfix {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    .FC-title {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /*   #hero-2021 video {
         width: 585px;
       }*/
    .info-block.gray {
      background-image: url(/sites/default/files/inline-images/Light-blue2.png) !important;
    }
  }

  .FC-title h2 {
    margin-bottom: 2rem !important;
  }

  .FC-title p {
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    color: $dark-gray-2024;
    width: 40%;
    margin: unset !important;
    padding-bottom: 50px;
  }

  #title-and-body {
    .container {
      max-width: 1440px;
    }

    h2 {
      margin: auto;
      margin-bottom: 5rem;
      color: $dark-gray-2024;
      font-family: "Roboto", Arial, Helvetica, sans-serif;
      font-size: 30px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 1.35em;
    }
  }

  @media (max-width: 280px) {
    .btn-red {
      font-size: 13px;
      margin-top: 10px;
      width: 100%;
      margin-right: unset;
    }
    #title-and-body {
      padding: 30px 0 0px !important;
    }
    #hero-2021 {
      padding: 110px 0 0px !important;

      .play-btn {
        height: 40px;
        width: 40px;
      }

      video {
        height: 50%;
      }

      .bg-video-wrap {
        height: 50%;
      }

      .bg-video-wrap:before {
        font-size: 24px !important;
      }

      .mobile-view-fc .row-mobile {
        padding-bottom: unset;
      }
    }
    .GDPM {
      display: block;
      margin-top: unset!important;
      min-height: unset!important;
      min-width: unset!important;
    }
    .play-container {
      display: none;
    }
    #capabilities .expert .bg-white .capability-body {
      padding: 20px;
      a.clearfix {
        align-items: baseline;
        display: flex;
      }
    }
    .promo-ribbon-email {
      width: 100%!important;
    }
    .promo-ribbon .promo-text .btn {
      width: 100% !important;
      font-size: 13px;
    }
    #capabilities.design5 .capability h4 {
      padding-right: unset;
    }
    #uberflip-tiles {
      max-width: unset !important;
      .tile {
        width: 100% !important;
      }

      ul.clearfix {
        width: unset!important;
      }
    }
    #title-and-body.align-content-center {
      padding: 30px 15px 0px !important;
    }
    #accordion h3 {
      font-size: 22px !important;
    }
  }
}
.path-node-2436 {
  #capabilities {
    padding: 90px 0 50px !important;
  }

  #resources-new {
    display: block!important;

    .card-title span::after {
      display: none !important;
    }
    .card-body {
      min-height: 160px;
    }
  }

  .customer-promotion-multi {
    padding: unset !important;
    left: calc(-1 * (100vw - 100%) / 2);
    position: relative;
    width: 100vw;
    background-color: #F4F4F4;

    .col-md-12.para {
      display: none;
    }

    .promotion .promotion-card {
      justify-content: flex-end;
      min-height: 100px;
    }
  }
  #title-and-body {
    background-color: #F4F4F4 !important;
  }
  .FC-title {
    h2 {
      margin-bottom: 2rem !important;
    }
    p {
      font-family: Roboto;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      text-align: left;
      color: $dark-gray-2024;
      font-weight: normal;
      width: 40%;
      margin: unset !important;
      padding-bottom: 50px;
    }
  }
  .img-capa {
    margin-bottom: 20px;
  }
  .footer-capability {
    display: flex;
    align-items: flex-end;
    flex: auto;
    margin-top: 30px;
  }
  #capabilities.design6 .card-paragraph.p400 {
    display: flex!important;
    flex-direction: column;
    flex: auto;
    color: $dark-gray-2024!important;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  .capabilities-6450 {
    background-color: #f4f4f4!important;
    span.only_arrow {
      display: none !important;
    }
    .capability-body {
      background-color: #ffffff !important;
    }
  }
}
.path-node-2005 {
  .promo-ribbon {
    min-height: 320px!important;
    background-color: #4a4f54!important;
  }
  .promo-ribbon img {
    max-height: 361px!important;
    height: auto!important;
    max-width: 100%!important;
  }
  .promo-ribbon .promo-image {
    top: -28px !important;
  }
}

// ====================================
// PARTNER PAGE
// ====================================
.partner-page {
  p.TPB {
    margin-top: 20px;
    width: 150px;
  }
  .customer-promotion {
    .promo-footer {
      display: none!important;
    }
    .text-card .text-card-content img.customer-logo {
      object-position: unset !important;
    }
  }
  .customer-promotion-graph.gray {
    margin-top: unset!important;
  }
  #capabilities {
    padding: 90px 0 0!important;
  }
  #sub-nav {
    left: calc(-1 * (100vw - 100%) / 2)!important;
    padding: 0 0 80px!important;
    position: relative!important;
    width: 100vw!important;
    background-color: #F4EDE4!important;
  }
  #resources-new .card-body {
    min-height: 180px!important;
  }
}
// ====================================
// Uberflip GWL OB-7939 start here
// ====================================

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
#uberflip-tiles {
  max-width: 1130px !important;
}
#uberflip-tiles .tile {
  width: 250px;
  height: 330px;
}
.tile.single {
  transition: height 0.15s linear;
}
#uberflip-tiles .tile.single .description h3 {
  margin-bottom: 0 !important;
  color: $dark-gray-2024!important;
  margin-left: 20px !important;
  margin-top: 20px !important;
  overflow: hidden;
  font-size: 16px !important;
  align-self: center;
  font-weight: 500 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: "Roboto", sans-serif;
}
body .hubs-embedded .tile.single .description h3 + h4 {
  display: none;
  font-weight: 400 !important;
  margin: 20px !important;
  color: #fff !important;
  font-family: "Roboto", sans-serif;
  margin-top: 20px !important;
}
#uberflip-tiles .tile .item-link {
  display: none;
}
#uberflip-tiles .tile .description::after {
  content: "";
  background: url("https://content.cdntwrk.com/files/aHViPTcwMjM0JmNtZD1pdGVtZWRpdG9yaW1hZ2UmZmlsZW5hbWU9aXRlbWVkaXRvcmltYWdlXzYyZDkzMGVkZTEwOGIucG5nJnZlcnNpb249MDAwMCZzaWc9Yjg1NjE2MmUzNjczYTAwZWY2N2FmOWViOGRhMzkxYjk%253D")
  no-repeat center;
  background-color: #b4b6b8;
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 30px;
  height: 30px;
}
#uberflip-tiles .tile:hover .description {
  background-color: #4a4f54 !important;
  color: #fff;
  height: 330px !important;
  transition: height 0.6s ease;
  bottom: 0;
  left: 0;
}
#uberflip-tiles .tile:hover .description::after {
  background-color: #7e71ff;
}
#uberflip-tiles .tile.single:hover .description h3 {
  display: none;
}
#uberflip-tiles .tile.single:hover .description h4 {
  display: block !important;
  position: static;
  margin-top: 10px;
}
@media (max-width: 1200px) {

  #uberflip-tiles ul.clearfix {
    margin: auto;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width: 768px) {

  #uberflip-tiles ul.clearfix {
    display: block !important;
  }

  #uberflip-tiles .tile {
    width: 315px !important;
  }
}
@media (max-width: 640px) {
  #uberflip-tiles .tile {
    width: 80% !important;
  }
  #uberflip-tiles ul.clearfix {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
}

// ====================================
// Open Banking PAGE 867
// ====================================
//li .amplify-open-banking {
//  margin-left: 10px;
//}
// ====================================
// Amplify Integration PAGE 2522
// ====================================
li .amplify-integration {
  margin-left: 10px;
}
// ====================================
// Axway eInvoicing 2648
// ====================================
li .axway-einvoicing {
  margin-left: 10px;
}

li .axway-b2b-cloud-services {
  margin-left: 10px;
}
// ====================================
// Axway MFT Cloud Services 2752
// ====================================
li .axway-mft-cloud-services {
  margin-left: 10px;
}
li a.menu-third-level{
  margin-left: 10px;
}
// ====================================
// MARKETPLACE PAGE 2329
// ====================================
li .amplify-enterprise-marketplace {
  margin-left: 10px;
}

.path-node-2329 {
  #hero-2021 {
    .hero-content {
      h1 {
        margin-top: unset;
      }
    }
  }

  #title-and-body .container {
    width: 93.75% !important;
    max-width: 1440px;
    margin: auto;

    .features-cta {
      padding: 45px 0 20px !important;

      h2 {
        color: $dark-gray-2024;
        font-size: 30px;
        font-weight: 500;
        margin: 0;
        padding-bottom: 20px;
      }

      p {
        color: $dark-gray-2024;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        max-width: 50%;
        padding-right: 10%;
        text-align: left;
        margin: unset;
      }
    }
  }

  #cta-2021.tmp5 {
    .container {
      width: 93.75% !important;
      max-width: 1440px;
      margin: auto;

      .para h2 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: left;
        color: #016580;
      }
    }
  }

  #capabilities.design1, #capabilities.design5, #capabilities.design9, #resources-new.design3 {
    .container {
      width: 93.75% !important;
      max-width: 1440px;
      margin: auto;

      .para {
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
        }
      }
    }
  }

  #capabilities.design5 {
    padding: 90px 0 0 !important;

    .capability-body {
      background-color: #F3F3F3 !important;
    }
  }

  #capabilities.design9 button {
    min-height: fit-content !important;
  }

  @media (max-width: $xl) {
    #capabilities.design1 {
      padding: 50px 0 40px;

      .col-lg-3.para {
        padding-bottom: 20px;
      }
    }
  }

  @media (max-width: $lg) {
    #title-and-body .container {
      width: 100% !important;
      .features-cta {
        padding: unset !important;
       p {
         max-width: unset;
         padding-right: unset;
       }
      }
    }
  }
  @media (max-width: $sm) {
    #title-and-body .container {
      .features-cta {
        h2 {
          padding-bottom: unset;
        }
      }
    }
  }
}

// ====================================
// AXWAY SUMMITS PAGES 2437 2438 2449
// ====================================

.path-node-2437 , .path-node-2438 , .path-node-2449 {
  #navigation {
    background-color: #f3ece3!important;
    border-bottom: unset!important;
    margin-top: unset!important; /* delete this one if we want to keep the static top bar*/
    position: unset!important; /* fixed if we want to keep the static top bar*/
  }
  #hero-2021.level-7 {
    background-color: #f3ece3;
    padding: 0 !important;/* delete this one if we want to keep the static top bar*/
    height: 441px !important;/* delete this one if we want to keep the static top bar*/

    .hero-content h1 {
      font-size: 48px !important;
      font-weight: 400 !important;
      padding-bottom: 15px!important;
    }

    p.title-sndline {
      font-weight: 400!important;
      font-size: 20px!important;
      color: #006580!important;
      margin-bottom: unset!important;
    }
  }
  .promo-ribbon {
  background-color: #4A4F54 !important;
  margin-bottom: unset!important;
  h2{
    font-size: 32px!important;
    font-weight: 400!important;
   /*margin-right: 250px;*/
    text-align: left;
  }
  .btn-tertiary {
    color: white!important;
    span {
      border-bottom: 1px solid white !important;
    }
    &:after {
      background-image: url("/themes/custom/axway2020/img/arrrow-2021-white.png")!important;
    }
  }
  }
  .mktoForm.mktoLayoutAbove .mktoField, .mktoForm.mktoLayoutAbove .mktoLogicalField, input[type="text"] {
    font-weight: 400 !important;
  }

  p.form_label {
    margin-bottom: unset !important;
    margin-top: 30px !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
  }


  .btn-red {
    height: 42px !important;
    width: 275px;
  }

  .gdpr_message {
    font-size: 12px;
    display: none;
  }



  #GDP {
    max-width: 460px;
  }

  #email-init2 {
    width: 344px;
    height: 42px;
    background: #FFFFFF;
    display: inline-block;
    margin-bottom: unset !important;
    font-weight: 400 !important;
  }

  #email-init2::placeholder {
    color: #7F8285 !important;
  }



  .info-block {
    padding-bottom: 50px;

    .header-text {
      margin-bottom: unset !important;
    }

    .col-lg-6.sacs {
      display: none;
    }

    .datapoints p {
      width: 100% !important;
    }
  }

  #footer {
    margin-top: unset !important;
  }
  #capabilities.design4 {
    .para h2 {
      margin-right: 90px;
    }
    .card-img-top {
      width: 10%!important;
    }
    .capability-body {
      padding: 10px 36px 15px 0px !important;
    }
  }
  .capability-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #capabilities.design9 button {
    min-height: fit-content !important;
  }

  #capabilities {
    padding: 90px 0 0 !important;
  }

  #capabilities.design5 {
    .capability-body {
      background-color: #016580 !important;
    }

    .capability h4 span {
      color: #FFFFFF !important;
      font-weight: 500 !important;
    }
    .capability p {
      color: #FFFFFF !important;
      font-weight: 400 !important;
    }
    .only_arrow {
      background-image: url(/sites/default/files/events/white_arrow.svg) !important;
    }
  }

  #capabilities {
    .col-lg-3.para p {
      color: $dark-gray-2024;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
  }

  #GDP2 {
    color: white;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  span#GDP3 {
    line-height: 20px;
  }



  input#email-init3 {
    margin-bottom: 20px;
  }

  #hero-2021.level-3 .hero-content h1 {
    margin-top: 85px !important;
    margin-bottom: 30px !important;
  }

  #hero-2021 .hero-content h1 {
    margin-top: 25px !important;
    padding-right: unset !important;
  }

  #hero-2021 {
    padding: 75px 0 0 !important;
  }

  #hero-2021.level-7 {
    min-height: unset!important;
  }

  .form_label {
    margin-bottom: unset !important;
    margin-top: 40px !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
  }

  .form_label_pr {
    margin-bottom: unset !important;
    margin-top: 10px !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: white;
  }

  form {
    margin: unset !important;
  }

  .info-block .hilite p {
    padding: 15px 17px !important;
    min-height: 130px !important;
  }

  .GDPM, .GDPM2 {
    min-width: 150px;
    display: block;
    min-height: 65px;
    margin-top: 5px;
  }

  .modal-content {
    h3 {
      font-family: Roboto;
      font-weight: 500;
      line-height: 42px;
      text-align: left;
      color: $dark-gray-2024;
      width: 410px;
    }
  }

  .mktoForm {
    .mktoButton {
      background-color: #b1001a !important;
      border: 1px solid #b1001a !important;
      font-family: Roboto;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #fff;
    }

    .mktoButton:hover {
      background-color: $light-red-2021 !important;
      border: 1px solid $light-red-2021 !important;
    }

    input, select {
      background-color: #ECEDED;
    }

    input::placeholder, select::placeholder {
      color: #7f8285 !important;
    }

    label.mktoLabel {
      font-family: Roboto !important;
      font-weight: 400 !important;
      color: $dark-gray-2024;
      font-size: 14px;
    }

    .mktoAsterix {
      display: none !important;
    }
  }

  .modal .modal-body {
    max-height: 85% !important;
  }

  @media (max-width: 1472px) {
    #hero-2021.level-3 .hero-content h1 {
      margin-top: 65px !important;
    }
  }
  @media (max-width: 1448px) {
    #email-init2 {
      width: 301px !important;
    }
  }
  @media (max-width: 1375px) {
    #hero-2021.level-3 {
      background-position-x: 543px !important;
    }
  }
  @media (max-width: 1350px) {
    #email-init2 {
      width: 275px !important;
    }
  }
  @media (max-width: 1205px) {
    span.sndline {
      display: none;
    }
  }
  @media (max-width: 1119px) {

    #hero-2021 video {
      width: 450px !important;
    }

  }
  @media (max-width: 1100px) {
    #hero-2021.level-3 .hero-content h1 {
      margin-top: 15px !important;
    }
    #hero-2021 .hero-content p {
      padding-right: unset !important;
    }
  }
  @media (max-width: 992px) {
    #hero-2021.level-7 {
      height: unset !important;
    }
    .bg-video-wrap:before {
      background-image: url(/sites/default/files/2022-04/new-layer-07.png) !important;
    }
    .hero-video {
      justify-content: center !important;
    }
    #hero-2021 video {
      width: 100% !important;
    }

    .info-block .col-lg-6 {
      -ms-flex: 0 0 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    #navigation.clear .container .navbar-toggler, #navigation.scroll .container .navbar-toggler {
      display: none!important;
    }
  }

  @media (max-width: 768px) {

    .btn-red {
      margin-bottom: unset !important;
    }
    #hero-2021 {
      padding: 110px 0 0px !important;
    }
    #hero-2021 video {
      width: 100% !important;
    }
    #hero-2021 .hero-content {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding-top: unset !important;
    }
    .bg-video-wrap:before {
      display: none !important;
    }
    #hero-2021 .stat-box {
      display: none !important;
    }
    #hero-2021 .overlay-mobile {
      display: none !important;
    }


    .info-block {
      padding-bottom: unset !important;
    }

    .GDPM, .GDPM2 {
      min-height: unset!important;
    }
    #capabilities {
      padding: 50px 0 0 !important;
    }
    #capabilities.design4 {
      .para h2 {
        margin-right: unset !important;
      }
      .capability h3 {
        padding-right: unset!important;
        width: 100%!important;
      }
    }
  }
  @media (max-width: 576px) {

    .FC-title {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  @media (min-width: 992px) {
    /*   #hero-2021 video {
         width: 585px;
       }*/
    .info-block.gray {
      background-image: url(/sites/default/files/inline-images/Light-blue2.png) !important;
    }
  }

  .FC-title h2 {
    margin-bottom: 2rem !important;
  }

  .FC-title p {
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    color: $dark-gray-2024;
    width: 40%;
    margin: unset !important;
    padding-bottom: 50px;
  }

  @media (max-width: 280px) {
    .btn-red {
      font-size: 13px;
      margin-top: 10px;
      width: 100%;
      margin-right: unset;
    }
    #hero-2021 {
      padding: 110px 0 0px !important;

      .play-btn {
        height: 40px;
        width: 40px;
      }

      video {
        height: 50%;
      }

      .bg-video-wrap {
        height: 50%;
      }

      .bg-video-wrap:before {
        font-size: 24px !important;
      }

      .mobile-view-fc .row-mobile {
        padding-bottom: unset;
      }
    }
    .GDPM {
      display: block;
      margin-top: unset!important;
      min-height: unset!important;
      min-width: unset!important;
    }
    .play-container {
      display: none;
    }
  }
}
.path-node-2437 {
  @media (max-width: 1163px) and (min-width: 993px){
     #hero-2021 video {
      width: 450px!important;
    }
  }
  @media (max-width: 1075px) {
    #hero-2021.level-7 .hero-content h1 {
      font-size: 40px !important;
      margin-top: unset!important;
    }
  }
  @media (max-width: 992px) {
    #hero-2021.level-7 .hero-content h1 {
      padding-right: unset !important;
    }
  }
  @media (max-width: 768px) {
    #hero-2021 video {
      width: 100% !important;
    }
  }
    @media (max-width: 576px){
      #hero-2021.level-7 video {
        height: 280px;
      }
      #hero-2021.level-7 .bg-video-wrap {
        height: 300px;
      }
      #hero-2021.level-7 .hero-content h1 {
        line-height: 50px!important;
      }
    }
}
.path-node-2438, .path-node-2449 {
  #hero-2021.level-7.design8 .hero-video {
    display: flex;
    justify-content: right;
  }
  #hero-2021.level-7.design8 .bg-video-wrap {
    z-index: 100;
    top: -5px;
    &:before {
      background-image: unset !important;
    }

    img {
      filter: drop-shadow(10px 10px 60px rgba(0, 0, 0, 0.2));
    }
  }
  @media (max-width: 1200px) {
    #hero-2021.level-7.design8 .bg-video-wrap {
      top: 15px!important;
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 1075px) {
    #hero-2021.level-7.design8 .hero-content h1 {
      font-size: 40px !important;
      margin-top: unset!important;
    }
  }
  @media (max-width: 992px) {
    #hero-2021.level-7.design8 .hero-content h1 {
      padding-right: unset !important;
    }
  }
  @media (max-width: 768px) {
    #hero-2021.level-7.design8 .hero-video{
      display: none !important;
    }
  }
}
// ====================================
// HOMEPAGE - BADGES - G2
// ====================================
html[lang="en"] {
  .path-node-2095,
  .path-node-2537 {

    #title-and-body .container-fluid.badges-G2 {
      p.bdg-txt {
        font-family: Roboto;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        text-align: center !important;
        color: $dark-gray-2024;
        padding-top: 30px !important;
      }
    }

    #title-and-body:nth-of-type(2) {
      background-color: #B9EDEB !important;
      background-image: unset !important;
      padding-bottom: 100px !important;
    }


    @media (max-width: 768px) {
      #title-and-body {
        background-image: none !important;
      }
    }

    @media (max-width: 576px) {
      #title-and-body {
        padding-right: unset !important;

        .container-fluid.badges-G2 {
          p.bdg-txt {
            text-align: center !important;
          }
        }

        &:nth-of-type(2) {
          padding-bottom: 50px !important;
        }
      }
    }
  }
}
// ====================================
// HOMEPAGE - BANNER - AXWAY SUMMIT (Template 7)
// ====================================

#hero-2021.level-5.axway-summit {
  padding: 0 !important;
  height: 540px !important;
  min-height: unset;
  background-image: url(https://www.axway.com/sites/default/files/axway-summits/AS_background.png) !important;
  background-size: 100% !important;
  background-position: center !important;
  background-color: #F4EDE4 !important;

  .as-logo {
    padding-top: 40px;

    img.img-fluid {
      max-width: 30%!important;
    }
  }
  .as-logo-txt {
    font-weight: 500;
    text-align: center;
    padding: 20px;
  }
  .axway-summit-tile {
    padding: 15px;
    background-color: #FFFFFF;
    margin: 10px;
    .title-as {
      font-weight: 600;
      font-size: 23px;
      color: $dark-gray-2024;
    }
  }
  .row {
    justify-content: center;
  }
  .container-axway-summit {
    width: 80%;
    display: flex;
    padding: 30px;
  }
  a.btn.btn-red.btn-hero {
    width: 20%;
  }
  .col-sm-12.register-axway-summit {
    text-align: center;
  }
  .col-lg-12.as-event {
    padding-top: 20px;
    padding-bottom: 40px;
    h3 {
      font-size: 20px!important;
      color: $dark-gray-2024!important;
    }
    .capability-body {
      padding: 30px!important;
    }
  }
  @media (max-width: 1185px) {
    .capability.flex-column.expert.col-sm-4 {
      height: 150px;
    }
  }
  @media (max-width: 768px) {
    height: 450px !important;
    .container-axway-summit {
      width: 100%;
      display: flex;
      padding: 10px;
      .axway-summit-tile {
        margin: 5px;
        .title-as {
          font-size: 20px;
        }
      }
    }
    .col-lg-12.as-event h3 {
      font-size: 15px !important;
    }
    .col-lg-12.as-event .capability-body {
      padding: 15px!important;
    }
    .col-lg-12.as-event {
      padding: 20px 0 40px!important;
    }
  }
  @media (min-width: 568px) and (max-width: 767px){
      height: 500px !important;
    a.btn.btn-red.btn-hero {
      width: 30%;
    }
  }
  @media (max-width: 576px) {
        height: 600px !important;
        background-position: top center!important;
      .container-axway-summit {
        flex-wrap: wrap;
      }
      a.btn.btn-red.btn-hero {
        width: 100%;
      }
      .axway-summit-tile .title-as {
        font-size: 20px!important;
      }
      .col-lg-12.as-event h3 {
        margin-bottom: unset!important;
      }
      .capability.flex-column.expert.col-sm-4 {
        height: unset;
      }
    }
}
// ====================================
// SPANISH & ITALIAN VERSION
// ====================================
html[lang="es"],
html[lang="it"] {
  #navigation #navbar-nav-wrapper ul.navbar-nav.main .dropdown-menu .dropdown-nav,
  #navigation #navbar-nav-wrapper ul.navbar-nav.main .dropdown-menu:before,
  #footer #block-footer-menu-2021 ul.main.dropdown-nav-column,
  .main .pricing, li.webinars,
  .top-right-menu .about-us,.top-right-menu .partners, .top-right-menu .support, .top-right-menu .sitemap
  {
    display: none!important;
  }
}

// ====================================
// AMPLIFY API MANAGEMENT PAGE 2500
// ====================================
li .amplify-api-management {
  margin-left: 10px;
}
// ============================================================================================================ *
//                                            TOOLTIP
// ============================================================================================================ *
html[lang="en"] {
  .path-node-1331 {
    .tooltip-inner {
      background-color: #fff;
      color: $dark-gray-2024;
      width: 260px;
      height: 72px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      max-width: unset!important;
      padding: 16px!important;
      box-shadow: 0px 8px 16px 0px #00000014;
      border-radius: 8px!important;
    }
    .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
      left: 13px!important;
      top: 8px!important;
      z-index: 1!important;
    }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      border-right-color: #FFF!important;
      border-width: 0.9rem 0.9rem 0.9rem 0!important;
      top: -12px;
    }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      border-bottom-color: #FFF!important;
      border-width: 0 0.9rem 0.9rem 0.9rem!important;
      left: -110px;
    }
    .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
      left: 65px!important;
      top: 8px!important;
    }
    @media (max-width: 480px) {
      .tooltip {
        transform: translate3d(5px, 386px, 0px) !important;
      }
      .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"],
      .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
        left: 20px !important;
        top: 30px !important;
        z-index: 1!important;
      }
      .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before,
      .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before, {
        border-bottom-color: #FFF !important;
        border-width: 0 0.9rem 0.9rem 0.9rem !important;
        left: -110px;
      }
      .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
        height: 0.8rem;
        right: 0;
        width: 0.4rem;
        top: 32px;
      }
      .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
        border-right-color: white;
        border-left-color: transparent;
        border-width: 0 1.2rem 1.2rem 1.2rem;
        left: -255px;
        top: -33px;
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
// ============================================================================================================ *
// 2024 Axway Excellence Awards
// ============================================================================================================ *
.path-node-2661,
.path-node-2662,
.path-node-2663 {
  #hero-2021 {
    &.level-4 {
      min-height: 545px !important;
    }
  &.bkg-gray {
      background-color: #B0001A !important;
    }
  .hero-content
  {
    h1 {
      color: white !important;
      font-size: 48px!important;
      line-height: 60px!important;
      margin-bottom: 40px!important;
      max-width: 500px!important;
      font-weight: 500 !important;
      padding-right: unset!important;
      margin-top: 80px!important;
      }
      p {
        color: white !important;
        font-size: 20px!important;
        line-height: 30px!important;
        max-width: 620px!important;
        }
        .btn-hero {
          background-color: #FFFFFF!important;
          color: $dark-gray-2024!important;
        }
  }
}
  #capabilities.design9 {
    background-color: #016580!important;
    .col-lg-3.para h2 {
      color: #FFF!important;
    }
    .col-lg-3.para p {
        color: #FFF!important;
        font-family: Roboto!important;
        font-weight: 300!important;
        font-size: 18px!important;
        line-height: 28px!important;
    }

    .card-body {
      background-color: #016580!important;
      p,li {
        color: #FFF!important;
      }
    }
  }

  #capabilities.design3 {
    &.light-grey-2021-bg{
      background-color: #F4EDE4!important;
    }

  .mt-auto {
      margin-top: 20px!important;
  }
}

#capabilities .events h4 {
  min-height: 35px!important;
}
.paragraph-team-members .col-lg-4.flex-column {
      margin-bottom: 0px;
      margin-left: 10px;
      margin-right: 10px;
      background-color: #f4f4f4;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 20px;
      flex: 0 0 30%;
  }

#resources-new .catalysts-card {
  .card-author {
        font-weight: bold!important;
        font-size: 16px;
        letter-spacing: 0.01em;
        line-height: 24px;
        text-align: left;
        color: $dark-gray-2024;
        text-transform: uppercase;
        font-family: 'Roboto Condensed';
    }
    .card-body {
      min-height: 260px;
  }
}

  #title-and-body {
  .container {
      max-width: 1440px!important;

      h2 {
        color: $dark-gray-2024;
            font-family: "Roboto",Arial,Helvetica,sans-serif;
            font-size: 30px !important;
            font-style: normal;
            font-weight: 500;
            line-height: 1.35em;
            }
      .col-12 {
        padding-left: unset!important;
        padding-right: unset!important;
        p {
          color: $dark-gray-2024;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
      }
    }

    .mktoForm {
      width: 95%!important;
      display: inline-block;

      span.form-section-title-num {
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        color: #016580;
        background-color: #F4F4F4;
        padding: 10px;
        top: -5px;
        position: relative;
    }

    span.form-section-title-text {
        font-family: Roboto;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: left;
        color: #016580;
        padding-left: 20px;
    }

    .form-section-title {
        left: -70px;
        position: relative;
    }

    hr {
        border-top: 1px solid #7f8184!important;
        margin-top: 30px;
        margin-bottom: 40px;
    }
    .mktoLayoutAbove {
      .mktoField {
      color: #7F8285!important;
      width: 266px;
      height: 26px;
      font-family: Roboto;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      }
      .mktoLabel {
        text-align: left;
        width: 222px;
        height: 38px;
        font-family: Roboto;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        text-align: left;
        color: $dark-gray-2024;
      }
    }
    .mktoRadioList label {
      padding-left: 20px;
      padding-top: 8px!important;
  }

      .mktoButtonWrap.mktoPurpleCandy button.mktoButton {
        background: #B1001A!important;
        border: nond!important;
        border-radius: unset!important;
        font-family: Roboto!important;
        font-weight: 500!important;
        font-size: 16px!important;
        line-height: 21px!important;
        text-align: center!important;
        color: #fff!important;
        width: 190px;
        height: 50px;
        border: none!important;
        text-shadow: none;
    }

    span.mktoButtonWrap.mktoPurpleCandy {
      padding-left: 8px;
  }
  select {
      height: 42px !important;
      line-height: 20px !important;
  }

  input::placeholder {
    width: 266px!important;
    height: 26px!important;
    font-family: Roboto!important;
    font-weight: 400!important;
    font-size: 18px!important;
    line-height: 30px!important;
    text-align: left!important;
    color: #7f8285!important;
}
.form-section-title {
  left: -70px;
  position: relative;
  padding-bottom: 20px!important;
}
label#LblInnovationDescription,
label#LblImprovementsDescription {
      line-height: 20px;
      padding-bottom: 30px!important;
      margin-bottom: 15px!important;
  }
  label#LbltradeshowContent5 {
    height: auto;
}
input[type="radio"] {
  width: auto!important;
}

    }
    p.formprivacy {
      font-size: 12px!important;
      text-align: justify!important;
    }
  }
  }

  #footer {
  margin-top: unset!important;
  }


  .card-body a {
      text-decoration: underline!important;
      color: #FFF!important;
  }

  .row.catalysts-card {
      justify-content: center;
  }
   sup {
      left: 0.1rem!important;
      top: 0.75rem!important;
  }


  @media (max-width: 1200px) {
  #hero-2021.level-4 {
      background-position-x: 170% !important;
    }
  }
  @media (max-width: 1100px) {
      #hero-2021.level-4 { background-position-x: 230% !important;}
  }
  @media (max-width: 992px) {
      #hero-2021.level-4 .hero-content h1 { margin-top: unset!important;}
        #hero-2021.level-4 { background-position-x: 270% !important; min-height: 500px !important;}
        #hero-2021 .hero-content p { padding-right: 0px !important; }
  }
  @media (max-width: 922px) {
  #hero-2021.level-4  {
      background-image: unset!important;
  }
  .col-md-6 {
      -ms-flex: 0 0 100%!important;
      flex: 0 0 100%!important;
      max-width: 100%!important;
  }
  #hero-2021 .hero-content h1 {
      max-width: unset!important;}
      #hero-2021 .hero-content p {
      max-width: unset!important;
      }
  #hero-2021.level-4 {
      min-height: 360px !important;}
  }
  @media (max-width: 768px){
  #hero-2021.level-4 .overlay-mobile {
      display: none!important;
  }
  }
}
// ============================================================================================================ *
// LOGIN CTA
// ============================================================================================================ *
.user-login-form .form-actions {display: block!important;}