.language-switcher-language-url{

    .dropdown-menu{
        background-color: #fff;
    }

    ul{
        padding: 10px 0;
        top: 8px !important;
        border-radius: 5px;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);

        a{
            color: #22272B;
            padding: 5px 15px;
            display: block;
            width: 100%;
            min-width: 200px;
            font-weight: bold;
        }

        li{

            a{
                font-weight: bold;

                &::before{
                    display: none;
                }

                &:hover{
                    background: #f7f6ff;
                    color: #7d79fc;
                }
            }
        }
    }

    .dropdown-toggle{
        background: url("../img/megamenu/global.svg") center center no-repeat;
        font-size: 0 !important;
        height: 46px;
        display: block;
        min-width: 46px;
        .caret-down{
            display: none;
        }
    }

    @media(max-width: 991px){
        .dropdown-toggle,
        .dropdown-toggle:hover{
            background: url(../img/megamenu/global.svg) left center no-repeat !important;
            display: block;
            font-size: 13px !important;
            height: 34px;
            padding: 0 0 0 27px !important;

        }
    }
}

@media(max-width: 991px){


    #search-glass {
        vertical-align: middle;
        margin-right: 6px;
    }
    #navigation #navbar-nav-wrapper .language-switcher-language-url>li>.dropdown-toggle,
    #navigation #navbar-nav-wrapper .top-right-menu>li>.dropdown-toggle{
        align-items: center;
    }

    #navigation.clear .container #navbar-nav-wrapper .navbar-nav,
    #navigation.scroll .container #navbar-nav-wrapper .navbar-nav{
        margin: 10px 0 0;
    }

    #navigation.clear .container #navbar-nav-wrapper .navbar-nav>li .chevron,
    #navigation.scroll .container #navbar-nav-wrapper .navbar-nav>li .chevron{
        display: block !important;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 2;
        cursor: pointer;
        background: none;

        &:after{
            -webkit-font-smoothing: antialiased;
            color: #B4B6B8;
            content: '\f107';
            display: inline-block;
            font: 14px / 1 FontAwesome;
            font-size: 22px;
            margin-top: -11px;
            position: absolute;
            right: 14px;
            text-rendering: auto;
            top: 50%;
            transform: rotate(-90deg);
        }
    }

    #navbar-nav-wrapper #nav-search-close.nav-search-active{
        margin: 0 0 0 20px;
    }

    #navigation.clear .container #navbar-nav-wrapper .navbar-nav>li a:hover,
    #navigation.scroll .container #navbar-nav-wrapper .navbar-nav>li a:hover,
    #navigation.clear .container #navbar-nav-wrapper .navbar-nav>li a,
    #navigation.scroll .container #navbar-nav-wrapper .navbar-nav>li a{
        color: #22272B;
        border-top: 0;
        font-weight: normal;
        justify-content: initial;
        font-family: Roboto, Arial, Helvetica, sans-serif;
    }

    #navigation #navbar-nav-wrapper #block-axway2020-languageswitchercontent .dropdown-menu{
        margin: 0;
        width: 100%;
        box-shadow: none;
    }

    #navigation #navbar-nav-wrapper #block-axway2020-languageswitchercontent .dropdown-menu .dropdown-nav{
        box-shadow: none;
        padding: 0;
    }


    #navigation #navbar-nav-wrapper #block-axway2020-languageswitchercontent ul.dropdown-nav-column{
        padding: 0;
        box-shadow: none;
    }

    #navigation.clear .container #navbar-nav-wrapper .navbar-nav>li .dropdown-menu.show .dropdown-nav .dropdown-nav-column a.language-link,
    #navigation.scroll .container #navbar-nav-wrapper .navbar-nav>li .dropdown-menu.show .dropdown-nav .dropdown-nav-column a.language-link{
        color: #22272B;
        display: block;
        font-weight: bold;
        min-width: 200px;
        padding: 5px 15px 5px 29px;
        width: 100%;
        text-decoration: underline;
        font-size: 13px;
        line-height: initial;
    }

    #navigation #navbar-nav-wrapper #block-axway2020-languageswitchercontent .dropdown-menu:before{
        display: none;
    }

    .st-ui-embed.st-new-embed-container {
        top: 15px !important;
    }
}

#navigation #navbar-nav-wrapper .navbar-nav.language-switcher-language-url{
    margin-right: 0 !important;
}

#navigation #navbar-nav-wrapper .dropdown-menu .dropdown-nav{
    background-color: #fff;
}

#navigation #navbar-nav-wrapper #block-axway2020-languageswitchercontent .dropdown-menu:before{
    background-color: #fff;
    width: 100%;
    opacity: 0;
    height: 31px;
    margin: -17px 0 0;

}

#navigation #navbar-nav-wrapper #block-axway2020-languageswitchercontent .dropdown-menu{
    box-shadow: none;
    background: none;
}

.contact-sales{
    min-width: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding: 7px 14px;
    color: #fff !important;
    font-family: 'Roboto';
    background: #695CFF;
    border-radius: 3px;
    margin: 0px 0px 0px 14px;
    &:hover{
        background: #39328A;
    }
    @media(max-width:991px){
        font-size:  14px;
        font-weight:   600;
        padding: 5px 16px;
        max-width: initial;
        height: auto;
        width: auto;
    }
}

#navbar-nav-wrapper #search-glass.nav-search-active{
    display: none;
}

#navbar-nav-wrapper span#nav-search-close{
    position: relative;
    z-index: 2;
    margin: 0 10px 0 0;
}

#navbar-nav-wrapper img#search-glass{
    position: relative;
    z-index: 2;
    right: 10px;
    top: 2px;
}
/*
#navbar-nav-wrapper span#search-title{
    color: #22272B;
    font-size: 13px;
    font-weight: normal;
    width: 100%;
    padding-left: 0.6rem;
}*/

.user-logged-in .st-ui-container.slide-opened, .user-logged-in .st-ui-container.slide-close{
    display: none;
}

.user-logged-in .st-ui-container, .user-logged-in div.st-ui-container.slide-open, .user-logged-in .st-ui-container.slide-opened, .user-logged-in .st-ui-container.slide-close{
    margin-top: 0px !important;
    right: 26% !important;
    //top: 53px !important;
}


.chat-contact{
    margin: 0 18px 0 0;
    background: url("../img/megamenu/ico-bubble-text.svg") center center no-repeat;
    font-size: 0 !important;
    height: 46px;
    display: block;
    min-width: 46px;
}
// #navigation #navbar-nav-wrapper .navbar-nav.hide{
//     display: block !important;
// }


.st-ui-embed.st-new-embed-container {
    position: relative !important;
    width: 100% !important;
    height: auto;
}

#st-injected-content > .st-default-autocomplete.default-st-style {
    display: none !important;
}
