// HERO STYLES
#hero {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0px 4px 60px #00000008;
  //padding: 70+114px 0 30px;
  background-color: unset; // #1A374E;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: auto;
  color: white;

  .container {
    width: 93.75%;
    max-width: 1260px;

    .row {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  @media (max-width: $lg) {
    padding: $section-padding-sm + 75px 0 $section-padding-sm;
    background-size: cover;
  }

  .bkg-overlay {
    display: none!important;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(
      270deg,
      rgba(16, 21, 24, 0.6) 0%,
      rgba(16, 21, 24, 0.85) 100%
    );
  }
  &.no-overlay {
    .bkg-overlay {
      display: none;
    }
  }

  /*.hero-content {
    z-index: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;

    h1 {
      border-bottom: none;
      margin-bottom: 20px;
      color: $white;
    }
    p {
      font-size: 18px;
      line-height: 32px;
      color: $white;
      font-weight: 300;

      &.subtitle-large {
        font-size: 23px;
      }
    }*/
  .hero-content {
    z-index: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;

    h1 {
      border-bottom: none;
      margin-bottom: 20px;
      color: $gray-dark-2020;
      font-style: normal;
      font-weight: 300;
      //font-size: 40px;
      //line-height: 54px;
      padding-right: 40px;
    }
    p {
      font-size: 18px;
      line-height: 32px;
      color: $gray-dark-2020;
      font-weight: 300;
      font-family: Roboto, Arial, Helvetica, sans-serif!important;

      &.subtitle-large {
        font-size: 23px;
      }
    }

    .btn-hero {
      //margin-top: $spacing-md;
      @media (min-width: $lg) {
        margin-bottom: 10px;
      }
    }
  }
}

.play-container {
  @media (max-width: $lg) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    justify-content: flex-start;
  }
}

.video-text > .play-btn.mobile {
  display: none;

  @media (max-width: $md - 1px) {
    display: flex;
  }
}

.play-btn.mobile {
  display: none;
  background-size: 25px;

  &:after {
    content: "Watch Video";
    bottom: 0;
    color: #fff;
    display: block;
    font-size: 16px;
    margin-bottom: auto;
    margin-left: auto;
    margin-top: auto;
    position: absolute;
    top: 15px;
    bottom: 0;
    margin: auto;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: $lg) {
    display: flex;
    position: relative;
    flex-direction: row;
    //margin-top: $spacing-md;
    width: 130px;
    margin-left: $spacing-md;
    background-position: left;
  }
}

.hero-video-col {
  @include center-child(); // centers child on x and y

  @media (max-width: $lg) {
    margin-top: $spacing-lg;
  }

  @media (max-width: $lg) {
    //display: none;
  }
}

// SMALL HERO
.hero-sm {
  min-height: 400px;
  padding: $spacing-lg + 114px 0 $spacing-lg;
}

// MEDIUM HERO

.col-lg-9.hero-content {
  flex: 0 0 90%;
  max-width: 90%;
}

.hero-md.video-included {
  height: unset !important;
  min-height: 400px !important;

  @media (max-width: $md) {
    height: auto;
  }
}

.hero-md {
  height: unset !important;
  min-height: 360px !important;
  //padding: $spacing-xl + 114px 0 $spacing-xl;
  padding: $spacing-xl + 34px 0 $spacing-md - 5px;
  overflow: hidden;

  @media (max-width: $md) {
    height: auto;
  }
}

.path-node-164 {
  .hero-md {
    min-height: 370px;
    padding: 130px 0px 60px;
  }
}

.path-node-154 {
  .hero-content {
    flex: 0 0 76%;
    max-width: 76%;
  }
}
body.path-frontpage {
  #hero {
    @media (max-width: $lg) {
      padding: 0;
    }
    .hero-content {
      h1 {
        font-weight: 100;
        font-size: 60px;
        line-height: 72px;
        padding-right: 40px;

        @media (max-width: $md - 1px) {
          font-size: 30px;
          line-height: 40px;
        }
      }

    }
  }
  // LARGE HEROES Styles
  .hero-lg {
    // min-height: 590px;
    // padding: $spacing-xl+114px 0 $spacing-xl;
    min-height: 598px;
    padding: 124px 0 50px;
    background-color: white!important;
    background-size: auto!important;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 100px)!important;
    @media (max-width: $md) {
      min-height: 630px;
    }
  }
}

/*body.path-frontpage {
  // LARGE HEROES Styles
  .hero-lg {
    // min-height: 590px;
    // padding: $spacing-xl+114px 0 $spacing-xl;
    min-height: 540px;
    padding: 124px 0 50px;

    @media (max-width: $md) {
      min-height: 630px;
    }
  }
}*/

// LARGE HEROES Styles
.hero-lg {
  min-height: 590px;
  padding: 90px 0px 0px;

  .row {
    align-items: center;

    .video-thumb {
    }
  }

  @media (max-width: $md) {
    min-height: 630px;
  }
}

.hero-large .btn-learn-more {
  margin-top: 60px;
}

// alignment for the second hero button

@media (max-width: $md) {
  .play-container.col-sm-8 .btn.btn-red.btn-hero {
    margin-bottom: 20px;
  }
  .play-container.col-sm-8 {
    align-items: start;
  }
}

// PROMOTION STYLES
#promotions {
  background: #F8F8F8;
  padding: $spacing-md*2 0;
  min-height: 470px;

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1440px;
    width: 93.75%;

    // .row {
    //   align-items: start;
    //   display: flex;
    //   flex-direction: row;
    // }
  }

  .promotion {
    display: flex;
    flex-direction: column;
    // flex: 1 1 25%;
    flex: 1 1 auto;
    padding-left: 0px;
    padding-right: 40px;
    @media (max-width: $sm) {
    padding-right: 0px!important;
  }
    // Special case where we only have 1 promotion.

    .promotion-card {
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
    }
    &.col-md-12 {
      .promotion-title {
        font-size: 24px;
      }

      .promotion-subtitle {
        font-size: 18px;
      }

      .promotion-link {
        font-size: 18px;
      }
    }

    // Special case for 2 promotions.
    &.col-md-6 {
      .promotion-image {
        max-width: 500px;
        height: 280px;
      }
    }

    @media (max-width: $md) {
      margin-bottom: $spacing-sm + 7px;
    }

    &:last-child {
      @media (max-width: $md) {
        margin-bottom: 0;
      }
    }
  }

  .promotion-body {
    flex: 1 1 auto;
    padding: 0px;
  }
  .promotion-content {
    background: #FFFFFF;
    display: -ms-flexbox;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 25px 20px 20px;
    position: relative;
  }
  .promotion-image {
    margin-bottom: 0px!important;
  }

  .promotion-title {
    font-family: Roboto, Arial, Helvetica, sans-serif !important;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    a {
      color: $slate;
    }
  }

  .promotion-subtitle {
    color: #919191;
    margin-bottom: 15px;
    p {

      margin-bottom: 15px;
      font-family: Roboto, Arial, Helvetica, sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: #4A4F54;
      opacity: 0.6;
    }
  }

  .promotion-link {
    align-items: center;
    color: $red-dark;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    font-family: Roboto, Arial, Helvetica, sans-serif !important;

    &:before {
      display: none;
    }
    &:after {
      content: "\203A";
      display: inline-block;
      margin-left: 4px;
      vertical-align: middle;
      font-size: 18px;
      font-family: Roboto, Arial, Helvetica, sans-serif !important;
    }

  }
  .promo-footer {
    padding:0;
  }
}

#hero-2021.level-7 {
  padding: 100px 0 0 !important;
  height: 540px;
  min-height: unset;

  .bkg-tan {
    background-color: #F4EDE4 !important;
  }
  .btn {
    text-transform: unset!important;
  }
  video {
    height: 438px;
    width: 585px;
    object-fit: cover;
  }

  .col-lg-6.col-md-12.hero-video {
    display: flex;
    justify-items: flex-end;
    justify-content: end;
  }

  .bg-video-wrap {
    position: relative;
    height: 440px;
  }

  .bg-video-wrap:before {
    content: '';
    position: absolute;
    background-image: url("/sites/default/files/2022-04/new-layer-06.png");
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bg-video-wrap .play-btn.Red {
    background: url(https://www.axway.com/themes/custom/axway2020/img/btn-video-play-2021.svg) center center no-repeat !important;
    background-size: cover !important;
    left: 50%;
    margin-right: -50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .bg-video-wrap .play-btn.Red::after {
    display: none !important;
  }

  .mobile-video {
    display: none;
  }
  @media (max-width: 1119px) {
     video {
      width: 450px !important;
    }
  }
  @media (max-width: 1100px) {
      .hero-content p, .hero-content h1 {
        padding-right: unset !important;
        max-width: unset !important;
    }
  }
  @media (max-width: 992px) {
    height: unset !important;

    .bg-video-wrap:before {
      background-image: url(/sites/default/files/2022-04/new-layer-07.png) !important;
    }
    .hero-video {
      justify-content: center !important;
    }
    video {
      width: 100% !important;
    }
    .play-container {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 768px) {
    padding: 110px 0 0px !important;

    .btn-red {
      margin-bottom: unset !important;
    }

    video {
      width: 100% !important;
    }
    .hero-content {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding-top: unset !important;
    }
    .bg-video-wrap:before {
      display: none !important;
    }
    .stat-box {
      display: none !important;
    }
    .overlay-mobile {
      display: none !important;
    }
  }
  @media (max-width: 280px) {
    padding: 110px 0 0px !important;
    .btn-red {
      font-size: 13px;
      margin-top: 10px;
      width: 100%;
      margin-right: unset;
    }
    .play-btn {
      height: 40px;
      width: 40px;
    }

    video {
      height: 50%;
    }

   .bg-video-wrap {
      height: 50%;
    }

  .bg-video-wrap:before {
      font-size: 24px !important;
    }

  }
}
