#block-axway2020-menulogin{
    width: 100%;
}

.menu.nav.menu-login{
    background: #000000;
    display: flex;
    align-content: end;
    justify-content: end;
    gap: 15px;
    padding: 0 15px;
    height: 36px;

    *{
        font-size: 14px;
        font-weight: 500;
    }

    >li{
        padding: 8px 0;
        >a{
            color: #fff;
            font-weight: bold;
            background: none;
            padding: 0 10px;

            &:after{
                display: none;
            }
        }
    }

    ul{
        padding: 10px 0;
        top: 8px !important;
        border-radius: 6px;
        transform: translate3d(-145px, 18px, 0px) !important;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);

        a{
            color: #22272B;
            padding: 5px 15px;
            display: block;
            width: 100%;
            min-width: 200px;
            font-weight: bold;
        }

        li{

            &:hover{

                a{
                    background: #f7f6ff;
                    color: #7d79fc;
                }
            }
        }
    }


    @media(max-width: 991px){
        align-content: start;
        flex-direction: initial;
        justify-content: start;
        background: none;
        padding: 0;
        gap: 0;

        >li{
            width: 100%;
            padding: 0px;

            >a{
                color: #22272B;
                font-weight: normal;
                background: none;
                font-size: 13px;
                width: 100%;

                span{
                    display: block !important;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    right: 0;
                    top: 3px;
                    z-index: 2;
                    cursor: pointer;
                    border: none;

                    &:after{
                        -webkit-font-smoothing: antialiased;
                        color: #B4B6B8;
                        content: '\f107';
                        display: inline-block;
                        font: 14px / 1 FontAwesome;
                        font-size: 22px;
                        margin-top: -11px;
                        position: absolute;
                        right: 14px;
                        text-rendering: auto;
                        top: 50%;
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        a{
            color: #22272B;
            font-weight: bold;
            padding: 10px 0;
            text-decoration: underline;
            font-size: 13px;
        }

        ul{
            width: 100%;
            position: relative;
            transform: none !important;
            border: none;
            box-shadow: none;

            li{
                a{
                    padding: 5px 15px 5px 27px;
                }

                &:hover{

                    a{
                        background: none;
                        color: #22272B;
                        padding: 5px 15px 5px 27px;
                    }
                }
            }
        }

        >li:nth-child(2){
            >a{
                padding: 15px 0 15px 27px;
                background: url(../img/megamenu/user.svg) left center no-repeat;
                display: block;
                height: 34px;
                padding: 0 0 0 27px;
                align-items: center;
                display: flex;
            }
        }

        a[href="https://support.axway.com"]{
            padding: 15px 0 15px 27px;
            background: url(../img/megamenu/support.svg) left center no-repeat;
            display: block;
            height: 34px;
            padding: 0 0 0 27px;
            align-items: center;
            display: flex;
        }
    }

}

@media(max-width: 991px){
    #navigation #navbar-nav-wrapper .menu-login .dropdown-menu.show{
        margin: 0 0 10px;
        margin-top: 0 !important;
    }
}

// extra
#navigation{
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .dropdown-menu {
        border: none !important;
    }
}