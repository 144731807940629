//
// Lato Light
// http://www.latofonts.com/
// ------------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}/lato-light.eot');
  src: local('Lato Light'),
       local('Lato-Light'),
       url('#{$font-path}/lato-light.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/lato-light.woff2') format('woff2'),
       url('#{$font-path}/lato-light.woff') format('woff'),
       url('#{$font-path}/lato-light.ttf') format('truetype'),
       url('#{$font-path}/lato-light.svg#lato') format('svg');
  font-weight: 300;
  font-style: normal;
}
