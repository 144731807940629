#navigation.scroll #block-axway2020-menumain .dropdown-menu,
#navigation.scroll #block-axway2020-menumaines .dropdown-menu,
#navigation.scroll #block-axway2020-menumainit .dropdown-menu {
    margin-top: 0 !important;
}

#navigation #block-axway2020-menumain .dropdown-menu,
#navigation #block-axway2020-menumaines .dropdown-menu,
#navigation #block-axway2020-menumainit .dropdown-menu {
    margin-top: 0 !important;
}



.menu-text {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: #22272B;
    font-size: 14px;
    vertical-align: middle;
}

#block-axway2020-menumain,
#block-axway2020-menumaines,
#block-axway2020-menumainit {

    @media(min-width: 992px) {

        * {
            font-family: 'Roboto';
        }
        .we-mega-menu-ul>.we-mega-menu-li>a:focus-visible{
            outline: 2px solid #dfdfff !important;
                background-color: #f0f0f0 !important;
        }
        a,
        span {
            color: #22272B;
            font-weight: bold;
            line-height: 16px;
            padding: 0 15px;
            white-space: nowrap;
            padding: 0 15px;
            border-bottom: none;
            background: none;
            font-size: 14px;
            width: 100%;
            font-weight: 700;
            font-family: 'Roboto';
            text-transform: initial;

            &::before {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 2px;
                vertical-align: middle;
                border-top: 4px dashed;
                border-top: 4px solid\9;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                right: 0;
                color: #B4B6B8;
            }
        }


        li {
            z-index: 2;
            width: 100%;
            border: none;
        }

        .we-mega-menu-group {
            a {
                white-space: initial;
                margin: 5px auto;
                padding: 0;

                &:before {
                    display: none;
                }
            }

            span {
                margin: 10px auto;
            }

            >a,
            >span {
                color: #7F8184;
                margin: -1px 20px;
                font-size: 16px;
                line-height: 1.4;
                padding-top: 20px;
            }

            ul {
                a {
                    text-decoration: underline;
                }
            }
        }

        .dropdown-menu.show {
            display: flex !important;
            border: none;
            margin-top: 21px !important;

            a,
            span {
                font-size: 14px;
            }

            &::after {
                display: block;
                position: fixed;
                background: #fff;
                width: 100%;
                height: 200px;
                content: '';
                left: 0;
                box-shadow: 0px 50px 60px rgba(0, 0, 0, 0.1);
            }

            a {
                text-decoration: underline;
            }


            span {
                color: #4A4F54;
                opacity: 0.7;
            }

            .dropdown-menu {
                flex-direction: column;
                display: flex;
                border: none;
                margin-top: 0 !important;

                li {
                    height: 32px;
                }
            }

            li {
                &::marker {
                    display: none;
                    font-size: 0;
                }
            }
        }

        .we-mega-menu-li {
            >.description {
                display: none;
            }
        }

        .group-info {
            >.description {
                // https://axwp.teamwork.com/app/tasks/36811475 max-width: 236px;
                padding: 5px 0 0 18px;
                order: -1;
                margin: 0 auto 0 0;

                h5 {
                    color: #22272B;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 28px;
                }

                p {
                    color: #7F8184;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    padding: 5px 0;
                }

                .btn-more {
                    text-decoration:none;
                    font-size: 14px;
                    color: #fff;
                    background: #695CFF;
                    width: 115px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    margin: 0 0 20px 0;
                    width: max-content;
                    padding: 7px 14px;
                    gap: 5px;


                    &:hover{
                        background: #39328A;
                        &:before{
                            transform: rotate(0deg);
                        }

                    }
                }
            }
        }

        .we-mega-menu-row {
            margin: 0 auto;
            max-width: 1440px;
            gap: 15px;
            flex-wrap: nowrap;
            padding: 0 15px 0 0;


            .we-mega-menu-row {
                box-shadow: none;
                border-top: none !important;
                padding: 0;
                max-width: 1108px;
                margin: 0px;
            }

            >.description {
                max-width: 300px;
                padding: 30px 0 30px 30px;
                order: -1;
                margin: 0 0 0 0;

                h2 {
                    color: #22272B;
                    font-size: 20px;
                    font-weight: bold;
                    margin: 0 0 10px;
                    line-height: 28px;
                }

                h5 {
                    color: #22272B;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 28px;
                }

                p {
                    color: #7F8184;
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    padding-bottom: 8px;
                }

                .btn-more {
                    text-decoration: none;
                    font-size: 14px;
                    color: #fff;
                    background: #695CFF;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    margin: 0 0 20px 0;
                    padding: 7px 14px;
                    width: max-content;

                }

            }
        }

        a,
        span {
            width: 100%;
            background: none;
            font-size: 14px;
            &::before{
                transform: rotate(0deg);
            }
        }
        .clicked {
            z-index: 99;
        }
        .we-mega-menu-submenu .we-megamenu-nolink:hover{
            color: #7F8184;
            cursor: default;
        }
        a:hover, span:hover {
            color: var(--Violet---600, rgba(105, 92, 255, 1));
            &::before{
                transform: rotate(0deg);
            }
        }

        .menu--menu-main {
            display: flex;
            flex-wrap: nowrap;
        }

        .navbar-we-mega-menu {
            background: none;
            padding: 0 15px 0 0;
            margin: 0;
        }

        // .we-mega-menu-submenu-inner{
        //     position: fixed;
        //     left: 0;
        //     top: 264px;
        //     width: 100vw !important;
        //     background: white;
        // }

        #navigation #navbar-nav-wrapper .dropdown-menu {
            position: initial;
        }

        .group-two {
            .navbar-we-mega-menu .we-mega-menu-group>.we-mega-menu-submenu {
                display: none !important;
            }

            .we-mega-menu-col {
                position: relative;

                .nav-tabs {
                    width: calc(20% + 10px);
                }

                .we-mega-menu-li {

                    &.headline-title {
                        order: -1;
                        .we-megamenu-nolink {
                            color: #7F8184;
                            font-size: 16px;
                            line-height: 1.4;
                            &:hover {
                                background: none;
                            }
                        }
                    }

                    .we-mega-menu-li, .we-megamenu-nolink {
                        width: 100%;
                        padding: 8px 18px;
                        background: none;
                        width: 100%;
                        color: #22272B;
                        font-size: 14px;
                        border-radius: 3px;
                        &:hover {

                            background: #F4F6FF;
                        }


                        .we-mega-menu-li {
                            padding: 0 !important;
                            width: max-content;
                            margin: 0 auto 0 0;
                        }
                    }
                    .we-mega-menu-li {
                        a.we-mega-menu-li, .we-megamenu-nolink {
                            font-weight: bold;
                        }
                    }
                    .nav-tabs {
                        width: 100%;
                        .we-mega-menu-li, .we-megamenu-nolink {
                            &:hover {
                                color: #695CFF;
                                background: none;
                            }
                        }
                    }
                }
            }

            ul {
                flex-direction: column;
//activar el active cambiando hover por active
                .we-mega-menu-li {
                    /* &:hover {
                        >.we-mega-menu-submenu {
                            display: block !important;

                        }
                    } */
                     &.click-to {
                        >.we-mega-menu-submenu {
                            display: block !important;
                        }
                     }
                     >.we-mega-menu-submenu:nth-child(1) {
                        display: block;
                    }
                    >a {
                        width: 25%;
                        margin: 5px 0;
                        font-weight: normal;
                    }

                    &.group-two-link.we-mega-menu-group>a {

                        &:after {
                            content: '\f107';
                            display: inline-block;
                            font: 14px / 1 FontAwesome;
                            text-rendering: auto;
                            font-size: 22px;
                            color: #B4B6B8;
                            transform: rotate(-90deg);
                            width: 15px;
                            height: 16px;
                            opacity: 1;
                            top: 9px;
                            margin-top: 0;
                            right: 15px;
                            background-image: none;
                        }
                    }
                    &:first-child > .we-mega-menu-submenu {
                        height: 361px;
                    }
                    >.we-mega-menu-submenu {

                        width: calc(77% - 10px);
                        position: absolute;
                        left: calc(22% + 10px);
                        display: none !important;
                        border-left: 1px solid #EBECEC !important;

                        a {

                            &:after {
                                display: none;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    position: initial;
                }
                >.we-mega-menu-li{
                    display: flex;
                    min-width: 100%;
                    ul .we-mega-menu-li {
                        min-width: initial;
                    }
                }
            }
        }

        a.collapsed.navbar-toggle {
            display: none;
        }



        li.we-mega-menu-li.dropdown-menu.group-two.click-to >a {
            color: #695cff;
            &:before {
                transform: rotate(180deg);
            }
        }


        //news
        .group-one {
            ul ul.subul{
                gap: 16px 0;
            }
        }
        .navbar-we-mega-menu .group-one .we-mega-menu-col {
            margin: 0 10px 0px 0px;
            padding: 14px 0px;
            max-width: 200px;
        }
        .group-one-link .we-mega-menu-group ul a {
            margin: 8px auto;
        }
        .navbar-we-mega-menu .we-mega-menu-col {
            max-width: initial;
            width: 100%;
            flex: auto;
            margin: 0px auto 0 0px;
            padding: 30px 0;
            &.menu-columns > ul{
                max-height: 200px;
                flex-wrap: wrap;
                gap: 0px;
                width: max-content;
                li.we-mega-menu-li{
                    width: max-content;
                }
            }

            .navbar-we-mega-menu .we-mega-menu-col {
                width: 100%;
                padding: 20px 0 0;
            }
        }

        .navbar-we-mega-menu .we-mega-menu-col.block-news {
            margin: 0px 0px 0px auto;
            min-width: max-content;
        }

        .navbar-we-mega-menu .we-mega-menu-col.one-row-block {
            margin: 0px !important;
            min-width: max-content;
        }

        .highlights {
            display: flex;
            gap: 40px;
            justify-content: end;

            img {
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 0;
                object-fit: cover;
                object-position: top;
            }

            .field-image {
                max-height: 115px;
                overflow: hidden;
                position: relative;
                height: 115px;
                width: 100%;
            }


            .rows {
                width: 220px;
                min-width: 220px;
                box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
                padding: 0;
                border-radius: 5px;
                overflow: hidden;
                height: 241px;
            }

            h2 {
                color: #7F8184;
                font-size: 10px;
                font-weight: bold;
                letter-spacing: 1px;
                margin: 0;
            }

            a {
                color: #695cff;
                padding: 0;
                font-size: 12px;
                text-decoration: none;

                &:hover {
                    color: #39328a;
                }
            }

            p {
                font-size: 12px;
                line-height: 16px;
                color: #22272B;
                font-weight: bold;
                margin: 8px 0;
                text-overflow: ellipsis;
                max-height: 48px;
                overflow: hidden;
                white-space: inherit;
            }

            .inf {
                padding: 15px 20px 20px;
            }
        }

        .we-mega-menu-submenu {
            margin-top: 30px;
            padding: 0;
            border: none;
        }

        .we-mega-menu-ul {
            flex-wrap: nowrap;
            display: flex;
            align-items: self-end;
            gap: 25px;
        }

        .we-mega-menu-submenu>.we-mega-menu-submenu-inner {
            position: fixed;
            left: 0;
            background: white;
            width: 100%;
            box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.12);
            min-height: 233px;

            .we-mega-menu-submenu>.we-mega-menu-submenu-inner {
                position: relative;
                box-shadow: none;
                min-height: initial;
                margin: 0 20px;
            }
        }

        .dropdown-menu.group-two.click-to,
        .dropdown-menu.group-one.clicked {

            .we-mega-menu-submenu {
                visibility: visible;
                opacity: 1;

                >.we-mega-menu-submenu-inner {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
       .group-two ul .we-mega-menu-li.clicking{
            >.we-mega-menu-submenu {
                visibility: visible !important;
                opacity: 1 !important;
                display: block !important;

                >.we-mega-menu-submenu-inner {
                    visibility: visible !important;
                    opacity: 1 !important;
                    display: block !important;
                }
            }
        }

    }

    // @media(max-width: 992px){
    //     background: #fff;

    //     a,span{
    //         padding: 0;
    //     }

    //     .expanded{
    //         li.expanded.dropdown{
    //             border-bottom: 1px solid #4A4F54;
    //             padding: 0 0 20px;

    //         }

    //         .dropdown-menu.show{
    //             margin-top: 0 !important;
    //         }
    //     }

    //     .dropdown-menu.show{
    //         flex-direction: column;
    //         position: relative;
    //         width: 100%;

    //         ul{
    //             flex-direction: column;
    //             position: relative;
    //             width: 100%;
    //         }


    //         &:after{
    //             display: none;
    //         }
    //     }

    //     .menu--menu-main{
    //         flex-direction: column;
    //         padding: 0 15px;
    //     }
    // }


    // @media(max-width: 992px){

    //     // .collapsed{
    //     //     display: none;
    //     // }

    //     // .navbar-we-mega-menu{
    //     //     display: block !important;
    //     //     position: relative;
    //     //     width: 100%;
    //     //     right: 0;
    //     //     padding: 0;
    //     // }


    //     // .we-mega-menu-ul{
    //     //     width: 100%;
    //     // }

    //     // .we-mega-menu-row{
    //     //     padding: 0;
    //     // }

    // }


}

.back {
    display: none;
}

.we-megamenu-nolink.no-href {
    a {
        cursor: default;
        text-decoration: none;
        color: inherit;
        pointer-events: none;
    }

  }

@media(max-width: 991px) {

    .back {
        display: block;
    }
    .hide {
        display: none !important;
    }
    .region-we-mega-menu {
        background: none;
        padding: 0;
    }

    #navigation {
        >.container {
            width: 100%;
            //padding: 11px 15px;
        }
    }

    #navigation.clear .container #navbar-nav-wrapper.show,
    #navigation.scroll .container #navbar-nav-wrapper.show {
        position: relative;
        display: block !important;
        margin-top: 0 !important;
        width: 100%;
        right: 0;
        left: 0;
    }

    .navbar-we-mega-menu.navbar .dropdown-menu.active-trail>.we-mega-menu-submenu,
    .navbar-we-mega-menu.navbar .dropdown-menu.active>.we-mega-menu-submenu {
        display: none;
    }

    #block-axway2020-menumain, #block-axway2020-menumaines, #block-axway2020-menumainit {

        ul {
            width: 100%;
            padding-top: 0px;
        }

        .navbar-toggle.collapsed {
            display: none;
        }

        .navbar-we-mega-menu {
            overflow-y: visible;
            left: 0;
            width: 100%;
            background: white;
            display: block;
            position: relative;
            padding: 0;
        }

        a,
        span {
            color: #22272B !important;
            background: none;
            padding: 13px 0;
            font-size: 14px;
            border-top: 0;
            font-weight: bolder;
            box-shadow: none;
            text-shadow: none;
            line-height: 18px;
        }

        li {
            width: 100%;
        }

        h2 {
            color: #22272B;
            font-size: 18px !important;
            font-weight: bold;
        }
        .no-desc .description {
            display: none;
        }

        .description {
            display: block;
            padding: 0;
            order: -1;
            margin: 0 auto 0 0;
            h5 {
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
            }
            p {
                color: #4A4F54;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
            }

            .btn-more {
                color: #fff !important;
                background: #695CFF;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                margin: 0 0 20px 0;
                font-weight:   600;
                padding: 5px 16px;
                max-width: initial;
                width: max-content;
                &:hover{
                    background: #39328A;
                }
            }
        }
    }

    width: max-content;
    padding: 0 10px;
    gap: 5px;

    /** Back Button Mobile Version **/

    .back {
        display: inline-block;
        text-decoration: underline;
        font-size: 15px;
        height: auto;
        width: auto;
        background-image: none;
        transform: none;
        color: var(--Violet---600, rgba(105, 92, 255, 1));
        font-family: 'Roboto';
        font-weight: bold;
        font-style: normal;
        margin-bottom: 18px;
        order: -1;
    }
    .we-mega-menu-submenu-inner {
        display: flex;
        flex-direction: column;
    }

    .overlay {
        display: none;
    }

    .show-submenu-megamenu {
        >* {
            display: none;

            &#block-axway2020-menumain, #block-axway2020-menumaines, #block-axway2020-menumainit {
                display: block;
            }
        }

        .language-switcher-language-url {
            display: none !important;
        }
    }

    #block-axway2020-menumain, #block-axway2020-menumaines, #block-axway2020-menumainit {

        >.region-we-mega-menu {
            margin: 0 auto;

            >.mobile-collapse {
                margin: 0;
            }
        }

        .group-one {

            .we-mega-menu-col {
                a.we-mega-menu-li {
                    color: #22272B;
                    padding: 7px 0 !important;
                    font-weight: bold;
                    text-decoration: underline;
                }
            }

            .dropdown-menu {
                border-bottom: 1px solid #EBECEC;

                .back,
                .description {
                    display: none;
                }


                >a,
                span {
                    color: #7F8184 !important;
                    font-weight: bold;
                    padding: 5px 0;
                }

                li {

                    >a,
                    span {
                        color: #22272B;
                        padding: 5px 0 !important;
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }
            }
        }

        .group-two {

            &.bo {
                & > .we-mega-menu-submenu > .we-mega-menu-submenu-inner > .back,
                & > .we-mega-menu-submenu h3 {
                    display: none;
                }
                & > .we-mega-menu-submenu .group-two-link h3 {
                    display:block;
                    color: #22272B;
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: 10px;
                }
            }

            > .we-mega-menu-submenu > .we-mega-menu-submenu-inner > h3{
                margin-top: 10px;
                color: #22272B;
                font-size: 18px !important;
                font-weight: bold;
            }

            .group-two-link {
                .we-mega-menu-submenu {
                    display: none;

                    a.we-mega-menu-li {
                        color: #22272B;
                        padding: 7px 0 !important;
                        font-weight: bold;
                        text-decoration: underline;
                    }

                }

                &.dropdown-menu.ob {

                    >a,
                    >i.icon-arrow {
                        display: none !important;
                    }

                    .we-mega-menu-submenu {
                        display: block;
                    }

                    h3 {
                        display: none;
                    }
                }

                &.bo {
                    display: none;
                }

                &.dropdown-menu {
                    .icon-arrow {
                        display: block !important;
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        right: 6px;
                        top: 0px;
                        z-index: 2;
                        cursor: pointer;
/*
[MOBILE] Right arrows aren't the same icon in 2nd and 3rd sub levels

                        &:after {
                            content: '';
                            display: inline-block;
                            text-rendering: auto;
                            position: absolute;
                            right: 8px;
                            top: 50%;
                            margin-top: -11px;
                            font-size: 22px;
                            color: #B4B6B8;
                            transform: rotate(0deg);
                            background: transparent center center url(/themes/custom/axway2020/img/megamenu/vector-arrow-mobile.svg) no-repeat no-repeat;
                            width: 30px;
                            height: 30px;
                        }
                            */
                    }

                    .we-mega-menu-submenu {
                        .icon-arrow {
                            display: none !important;
                        }
                    }
                }
            }

        }



    }
    .navbar-we-mega-menu.navbar ul>li {
        &.group-one{
            li {
                padding: 0 !important;
            }
        }
    }
    #navigation #block-axway2020-menumain .dropdown-menu,
    #navigation #block-axway2020-menumaines .dropdown-menu,
    #navigation #block-axway2020-menumainit .dropdown-menu,
    .navbar-we-mega-menu.navbar ul>li {
        position: relative;
        &.group-two-link{
            padding: 0 15px !important;
            &.ob {
                padding: 0 !important;
                .description h2{
                    display:none;
                }
            }
            li {
                padding: 0 !important;
            }
        }

        &.open {
            position: initial;

            >a,
            .icon-arrow {
                display: none;
            }

            &.po {
                position: relative;

                >a,
                .icon-arrow {
                    display: block;
                }

                .we-mega-menu-submenu {
                    display: none !important;
                }
            }
        }

        &.op {
            display: none;
        }

    }

    #navigation #block-axway2020-menumain .dropdown-menu.group-two-link,
    #navigation #block-axway2020-menumaines .dropdown-menu.group-two-link,
    #navigation #block-axway2020-menumainit .dropdown-menu.group-two-link,
    .navbar-we-mega-menu.navbar ul>li.group-two-link {
        // // position: relative;

        // // &.open{
        // //     position: initial;

        // >a,
        // .icon-arrow {
        //     display: none;
        // }

        // &.bo{
        //     position: relative;
        //     >a,
        //     .icon-arrow {
        //         display: block;
        //     }

        //     .we-mega-menu-submenu{
        //         display: none !important;
        //     }
        // }
        // // }

        // &.ob{
        //     display: none;
        // }
    }


    .navbar-we-mega-menu .we-mega-menu-row,
    .navbar-we-mega-menu .we-mega-menu-row>* {
        display: flex;
        flex-direction: column;

    }

    #block-axway2020-menumain .we-mega-menu-li.headline-title,
    #block-axway2020-menumaines .we-mega-menu-li.headline-title
    #block-axway2020-menumainit .we-mega-menu-li.headline-title {
        order: -1;
        .we-megamenu-nolink {
            color: #7F8184 !important;
            font-weight: bold;
        }
    }

    .navbar-we-mega-menu.navbar .dropdown-menu>.we-megamenu-nolink:after,
    .navbar-we-mega-menu.navbar .dropdown-menu>a:after {
        display: none;
    }


    // .we-mega-menu-ul>.dropdown-menu>.we-mega-menu-submenu,
    // .we-mega-menu-ul>.dropdown-menu.open>.we-mega-menu-submenu{
    //     display: none !important;
    // }


    // .we-mega-menu-ul>.dropdown-menu>.we-mega-menu-submenu.open,
    // .we-mega-menu-ul>.dropdown-menu.open>.we-mega-menu-submenu.open{
    //     display: block !important;
    // }


    // .navbar-we-mega-menu.navbar .dropdown-menu.active-trail>.we-mega-menu-submenu,
    // .navbar-we-mega-menu.navbar .dropdown-menu.active>.we-mega-menu-submenu{
    //     display: none;
    // }


    .dropdown-menu {

        >.icon-arrow {
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 6px;
            top: 5px;
            z-index: 2;
            cursor: pointer;

            &:after {
                content: '\f107';
                display: inline-block;
                font: 14px / 1 FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                right: 8px;
                top: 50%;
                margin-top: -4px;
                font-size: 22px;
                color: #B4B6B8;
                transform: rotate(-90deg);
            }
        }
    }


    #navigation.clear .container .navbar-brand,
    #navigation.scroll .container .navbar-brand {
        margin: -7px auto 0 -30px;
    }

    #navigation.clear .container .navbar-toggler,
    #navigation.scroll .container .navbar-toggler {
        top: 15px;
        position: absolute;
        right: 0;
    }

    .navbar-toggler {
        background: url("../img/megamenu/close-megamenu.svg") center center no-repeat !important;

        &.collapsed {
            background: url("../img/icn-menu-dark.svg") center center no-repeat !important;
        }
    }

    .chat-contact {
        display: none;
    }

    img#search-glass,
    img#search-glass.nav-search-active {
        left: 0 !important;
    }

    .contact-sales {
        position: absolute;
        top: 22px;
        right: 50px;
    }

    body.st-ui-overlay-active{
        a.navbar-brand,
        #navigation.clear .container .navbar-toggler,
        #navigation.scroll .container .navbar-toggler,
        .contact-sales{
          display: none;
        }
      }
}
#block-axway2020-menumain .clicked > a, #block-axway2020-menumain .clicked > span {
    color: var(--Violet---600, #695cff);
}

#block-axway2020-menumaines .clicked > a, #block-axway2020-menumaines .clicked > span {
    color: var(--Violet---600, #695cff);
}

#block-axway2020-menumainit .clicked > a, #block-axway2020-menumainit .clicked > span {
    color: var(--Violet---600, #695cff);
}

.st-ui-container.slide-open {
    margin: -20px 0px 30px !important;
}

.user-logged-in.toolbar-tray-open {
    .st-ui-container,
    .st-ui-container.slide-open,
    .st-ui-container.slide-opened,
    .st-ui-container.slide-close {
      margin-top: -20px !important;
    }
  }

  .user-logged-in.toolbar-tray-open .st-ui-container, .user-logged-in.toolbar-tray-open .st-ui-container.slide-open, .user-logged-in.toolbar-tray-open .st-ui-container.slide-opened, .user-logged-in.toolbar-tray-open .st-ui-container.slide-close {
    margin-top: 0px !important;
}
@media (min-width: 992px) {
    #navigation .logo, #navigation .navbar-brand {
        margin-right: 25px;
    }
}