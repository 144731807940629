// ***************     NAV STYLES      ***************

.dropdown-header {
  &:hover span {
    background: transparent !important;
    background-color: transparent !important;
  }
}

.navbar-nav.main {
  li.active {
    a.dropdown-toggle {
      font-weight: 700 !important;
    }
  }

  li.active.dropdown-header {
    ul {
      li.active {
        a {
          background: #efefef;
          color: $aqua !important;
        }
      }
    }
  }

  li.active {
    ul {
      li.active {
        a.is-active {
          background: #efefef;
          color: $aqua !important;
        }
      }
    }
  }
}

#navigation {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // padding: 30px 0 0;
  padding: 10px 0 0;
  // background: transparent;
  background-color: white;
  position: fixed;
  border-bottom: 1px solid #EBECEC !important;
  .container {
    width: 100%;
    max-width: 1440px;
  }

  ul,
  li {
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) !important;
    list-style: none !important;
    list-style-type: none !important;
    padding-left: 0;
  }

  &.scroll {
    // top: -600px; // <-- remove this to add a sticky top nav
    // transition: all 0.1s ease-out;
    top: 0px !important;
    transition: none !important;
    //padding: 10px 0 0;
    //background: linear-gradient(150.74deg, #2C3A44 0%, #23313C 54%, #23313C 54%, #23313C 54%, #1A2934 100%);

    .container {
      border-bottom: none;
    }

    .dropdown-menu {
      margin-top: 20px;
    }
  }

  &.clear {
    transition: all 0.1s ease-out;

    .container {
    }

    .dropdown-menu {
      // margin-top: 15px;
      margin-top: 6px !important;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
} // end #navigation

#navigation {
  // ***************     Brand - Axway logo      ***************
  .logo,
  .navbar-brand {
    height: 45px;
    margin: 0;
    padding: 0;
    min-width: 116px;

    @media (max-width: $xl) {
      margin-right: 15px;
    }

    img {
      margin: -5px 0 0 0;
      height: 40px;
    }
  }

  // ***************     Mobile Nav Menu Icon      ***************
  .navbar-toggler {
    height: 45px;
    width: 45px;
    padding: 0;
    background: url("../img/icn-menu-dark.svg") center center no-repeat;
    background-size: 30px;
    display: none;
  }

  // ***************     Top Level Nav      ***************
  #navbar-nav-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45px;
    padding: 0;

    .navbar-nav {
      color: rgba(0, 0, 0, 0); // hides the bullet in edge
      display: flex;
      flex-basis: auto;
      flex-direction: row;
      flex-grow: 0;
      flex-shrink: 0;
      height: 45px;
      list-style: none;
      list-style-type: none;
      margin: 0;
      overflow: visible;
      padding: 0;

      & > li {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.partners,
        &.partner,
        &.partenaires {
          .dropdown-menu {
            @media (min-width: $lg + 1px) and (max-width: 1600px) {
              //used when we have 2 columns
              //margin-left: -13vw;
              margin-left: 0px;
            }
          }
        }

        &.services,
        &.resources,
        &.ressources,
        &.dienstleistungen {
          .dropdown-nav-column {
            display: block;
            column-count: 1;
          }
        }

        //&.services,
        //&.ressources,
        &.ressourcen,
        &.company,
        &.société,
        &.unternehmen {
          .dropdown-nav-column {
            display: block;
            column-count: 2;
          }
        }

        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;
        }

        & > .dropdown-toggle {
          padding: 0 15px;
          color: $gray-dark-2020;
          font-weight: bold;
          font-size: 16px;
          line-height: 45px;
          white-space: nowrap;
          color: $gray-dark-2020;
          &:hover {
            color: #000;
          }

          @include media-breakpoint-down(lg) {
            padding: 5px 10px;
          }

          &::after {
            // Removes bootstrap's carat
            display: none;
            content: none;
          }

          &:hover {
            @include media-breakpoint-down(md) {
              background: $gray-bkg;
            }
          }
        }
      }
    } // end .navbar-nav

    // .top-nav-right > li {
    .language-switcher-language-url > li,
    .top-right-menu > li {
      & > .dropdown-toggle {
        padding: 5px 14px;
        font-size: 14px;

        @include media-breakpoint-down(lg) {
          padding: 5px 10px;
        }
        @include media-breakpoint-down(md) {
          //padding: 0 15px 15px;
          //font-size: inherit;
          //display: block;
        }
      }

      &.expanded:last-child {
        .dropdown-menu {
          .dropdown-nav {
            .dropdown-nav-column {
              flex-direction: column;
            }
          }
        }
      }

      .caret-down {
        $icon-size: 13px;
        display: none;
        height: $icon-size;
        width: $icon-size;
        margin-left: 5px;
        // background-image: url("../img/icn-chevron-down-slate.svg");
        // background-size: contain;
        // background-position: center center;
        // background-repeat: no-repeat;

        &:after {
          content: "\25BC";
          margin-left: 0;
        }

        @media (max-width: $lg) {
          //display: none;
        }
      }

      .top-nav-search {
        display: flex;
        align-items: center;
      }
      .btn-search {
        $icon-size: 20px;
        display: inline-block;
        height: 45px;
        width: $icon-size;
        margin-left: 15px;
        background-image: url("../img/icn-search.svg");
        background-size: $icon-size;
        background-position: center center;
        background-repeat: no-repeat;
      }
    } // end .top-nav-right > li
    #block-axway2020-languageswitchercontent {
      ul.dropdown-nav-column {
        flex-direction: column;
        padding: 10px 0;
      }

      .dropdown-menu {
        border: none;
        margin: 7px;

        &:before {
          content: "";
          display: inline-block;
          height: 21px;
          vertical-align: middle;
          width: 85px;
          background-color: #f3f3f3;
        }

        .dropdown-nav {
          box-shadow: 0px 10000px 0 10000px rgba(0, 0, 0, 0.15);
          width: auto;
          border-radius: 5px;
        }
      }

      li ul {
        li {
          a:hover {
            position: relative;
            background: #f7f6ff !important;
            color: #7d79fc !important;
          }
        }
      }
      //   ul.links {
      //     padding-left: 0;

      //     li {
      //       font-size: 14px;
      //       font-weight: 300;
      //       line-height: 1.25;
      //       min-width: 150px;
      //       margin: 0;
      //       padding: $spacing-sm/2 $spacing-sm/2;
      //       list-style: none;

      //       & > span {
      //         color: $gray-darkest;
      //       }

      //       a {
      //         display: block;
      //         padding: 3px 8px;
      //         white-space: nowrap;
      //         color: $white;

      //         &:focus,
      //         &:hover {
      //           color: $gray-darkest;
      //           text-decoration: none;
      //           background-color: #efefef;
      //         }
      //       }
      //     }
      //   }
    } // end #block-axway-languageswitchercontent

    // ***************     Dropdowns      ***************
    .dropdown-menu {
      //min-width: 150px;
      //min-width: max-content;
      //margin: 35px 0 0 0;    // Margin is assigned at the top under .clear and .scroll
      border-radius: 0;
      padding: 0;
      color: $gray-darkest;

      &.show {
        display: table;
      }

      .ultimenu__region,
      .dropdown-nav {
        background-color: white;
        // display: flex; // TODO: add responsive style to change this to block on mobile
        // flex-direction: row;

        &.region-ultimenu-main-40424bd0,
        &.region-ultimenu-main-5e5e49e1 {
          min-width: 360px;
        }

        &.region-ultimenu-main-85e811a1 {
          min-width: 288px;
        }

        &.region-ultimenu-main-1b029abd {
          min-width: 600px;
        }

        &.region-ultimenu-main-4a67e2b6 {
          min-width: 300px;
        }

        &.region-ultimenu-top-right-e63dc0b1 {
          // min-width: 306px;
          min-width: 336px;
        }

        &.region-ultimenu-top-right-9d95c79a {
          min-width: 141px;

          li {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.25;
            min-width: 150px;
            margin: 0;
            // padding: $spacing-sm/2 $spacing-sm/2;
            // padding: $spacing-sm $spacing-sm/2;
            list-style: none;

            & > span {
              color: $gray-darkest;
            }

            a {
              display: block;
              padding: 3px 8px;
              white-space: nowrap;
              color: $gray-darkest;

              &:focus,
              &:hover {
                color: $gray-darkest;
                text-decoration: none;
                background-color: #efefef;
              }
            }
          }
        }

        li.column {
          font-size: 16px;
          line-height: 26px;
          font-weight: bold;
          min-width: 150px;
          margin: 0;
          // padding: $spacing-sm $spacing-sm/2;
          // padding: $spacing-sm $spacing-sm/2;
          list-style: none;

          & > span {
            font-weight: bold;
            color: $gray-darkest;
          }

          a {
            display: block;
            // padding: 5px 15px;
            padding: 5px 15px 3px 0;
            white-space: nowrap;
            color: $gray-darkest;

            &:focus,
            &:hover {
              color: $gray-darkest;
              text-decoration: none;
              background-color: #efefef;
            }
          }

          ul {
            padding-left: 0;
            li {
              list-style: none outside none;
            }
          }
        }

        li.links {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.25;
          min-width: 150px;
          margin: 0;
          // padding: $spacing-sm/2 $spacing-sm/2;
          list-style: none;

          & > span {
            color: $gray-darkest;
          }

          a {
            display: block;
            padding: 3px 8px;
            white-space: nowrap;
            color: $gray-darkest;

            &:focus,
            &:hover {
              color: $gray-darkest;
              text-decoration: none;
              background-color: #efefef;
            }
          }
        }

        .dropdown-nav-column {
          display: flex;
          flex-direction: row;
          min-width: 150px;
          margin: 0;
          list-style: none;
          list-style: none;
          list-style-type: none;
          color: rgba(0, 0, 0, 0); // hides the bullet in edge

          li {
            // display: flex;
            font-size: 16px;
            line-height: 0px;
            //margin-top:12px;
            //margin-bottom: 12px;
            font-weight: bold;
            // flex-wrap: wrap;
            flex-direction: column;
            list-style: none outside none;

            &.expanded {
              // display: flex;
              // flex-direction: row;
              flex-wrap: nowrap;
            }

            a,
            span {
              display: block;
              padding: 5px 15px;
              white-space: nowrap;
              color: $gray-dark-2020;
              line-height: 24px;

              &:focus,
              &:hover {
                color: $gray-dark-2020;
                text-decoration: none;
                background-color: #efefef;
              }
            }

            ul {
              // margin-left: -15px;
              padding-left: 0;
            }
          }
          .dropdown-header {
            font-weight: 400;
            color: $gray-dark-2020;
            margin-bottom: 12px;
          }
        }
      }

      .dropdown-footer {
        //section.block {
        display: flex;
        flex-direction: row;
        background-color: $gray-bkg;
        display: none;

        .dropdown-footer-image {
          // .menu-image {
          width: 120px;
          min-height: 90px;
          @include center-bkg();
        }
        .dropdown-footer-text {
          flex-grow: 1;
          padding: $spacing-sm;

          &.go-left {
            align-items: normal;
          }

          p {
            margin: 0;
            padding: 0;
          }

          a {
            display: block;
            font-weight: bold;
            color: $gray-darkest;

            &:focus,
            &:hover {
              color: $gray-darkest;
            }

            &.btn {
              color: $white;

              &:focus,
              &:hover {
                color: $white;
              }
            }
          }
        } // end .dropdown-footer-text

        .dropdown-footer-btn-wrapper {
          @include center-child();

          .btn {
            margin: 0 $spacing-sm 0 0;
          }
          .btn-social-media {
            padding: 5px;

            &:last-child {
              margin-right: 10px;
            }
          }
        } // end .dropdown-footer-btn-wrapper
      } // end .dropdown-footer
    } // end .dropdown-menu

    // ***************     RESPONSIVE - TABLET      ***************

    @media (max-width: $lg) {
      display: none;

      &.show {
        display: block;
      }
    }
  }
} // end #navigation

// ***************     MOBILE - TABLET & Phone     ***************
@media (max-width: $lg) {
  #navigation.clear,
  #navigation.scroll {
    top: 0;
    padding: 0;
    background: white;
    min-height: 75px;

    .container {
      border-bottom: none;
      margin: 0;
      padding-left: $spacing-md;
      padding-right: 15px;

      .navbar-toggler {
        display: block;
      }
      .navbar-brand {
        //position: absolute;
        left: 50%;
        //margin-right: 0;
        //margin-left: -60px; // <-- needs to be half of image width
        margin: auto;

        img {
          width: 120px;
          height: 30px;
          //height: auto;
          margin: 8px 0 0 0;
        }
      }

      #navbar-nav-wrapper {
        display: none !important; // important required to override bootstrap important tag
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        height: auto;
        overflow-y: auto;
        margin-top: 76px;
        border-top: 1px solid rgba(255, 255, 255, 0.25);
        padding: $spacing-sm 0;
        background: white;
        color: $gray-dark-2020;
        &::-webkit-scrollbar {
          display: none;
        }

        &.show {
          display: block !important; // important required to override bootstrap important tag
          margin-top: 126px;
          height: 100vh !important;
        }

        .navbar-nav {
          background: none;
          flex-direction: column;
          height: auto;
          padding: 0;
          background: transparent;

          & > li {
            display: block;
            width: 100%;

            &.services,
            &.dienstleistungen,
            &.resources,
            &.ressources,
            &.ressourcen,
            &.company,
            &.société,
            &.unternehmen {
              .dropdown-nav-column {
                display: block;
                column-count: auto;
              }
            }

            .chevron {
              display: inline-block;
              float: right;
              height: 60px;
              width: 60px;
              background: url("../img/icn-chevron-right-dark.svg") center
                center no-repeat;
              background-size: auto 20px;
            }

            &.show {
              .chevron {
                background-image: url("../icn-chevron-down-slate.svg");
                background-size: 20px auto;
              }
            }

            a {
              display: block;
              width: 100%;
              padding: 0 $spacing-sm;
              color: $gray-dark-2020;
              font-size: 18px;
              line-height: 60px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              &:hover {
                background: rgba(0,0,0,0.1);
                color: $gray-dark-2020;
                font-weight: 500;
              }
            }

            .dropdown-menu.show {
              display: block;
              padding: 0;
              margin: 0;
              background: rgba(0, 0, 0, 0.1);

              .dropdown-nav {
                // display: block;
                background: transparent;
                padding: $spacing-sm 0;

                .dropdown-nav-column {
                  display: block;
                  padding: 0;

                  li span {
                    padding: 0 !important;
                    font-weight: 500;
                    color: $gray-dark-2020;
                  }

                  .dropdown-header {
                    //padding: 0 $spacing-lg;
                    color: $gray-dark-2020;
                    font-size: 18px;
                    line-height: 60px;


                    ul {
                      margin-left: -$spacing-lg;
                    }
                  }

                  & + .dropdown-nav-column .dropdown-header {
                    // Targets dropdown headers that are not the first (at the top)
                    margin-top: $spacing-xs;
                  }

                  a {
                    color: $gray-dark-2020;
                    padding: 0 $spacing-lg;
                    line-height: 45px;
                  }
                }
              } // end dropdown-nav

              .dropdown-footer {
                display: none;
              }
            }
          }

          .caret-down {
            display: none;
          }

          .top-nav-search {
            display: none;
          }
        } // end .navbar-nav
      }
    }
  }
}

// MENU PROMOTION
a.menu-promotion {
  color: $red !important;
  font-weight: $font-weight-bold;
}

// ***************    DRUPAL       ***************
.toolbar-horizontal {
  #navigation {
    margin-top: 39px;

    @media (max-width: $lg) {
      #navbar-nav-wrapper {
        margin-top: 114px !important;
      }
    }
  }

  &.toolbar-tray-open {
    #navigation {
      margin-top: 80px;

      @media (max-width: $lg) {
        #navbar-nav-wrapper {
          margin-top: 154px !important;
        }
      }
    }
  }
}

.toolbar-vertical {
  #navigation {
    margin-top: 39px;

    @media (max-width: $lg) {
      #navbar-nav-wrapper {
        margin-top: 114px !important;
      }
    }
  }

  &.toolbar-tray-open {
    #navigation {
      margin-left: 150px;

      @media (max-width: $lg) {
        #navbar-nav-wrapper {
          margin-left: 150px !important;
        }
      }
    }
  }
}

#nav-search-close {
  color: $gray-dark-2020;
}

#toc-inner .nav>li>a {
  position: relative;
  display: block;
  padding: 0;
}